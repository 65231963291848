import styled from 'styled-components';


export const SpanButton = styled.button`
    background: transparent;
    border: none;
    
`;

export const ContainerAlert = styled.div`
    display: flex;
    flex-direction: row;
`;

export const ContainerInfo = styled.div`
    width: 50%;
`;


export const ContainerX = styled.div`
    width: 50%;
    display: flex;
    justify-content: flex-end;

    span {
        font-size: 1rem !important;
        color: #e04167 !important;
    }
`;

export const Cadastrado = styled.h6`
    color: #155724;

`;


//sucesso casdatro
export const Area = styled.div`
    

    width: 100%
  
`;

export const VoltarBtn = styled.button`
    width: 343px;
    height: 60px;
    background: #ECF2F7 0% 0% no-repeat padding-box;
    border-radius: 8px;
    opacity: 1;
    border: none;
    color: #002D6D;
    box-shadow: 0 0.5rem 1.125rem -0.5rem rgb(0 46 110 / 90%);

    

`;

export const AreaBtn = styled.div`
    display:flex;
    justify-content: center;
    align-items: center;
    padding: 40px;


`;



export const FormBox = styled.div`
    padding: 15px;
`;

export const Cartao = styled.div`

    @media only screen and (max-width: 576px)  { 
        .jp-card-front {
    
            width: 80% !important;

        }
        .jp-card-back {
    
            width: 80% !important;

        }
    }
`;

export const CardBox = styled.div`
    display:flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 572.5px;


`;

export const HomeBtn = styled.a`
    background-color: #002D6D ;  
    color: #FFFFFF !important;
    font-size: 14px;
    border: none; 
    text-align: center !important;  
    border-radius: 7px;
    padding: 10px;
    height: 40px;
    

`;
