import React, { useState } from 'react';

import {
    AreaBemVindo,
    Title,
    TextContent,
    CadastrarBtn,
    Area,
    AreaCadastrarBtn
} from './styles';

const  MainBanner = (props) => {
    return(
        <AreaBemVindo> 
            <Area >  
                <Title>Seu próprio negócio a um clique</Title>
                <TextContent style={{marginBottom:"60px"}}>Baixo investimento. Alta lucratividade. Sem burocracia.</TextContent>
                <AreaCadastrarBtn>
                    <CadastrarBtn className="btn-primary btn-shadow" data-target="#revendedor-register" data-toggle="modal">
                        Cadastre-se Agora
                    </CadastrarBtn>  
                </AreaCadastrarBtn>
            </Area>
        </AreaBemVindo>  
    );
}

export default MainBanner;

/**
 * 
 */