import React, { useState, useEffect } from 'react'
import PageTitle from '../components/PageTitle'
import { inject, observer } from 'mobx-react'
import api from '../services/api'
import { useParams } from 'react-router-dom'
import { useAlert } from 'react-alert'
import subSiteConfig from '../services/subSite'

import AsideProfile from '../components/Profile/AsideProfile'
import ButtonLoading from '../components/ButtonLoading'
import Loading from '../components/Loading'
import Toolbar from '../components/Profile/Toolbar'
import Skeleton from 'react-loading-skeleton'

const Profile = props => {
  const { token, dados, setDados } = props.ClientStore
  const { setSubSite, subSite, setSubSiteParam } = props.HeaderStore
  const { vendedor } = useParams()
  const alert = useAlert()

  // console.log(token);
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [telefone, setTelefone] = useState('')
  const [senha, setSenha] = useState('')
  const [senhaConfirm, setSenhaConfirm] = useState('')
  const [tipoCliente, setTipoCliente] = useState('')
  const [loading, setLoading] = useState(false)
  const [viewInfo, setViewInfo] = useState(false)

  async function getPerfil() {
    try {
      //console.log({ token });

      let response = await api.get('/perfil', {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })

      if (response.data.data) {
        //console.log("Perfil: ", response.data.data.cliente.tipo_cliente_id);

        setName(response.data.data.cliente.nome)
        setEmail(response.data.data.cliente.email)
        setTelefone(response.data.data.cliente.telefone)
        setTipoCliente(response.data.data.cliente.tipo_cliente_id)
        setViewInfo(true)
      }
    } catch (error) {
      console.log(error)

      alert.info('Ocoreu um erro')
    }
  }

  async function updatePerfil() {
    if (senha !== '' && senha !== senhaConfirm) {
      alert.error('As duas senhas não são iguais.')

      return
    }

    setLoading(true)
    setViewInfo(false)

    try {
      let response = await api.post(
        '/perfil/edit',
        {
          nome: name,
          email,
          telefone,
          password: senha !== '' ? senha : null,
        },
        {
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + token,
          },
        }
      )

      if (!response.data.errors) {
        getPerfil()
        setSenha('')
        setSenhaConfirm('')
        alert.success('Dados atualizados com sucesso!')
        setViewInfo(true)
      }

      setLoading(false)
    } catch (error) {
      setLoading(false)

      alert.info('Ocorreu algo de errado.')
    }
  }

  useEffect(() => {
    if (token) {
      getPerfil()
    }
  }, [token])

  //subsite
  const subSiteLayout = async v => await subSiteConfig.handleSubsite(v)

  const reqSubsite = async v => {
    let obj = await subSiteConfig.getUrlParameter(v)

    setSubSite(obj)
  }

  useEffect(() => {
    reqSubsite(vendedor)
  }, [])

  useEffect(() => {
    //console.log("subSite: ", subSite);
    if (subSite != null) {
      console.log('subSite: ENTROU')
      setSubSiteParam(subSite)
      subSiteLayout(subSite)
    }
  }, [subSite])
  //subsite

  return (
    <>
      <PageTitle
        nameRoute="Perfil"
        url={subSite != null ? `/site/${subSite.param}/perfil` : '/perfil'}
        title="Informações do perfil"
      />

      <div className="container pb-5 mb-2 mb-md-3">
        <div className="row">
          {/* <!-- Sidebar--> */}
          <AsideProfile type="profile" name={dados.nome} email={dados.email} />

          {/* <!-- Content  --> */}
          <section className="col-lg-8">
            {/* <!-- Toolbar--> */}
            <Toolbar title="Atualize as suas informações do perfil abaixo:" />

            {/* <!-- Profile form--> */}
            <form>
              <div className="row">
                <div className="col-sm-12">
                  <div className="form-group">
                    <label htmlFor="account-fn">Nome</label>

                    {viewInfo && (
                      <input
                        className="form-control"
                        type="text"
                        name="nome"
                        id="account-fn"
                        value={name}
                        onChange={event => setName(event.target.value)}
                      />
                    )}

                    {!viewInfo && <Skeleton height="45px" width="100%" />}
                  </div>
                </div>

                <div className="col-sm-6">
                  <div className="form-group">
                    <label htmlFor="account-email">E-mail</label>

                    {viewInfo && (
                      <input
                        className="form-control"
                        type="email"
                        name="email"
                        id="account-email"
                        value={email}
                        onChange={event => setEmail(event.target.value)}
                      />
                    )}

                    {!viewInfo && <Skeleton height="45px" width="100%" />}
                  </div>
                </div>

                <div className="col-sm-6">
                  <div className="form-group">
                    <label htmlFor="account-phone">Número de celular</label>

                    {viewInfo && (
                      <input
                        className="form-control"
                        type="text"
                        name="telefone"
                        id="account-phone"
                        value={telefone}
                        onChange={event => setTelefone(event.target.value)}
                        required
                      />
                    )}

                    {!viewInfo && <Skeleton height="45px" width="100%" />}
                  </div>
                </div>

                <div className="col-sm-6">
                  <div className="form-group">
                    <label htmlFor="account-pass">Senha</label>

                    <div className="password-toggle">
                      {viewInfo && (
                        <>
                          <input
                            className="form-control"
                            type="password"
                            id="account-pass"
                            name="senha"
                            value={senha}
                            onChange={event => setSenha(event.target.value)}
                          />

                          <label className="password-toggle-btn">
                            <input
                              className="custom-control-input"
                              type="checkbox"
                            />

                            <i className="czi-eye password-toggle-indicator"></i>

                            <span className="sr-only">Mostrar Senha</span>
                          </label>
                        </>
                      )}

                      {!viewInfo && <Skeleton height="45px" width="100%" />}
                    </div>
                  </div>
                </div>

                <div className="col-sm-6">
                  <div className="form-group">
                    <label htmlFor="account-confirm-pass">
                      Confirme a Senha
                    </label>

                    <div className="password-toggle">
                      {viewInfo && (
                        <>
                          <input
                            name="senha_confirm"
                            className="form-control"
                            type="password"
                            id="account-confirm-pass"
                            value={senhaConfirm}
                            onChange={event =>
                              setSenhaConfirm(event.target.value)
                            }
                          />

                          <label className="password-toggle-btn">
                            <input
                              className="custom-control-input"
                              type="checkbox"
                            />

                            <i className="czi-eye password-toggle-indicator"></i>

                            <span className="sr-only">Mostrar Senha</span>
                          </label>
                        </>
                      )}
                      {!viewInfo && <Skeleton height="45px" width="100%" />}
                    </div>
                  </div>
                </div>

                <div className="col-12">
                  <hr className="mt-2 mb-3" />

                  <div className="d-flex flex-wrap justify-content-between align-items-center">
                    <div className="custom-control custom-checkbox d-block"></div>

                    <ButtonLoading
                      class="btn btn-primary mt-3 mt-sm-0"
                      onClick={updatePerfil}
                      title="Atualizar Perfil"
                      loading={loading}
                    />
                  </div>
                </div>
              </div>
            </form>
          </section>
        </div>
      </div>
    </>
  )
}

export default inject('ClientStore', 'HeaderStore')(observer(Profile))
