import React, { useEffect, useState } from 'react'
import { inject, observer } from 'mobx-react'
import api from '../../services/api'
import { closeModal } from '../../services/helpers'
import ButtonLoading from '../../components/ButtonLoading'
import InputMask from 'react-input-mask'
import { useAlert } from 'react-alert'
import subSiteConfig from '../../services/subSite'

import { useParams } from 'react-router-dom'

import { ContainerAlert, ContainerInfo, ContainerX, SpanButton } from './styles'

import Loading from '../../components/Loading'

const EsqueceuSenha = props => {
  const [email, setEmail] = useState('')
  const [emailInfo, setEmailInfo] = useState('')
  const [checkEmail, setCheckEmail] = useState(null)
  const [loading, setLoading] = useState(false)
  const alert = useAlert()

  const { setSubSite, subSite } = props.HeaderStore
  let { vendedor } = useParams()

  const addAddress = async event => {
    event.preventDefault()

    try {
      setLoading(true)

      //necessita do Endpoint de cadastro do cliente
      console.log('Processo troca de senha se inciará')
      /*
        let response = await api.post('/endereco', {
            rua,
            cep,
            numero,
            complemento,
            bairro,
            cidade,
            estado
        }, {
            headers: {
            'Accept': 'application/json',
            }
        });

        if (!response.data.errors) {
            setCep('');
            setRua('');
            setNumero('');
            setComplemento('');
            setBairro('');
            setCidade('');
            setEstado('');

            setLoading(false);
            props.getMyAddresses();
            closeModal('#add-address');
        }
    */

      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  const isValidEmail = async () => {
    const reg = new RegExp(/^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/)

    if (!reg.test(email)) {
      // nao é um email valido, nao vale a pena perguntar se existe na DB
      setEmail('')

      setCheckEmail(true)
    }
  }

  useEffect(() => {
    if (checkEmail) {
      setEmailInfo('Email invalido.')
    }
  }, [checkEmail, emailInfo])

  //subsite
  const subSiteLayout = async v => {
    return await subSiteConfig.handleSubsite(v)
  }

  const reqSubsite = async v => {
    let obj = await subSiteConfig.getUrlParameter(v)

    setSubSite(obj)
  }

  useEffect(() => {
    reqSubsite(vendedor)
  }, [])

  useEffect(() => {
    console.log('subSite: ', subSite)

    if (subSite != null) {
      console.log('subSite: ENTROU')

      subSiteLayout(subSite)
    }
  }, [subSite])
  //subsite

  return (
    <div className="container">
      <form
        method="post"
        id="revendedor-register"
        tabIndex="-1"
        noValidate
        onSubmit={addAddress}
      >
        <div style={{ padding: '20px' }}>
          <h2 style={{ textAlign: 'center' }}>Recuperar Senha</h2>

          <div className="col-sm-12">
            <div className="form-group">
              <label htmlFor="address-zip">Email</label>

              <InputMask
                className="form-control"
                maskChar={null}
                value={email}
                onChange={e => setEmail(e.target.value)}
                placeholder="Email"
                onBlur={isValidEmail}
              />

              {checkEmail == true ? (
                <div
                  className="col-sm-12 alert alert-danger alert-dismissible"
                  style={{ marginTop: '10px' }}
                  role="alert"
                >
                  <ContainerAlert>
                    <ContainerInfo>
                      <strong>Atenção!</strong> {emailInfo}
                    </ContainerInfo>

                    <ContainerX>
                      <SpanButton
                        type="button"
                        onClick={() => setCheckEmail(false)}
                      >
                        <span aria-hidden="true">&times;</span>
                      </SpanButton>
                    </ContainerX>
                  </ContainerAlert>
                </div>
              ) : null}

              <div className="invalid-feedback">Preencha este campo.</div>
            </div>
          </div>
        </div>

        <div className="modal-footer">
          <a
            className="btn btn-secondary"
            type="button"
            href={subSite != null ? `/site/${subSite.param}/entrar` : '/entrar'}
          >
            Voltar
          </a>

          <ButtonLoading
            class="btn btn-primary btn-shadow"
            type="submit"
            loading={loading}
            title="Concluir"
          />
        </div>
      </form>
    </div>
  )
}

export default inject(
  'CadastroRevendedorStore',
  'HeaderStore'
)(observer(EsqueceuSenha))
