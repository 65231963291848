
import styled from 'styled-components';




export const Area =  styled.div`  
    display:flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    background-image: url("../img/cadastrar-revendedor/woman.jpg");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding: 10px;

    .CarouselCustom{
        width: 954px;
        height: auto;
        border-radius:43px;
    }

    .carousel-caption {
        top:10% !important;
    }

    h4 {
        color: #717177;
    }

    h6 {
        color: #717177;
    }

    .carousel-indicators li {
      width:10px !important;
      height:10px !important;
      background-color:#E7E6E6 !important;
      border-radius: 100%; 
    
    }

    @media only screen and (max-width: 576px)  { 
        

        h4 {
            color: #717177;
            font-size: 12px;
        }

        
        h6 {
            color: #717177;
            font-size: 11px;
        }

        .CarouselCustom{
            height: 300px;
        }
    }

    @media only screen and (max-width: 768px)  { 
        

        h4 {
            color: #717177;
            font-size: 11px;
        }   
       
    }

    @media only screen and (max-width: 280px)  { 

        h4 {
            font-size: 12px;
        }
    }

   
`;

export const CarouselImg = styled.img`
    width: 954px;
    height: auto;
    border-radius:43px;

    @media only screen and (max-width: 770px)  { 
        height: 300px !important;
    }

    
`;

export const ContainerComentario =  styled.div`  
    display: flex;
    flex-direction: column;
   

    
`;

export const ContainerAspasLeft = styled.div`
    justify-content: flex-start;
    display: flex; 


`;

export const ContainerAspasRight = styled(ContainerAspasLeft)`
    justify-content: flex-end;
`;

export const AspasLeft = styled.div`
    width: 54px;
    height: 43px;
    background-image: url("../img/cadastrar-revendedor/comentario/metro-quote1.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;

    

    @media only screen and (max-width: 768px)  { 
        width: 20px;
        height: 20px;
    }


`;

export const AspasRight = styled(AspasLeft)`
    background-image: url("../img/cadastrar-revendedor/comentario/metro-quote2.png");


`;

export const BodyContent =  styled.div`  
    justify-content: center;
    align-items: center;
    padding: 10px;
`;


export const Avatar =  styled.div`  
    position: absolute;
    width: 184px;
    height: auto;
    top: 290px;
    margin-left: 50px;

    @media only screen and (max-width: 768px)  { 
        width: 80px;
        top: 225px;
        margin-left: 25px;
        height: auto;
    }

    
`;













