import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import subSiteConfig from '../services/subSite';
import {
  useParams
} from "react-router-dom";

import Breadcrumb from './Breadcrumb';

const PageTitle = (props) => {

  const { visible, setVisible, setSubSite, subSite, setSubSiteParam } = props.HeaderStore;
  let { vendedor } = useParams();

  return (
    <div className="page-title-overlap pt-4" style={{ backgroundColor: "#ecf2f7" }}>
      <div className="container d-lg-flex justify-content-between py-2 py-lg-3">
        
      <div className="order-lg-2 mb-3 mb-lg-0 pt-lg-2">
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb breadcrumb-dark flex-lg-nowrap justify-content-center justify-content-lg-start text-dark">
        
          <Breadcrumb name="Início" link={subSite != null ? `/site/${subSite.param}/` :"/"} isHome={true}/>
          <Breadcrumb name={props.nameRoute} link={props.url}/>
          {console.log("BreadCrumb: ", {link:props.url})}
        </ol>
      </nav>
      </div>

        <div className="order-lg-1 pr-lg-4 text-center text-lg-left">
          <h1 className="h3 mb-0">
            {props.title}
          </h1>
        </div>
      </div>
    </div>
  );  
}

export default inject("ClientStore","HeaderStore")(observer(PageTitle));