import React, { useEffect, useState } from 'react'

import PageTitle from '../../components/PageTitle'
import Step from '../../components/Checkout/Step'
import CardCheckoutConclusion from '../../components/Checkout/CardCheckoutConclusion'
import NavigationDesktop from '../../components/Checkout/NavigationDesktop'
import AsideCheckout from '../../components/Checkout/AsideCheckout'
import NavigationMobile from '../../components/Checkout/NavigationMobile'
import { inject, observer } from 'mobx-react'
import { useAlert } from 'react-alert'
import { useHistory, useParams } from 'react-router-dom'
import { formatNumber, closeModal, getCardFlag } from '../../services/helpers'
import CardReactFormContainer from 'card-react'
import api from '../../services/api'
import ModalPresente from '../../components/ModalPresente'
import { FormBox, Cartao, CardBox } from './styles'
import InputMask from 'react-input-mask'
import subSite from '../../services/subSite'
import subSiteConfig from '../../services/subSite'
import Cookies from 'js-cookie'

const CheckoutConclusion = props => {
  const {
    cvv,
    setCvv,
    cartao,
    nome,
    validade,
    bandeira,
    setBandeira,
    type_pagamento,
    objectToken,
    objectEndereco,
    freteSelecionado,
    codigo_frete,
    qtdParcelas,
    parcelas,
    setQtdParcelas,
    cupomDados,
    tipo_pagamento,
    setTipoPagamento,
  } = props.PedidoStore

  console.log('tipoPagamento: ', tipo_pagamento)

  const [endercoStatus, setEndercoStatus] = useState(false)
  const [vendedorId, setVendedorId] = useState(0)

  //2 Pagseugro; 0 cartao;
  const [pagamento, setPagamento] = useState([
    'Cartão de Credito!!',
    'Pix',
    'PagSeguro',
    'Transferencia',
  ])

  const { visible, setVisible, setSubSite, subSite, setSubSiteParam } =
    props.HeaderStore
  let { vendedor } = useParams()
  const alert = useAlert()

  const { token, dados } = props.ClientStore
  const { produtos, cleanCart, getTotal, setEmbalagem, frete } =
    props.CarrinhoStore
  const [pedido, setPedido] = useState({})
  const [keyProduto, setKeyProduto] = useState(0)
  const [textoProduto, setTexto] = useState(null)
  const [embalagemId, setEmbalagemId] = useState(null)
  const [valorExtra, setValorExtra] = useState(0)
  const [presente, setPresente] = useState(null)
  const [loading, setLoading] = useState(false)

  //card data
  const [nameForm, setNameForm] = useState(nome)
  const [cvvForm, setCvvForm] = useState('')
  const [validadeForm, setVaildadeForm] = useState('00/00')
  const [cpfForm, setCpfForm] = useState('')
  const [numberCardForm, setNumberCardForm] = useState(null)
  const [bandeiraCardForm, setBandeiraCardForm] = useState(null)
  const [cardFlag, setCardFlag] = useState('visa')
  const [celularForm, setCelularForm] = useState('')
  const [disabledButton, setDisabledButton] = useState(false)
  const successUrl =
    subSite != null
      ? `/site/${subSite.param}/compra-efetuada-sucesso`
      : '/compra-efetuada-sucesso'

  let history = useHistory()

  useEffect(() => {
    if (tipo_pagamento == 4) {
      setTipoPagamento(0)
    }

    if (!tipo_pagamento) {
      if (subSite != null) {
        alert.show('Escolha a forma de pagamento.', { type: 'info' })
        history.push(`/site/${subSite.param}/checkout-pagamento`)
      } else {
        history.push('/checkout-pagamento')
      }
    }

    if (!codigo_frete) {
      if (subSite != null) {
        alert.show('Selecione um metodo de entrega!', { type: 'info' })
        history.push(`/site/${subSite.param}/checkout-endereco`)
      } else {
        history.push('/checkout-endereco')
      }
    }


    window.scrollTo(0, 0)
  }, [])

  useEffect(() => { }, [
    nameForm,
    cvvForm,
    validadeForm,
    cpfForm,
    celularForm,
    cpfForm,
    tipo_pagamento,
  ])

  useEffect(() => {
    if (numberCardForm != null) {
      setBandeiraCardForm(getCardFlag(numberCardForm))
    }
    setVendedorId(Cookies.get('vendedor_id'))
  }, [numberCardForm, bandeiraCardForm, qtdParcelas])




  const validarNome = () => {
    if (tipo_pagamento == 1 || tipo_pagamento == 3) {
      return
    } else {
      if (nameForm == '') {
        if (subSite != null) {
          alert.show('Digite o nome completo do titular.', { type: 'info' })
          history.push(`/site/${subSite.param}/checkout-pagamento`)
          return
        } else {
          alert.show('Digite o nome completo do titular.', { type: 'info' })
          history.push('/checkout-pagamento')
          return
        }
      }
    }
  }

  const validarCardNumber = () => {
    if (tipo_pagamento == 1 || tipo_pagamento == 3) {
      return
    } else {
      if (numberCardForm.length < 16) {
        if (subSite != null) {
          alert.show('Digite um cartão de crédito válido.', { type: 'info' })
          history.push(`/site/${subSite.param}/checkout-pagamento`)
          return
        } else {
          alert.show('Digite um cartão de crédito válido.', { type: 'info' })

          history.push('/checkout-pagamento')
          return
        }
      }
    }
  }

  const validarValidade = () => {
    if (tipo_pagamento == 1 || tipo_pagamento == 3) {
      return
    } else {
      if (validadeForm == '' || validadeForm.length < 9) {
        if (subSite != null) {
          alert.show('Digite a válidade do cartão corretamente.', {
            type: 'info',
          })
          history.push(`/site/${subSite.param}/checkout-pagamento`)
          return
        } else {
          alert.show('Digite a válidade do cartão corretamente.', {
            type: 'info',
          })
          history.push('/checkout-pagamento')
          return
        }
      }
    }
  }

  const validarCvv = () => {
    if (tipo_pagamento == 1 || tipo_pagamento == 3) {
      return
    } else {
      if (cvvForm == '') {
        if (subSite != null) {
          alert.show('Digite o código de segurança do cartão.', {
            type: 'info',
          })
          history.push(`/site/${subSite.param}/checkout-pagamento`)
          return
        } else {
          alert.show('Digite o código de segurança do cartão.', {
            type: 'info',
          })
          history.push('/checkout-pagamento')
          return
        }
      }
    }
  }

  async function postPedido() {
    debugger;
    if (Cookies.get('vendedor_id') != null) {
      setVendedorId(Cookies.get('vendedor_id'))
    } else {
      setVendedorId(0)
    }

    setLoading(true)
    let body = {}

    let d = validadeForm
    let dataSplit = d.split('/')

    //corpo da requisiçao

    if (tipo_pagamento == 1 || tipo_pagamento == 3) {
      body = {
        produtos: produtos,
        tipo_pagamento: tipo_pagamento,
        frete: freteSelecionado.valor,
        tipo_frete: freteSelecionado.tipo,
        prazo: freteSelecionado.prazo,
        type: 'pix',
        token_cartao: false,
        // id_cupom: cupomDados ? cupomDados.id : null,
        cvv: "null",
        vendedor_id: vendedorId ? vendedorId : 0,
        endereco: objectEndereco.id,
        cartao: {
          cpf: "null",
          bandeira: "null",
          numero: "null",
          titular: "null",
          validade: "null",
          cvv: "null",
          parcelas: "null",
          salvar: true,
        },
      }
    } else {
      body = {
        produtos: produtos,
        frete: freteSelecionado.valor,
        tipo_frete: freteSelecionado.tipo,
        tipo_pagamento: 0,
        prazo: freteSelecionado.prazo,
        type: 'cartao',
        token_cartao: type_pagamento == 2 ? objectToken.token : false,
        bandeira: cardFlag,
        id_cupom: cupomDados ? cupomDados.id : null,
        cvv: cvvForm,
        vendedor_id: vendedorId,
        endereco: objectEndereco.id,
        cartao: {
          cpf: cpfForm,
          bandeira: cardFlag,
          numero: numberCardForm,
          titular: nameForm,
          validade: `${dataSplit[0]}/20${dataSplit[1]}`,
          cvv: cvvForm,
          parcelas: qtdParcelas,
          salvar: false,
        },
      }
    }

    let base = `https://app.ronnellypara.com.br/api/pedido`

    console.log(body)
    const req = await fetch(base, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      body: JSON.stringify(body),
    })
    const response = await req.json()

    if (response.errors) {
      setPedido(response.data.data)
      window.scrollTo(0, 0)
      alert.show(response.message, { type: 'error' })
      setLoading(false)
      setDisabledButton(true)
    } else {
      cleanCart()
      window.scrollTo(0, 0)
      alert.show('Pedido efetuado com sucesso', { type: 'success' })
      window.location.href = successUrl
      setLoading(false)
    }

    setLoading(false)
    setDisabledButton(false)
  }

  function saveEmbalagem() {
    if (presente == 'sim' && !embalagemId) {
      alert.show('Selecione a embalagem.', { type: 'error' })
      return
    }
    setEmbalagem(keyProduto, textoProduto, presente, embalagemId, valorExtra)
    closeModal('#modal-presente')
  }

  const handleCardAndLogo = valor => {
    if (valor == 3) {
      return (
        <div
          className="d-flex justify-content-center"
          style={{ height: '267px', padding: '10px' }}
        >
          <img
            src="/img/iconeSantander.png"
            style={{ height: '220px', width: '220px' }}
          />
        </div>
      )
    }

    if (valor == 1) {
      return (
        <div
          className="d-flex justify-content-center"
          style={{ height: '165px', padding: '10px' }}
        >
          <img
            src="/img/iconePix.png"
            style={{ height: '150px', width: '150px' }}
          />
        </div>
      )
    }
  }


  const parcelasMap = [
    {
      value: 1,
      limit: 0
    },
    {
      value: 2,
      limit: 100
    },
    {
      value: 3,
      limit: 300
    },
    {
      value: 4,
      limit: 600
    },
    {
      value: 5,
      limit: 1000
    },
    {
      value: 6,
      limit: 1500
    },
  ]



  //subsite
  const subSiteLayout = async v => {
    return await subSiteConfig.handleSubsite(v)
  }

  const reqSubsite = async v => {
    let obj = await subSiteConfig.getUrlParameter(v)
    setSubSite(obj)
  }

  useEffect(() => {
    reqSubsite(vendedor)
  }, [])

  useEffect(() => {
    //console.log("subSite: ", subSite);
    if (subSite != null) {
      //console.log("subSite: ENTROU");
      setSubSiteParam(subSite)
      subSiteLayout(subSite)
    }
  }, [subSite])
  //subsite

  return (
    <>
      <ModalPresente
        valorExtra={valorExtra}
        setValorExtra={valor => setValorExtra(valor)}
        embalagemId={embalagemId}
        setEmbalagem={e => setEmbalagemId(e)}
        keyProduto={keyProduto}
        texto={textoProduto}
        presente={presente}
        setTexto={texto => setTexto(texto)}
        setPresente={value => setPresente(value)}
        onSave={() => saveEmbalagem()}
      />
      <PageTitle
        nameRoute="Conclusão"
        url={'/checkout-conclusao'}

      />

      <div className="container pb-5 mb-2 mb-md-4">
        <div className="row">
          <section className="col-lg-8">
            {/* <!-- Steps--> */}

            <Step numberCurrent="4" />

            {/* <!-- Order details--> */}
            <h2 className="h6 pt-1 pb-3 mb-3 border-bottom">
              Resumo da sua compra
            </h2>
            {/* <!-- Item--> */}

            {produtos.map((item, key) => (
              <CardCheckoutConclusion
                key={item.id}
                id={item.id}
                item={item}
                slug={item.slug}
                img={item.url_fotos}
                price={formatNumber(
                  item.desconto.length ? item.preco_final : item.preco
                )}
                amount={item.quantidade}
                title={item.nome}
                setKeyProduto={() => {
                  setKeyProduto(key)
                  setTexto(item.texto_presente)
                  setPresente(item.presente)
                  setValorExtra(item.valor_extra)
                  setEmbalagemId(item.embalagem_id)
                }}
              />
            ))}

            {/* <!-- Client details--> */}
            {
              freteSelecionado && freteSelecionado.codigo !== 1 && <div
                className="bg-secondary rounded-lg px-4 pt-4 pb-2"
                style={{ color: '#000' }}
              >
                <div className="row">
                  <div className="col-sm-6">
                    <h4 className="h6" style={{ color: '#000' }}>
                      Enviar para:
                    </h4>
                    <ul className="list-unstyled font-size-sm">
                      <li>
                        <span style={{ color: '#000' }}>Cliente:&nbsp;</span>
                        {dados.nome}
                      </li>
                      <li>
                        <span style={{ color: '#000' }}>Endereço:&nbsp;</span>
                        {objectEndereco
                          ? objectEndereco.rua +
                          ' ' +
                          objectEndereco.numero +
                          ' ' +
                          objectEndereco.bairro +
                          ' / ' +
                          objectEndereco.cidade +
                          ' ' +
                          objectEndereco.estado
                          : ''}
                      </li>
                      <li>
                        <span style={{ color: '#000' }}>E-mail:&nbsp;</span>
                        {dados.email}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            }
            <br />
            <div className="bg-secondary rounded-lg px-4 pt-4 pb-2">
              <div className="row">
                {tipo_pagamento == 0 && (
                  <>
                    <div className="col-sm-6">
                      <div
                        className="row border-bottom"
                        style={{ padding: '10px' }}
                      >
                        <h4 className="h6">Método de Pagamento:</h4>
                        <div>
                          <ul className="list-unstyled font-size-sm">
                            <li>
                              <span style={{ color: '#000' }}>&nbsp;</span>{' '}
                              {pagamento[tipo_pagamento]}
                            </li>
                          </ul>
                        </div>
                      </div>
                      <form
                        method="post"
                        id="revendedor-register"
                        tabIndex="-1"
                        noValidate
                      >
                        <FormBox className="row">
                          <>
                            <CardReactFormContainer
                              // the id of the container element where you want to render the card element.
                              // the card component can be rendered anywhere (doesn't have to be in ReactCardFormContainer).
                              container="card-wrapper" // required
                              // an object contain the form inputs names.
                              // every input must have a unique name prop.
                              formInputsNames={{
                                number: 'CCnumber', // optional — default "number"
                                expiry: 'CCexpiry', // optional — default "expiry"
                                cvc: 'CCcvc', // optional — default "cvc"
                                name: 'CCname', // optional - default "name"
                              }}
                              // initial values to render in the card element
                              initialValues={{
                                number: '•••• •••• •••• ••••', // optional — default •••• •••• •••• ••••
                                cvc: '•••', // optional — default •••
                                expiry: '••/••', // optional — default ••/••
                                name: 'Nome Completo', // optional — default FULL NAME
                              }}
                              // the class name attribute to add to the input field and the corresponding part of the card element,
                              // when the input is valid/invalid.
                              classes={{
                                valid: 'valid-input', // optional — default 'jp-card-valid'
                                invalid: 'invalid-input', // optional — default 'jp-card-invalid'
                              }}
                              // specify whether you want to format the form inputs or not
                              formatting={true} // optional - default true
                            >
                              <div className="row">
                                <div className="col-sm-12">
                                  {/* selecione a bandeira */}
                                  <div className="form-group">
                                    <label htmlFor="CCbrand">
                                      Bandeira do Cartão
                                    </label>
                                    <select
                                      className="form-control"
                                      id="CCbrand"
                                      value={bandeira}
                                      required
                                      onChange={e => {
                                        setCardFlag(e.target.value)
                                      }}
                                    >
                                      <option value="visa">Visa</option>
                                      <option value="mastercard">
                                        Mastercard
                                      </option>
                                      <option value="amex">Amex</option>
                                      <option value="elo">Elo</option>
                                      <option value="diners">Diners</option>
                                      <option value="discover">Discover</option>
                                      <option value="jcb">JCB</option>
                                    </select>
                                  </div>
                                  <div className="form-group">
                                    <label htmlFor="address-zip">
                                      Numero do Cartao *
                                    </label>
                                    <InputMask
                                      className="form-control"
                                      type="text"
                                      mask="9999 9999 9999 9999"
                                      maskChar={null}
                                      value={numberCardForm}
                                      onChange={e =>
                                        setNumberCardForm(e.target.value)
                                      }
                                      placeholder="Nº Cartao"
                                      name="CCnumber"
                                      onBlur={validarCardNumber}
                                    />
                                    <div className="invalid-feedback">
                                      Preencha este campo.
                                    </div>
                                  </div>
                                </div>
                                <div className="col-sm-12">
                                  <div className="form-group">
                                    <label htmlFor="address-zip">Nome Completo de Titular</label>
                                    <InputMask
                                      className="form-control"
                                      type="text"
                                      maskChar={null}
                                      value={nameForm}
                                      onChange={e =>
                                        setNameForm(e.target.value)
                                      }
                                      placeholder="Nome Completo"
                                      name="CCname"
                                      onBlur={validarNome}
                                    />
                                    <div className="invalid-feedback">
                                      Preencha este campo.
                                    </div>
                                  </div>
                                </div>
                                <div className="col-sm-4">
                                  <div className="form-group">
                                    <label htmlFor="address-zip">
                                      Validade *
                                    </label>
                                    <InputMask
                                      className="form-control"
                                      type="text"
                                      mask="99/99"
                                      maskChar={'_'}
                                      value={validadeForm}
                                      onChange={e =>
                                        setVaildadeForm(e.target.value)
                                      }
                                      placeholder="MM/AA"
                                      name="CCexpiry"
                                      onBlur={validarValidade}
                                    />
                                    <div className="invalid-feedback">
                                      Preencha este campo.
                                    </div>
                                  </div>
                                </div>

                                <div className="col-sm-4">
                                  <div className="form-group">
                                    <label htmlFor="address-fn">CVV *</label>
                                    <input
                                      className="form-control"
                                      type="text"
                                      id="address-fn"
                                      required
                                      value={cvvForm}
                                      placeholder="CVV"
                                      onChange={event =>
                                        setCvvForm(event.target.value)
                                      }
                                      name="CCcvc"
                                      onBlur={validarCvv}
                                    />
                                    <div className="invalid-feedback">
                                      Preencha este campo.
                                    </div>
                                  </div>
                                </div>

                                <div className="col-sm-4">
                                  <div className="form-group">
                                    <label htmlFor="number">Parcela *</label>
                                    <select
                                      className="custom-select mr-2"
                                      onChange={(e) => { setQtdParcelas(e.target.value) }}
                                    >
                                      <option value="">Selecione</option>
                                      {parcelasMap.map(parcela => {
                                        if (parcela.limit > getTotal) return null
                                        return <option value={parcela.value}>{`${parcela.value}x ${new Intl.NumberFormat('pt-BR', {
                                          style: 'currency',
                                          currency: 'BRL',
                                        }).format(getTotal / parcela.value)}`}</option>
                                      })}

                                    </select>
                                    <div className="invalid-feedback">
                                      Preencha este campo.
                                    </div>
                                  </div>
                                </div>
                                <div className="col-sm-12">
                                  <div className="form-group">
                                    <label htmlFor="address-zip">CPF do Titular *</label>
                                    <InputMask
                                      className="form-control"
                                      mask="999.999.999-99"
                                      maskChar={null}
                                      value={cpfForm}
                                      onChange={e => setCpfForm(e.target.value)}
                                      placeholder="CPF"
                                    />
                                    <div className="invalid-feedback">
                                      Preencha este campo.
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </CardReactFormContainer>
                          </>

                          <div className="col-12 form-group">
                            <small>(*) Campos obrigatórios</small>
                          </div>
                        </FormBox>
                      </form>
                    </div>

                    <div className="col-sm-6">
                      <>
                        <CardBox>
                          <Cartao id="card-wrapper" />
                        </CardBox>
                      </>
                    </div>
                  </>
                )}

                {tipo_pagamento == 1 && (
                  <>
                    <div className="col-sm-6">
                      <div className="row" style={{ padding: '10px' }}>
                        <h4 className="h6">
                          {' '}
                          <strong>Forma de pagamento:</strong> &nbsp; PIX
                        </h4>
                        <ul className="list-unstyled font-size-sm">
                          <li>
                            <strong>Chave (CNPJ):</strong>&nbsp;
                            30.945.774/0001-56
                          </li>
                        </ul>
                        <p style={{ fontSize: '12px' }}>
                          <strong>Obs:</strong>&nbsp; Após a{' '}
                          <strong>conclusão</strong> do pagamento, envie o
                          comprovante para o nosso whatsapp.
                          Obrigado!
                        </p>
                        <div>
                          <a href="https://wa.me/5509132335122" className='d-flex align-items-center '>
                            <img src="/img/zapIcon.png" style={{ height: '30px' }} className="pr-2" />
                            <p className='mb-0'>(91) 32335122</p>
                          </a>

                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      {handleCardAndLogo(tipo_pagamento)}
                    </div>
                  </>
                )}

                {tipo_pagamento == 3 && (
                  <>
                    <div className="col-sm-6">
                      <div className="row" style={{ padding: '10px' }}>
                        <h4 className="h6">
                          {' '}
                          <strong>Forma de pagamento:</strong> &nbsp;
                          Transferência Bancária
                        </h4>
                        <ul className="list-unstyled font-size-sm">
                          <li>
                            Banco:&nbsp;
                            <span style={{ color: '#000' }}>
                              Santander&nbsp;
                            </span>
                          </li>
                          <li>
                            Agencia:&nbsp;
                            <span style={{ color: '#000' }}>3524&nbsp;</span>
                          </li>
                          <li>
                            Conta corrente:&nbsp;
                            <span style={{ color: '#000' }}>
                              13003722-2&nbsp;
                            </span>
                          </li>
                          <li>
                            CNPJ:&nbsp;
                            <span style={{ color: '#000' }}>
                              {' '}
                              30.945.774/0001-56 &nbsp;
                            </span>
                          </li>
                        </ul>
                        <p style={{ fontSize: '12px' }}>
                          <strong>Obs:</strong>&nbsp; Após a{' '}
                          <strong>conclusão</strong> do pedido, envie o
                          comprovante de pagamento para o nosso whatsapp.
                          Obrigado!
                        </p>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      {handleCardAndLogo(tipo_pagamento)}
                    </div>
                  </>
                )}
              </div>
            </div>
            {!disabledButton && (
              <NavigationDesktop
                onClick={() => postPedido()}
                linkBack={
                  subSite != null
                    ? `/site/${subSite.param}/checkout-endereco`
                    : '/checkout-endereco'
                }
                linkNext={subSite != null ? `/site/${subSite.param}/` : '/'}
                textBack="Voltar ao pagamento"
                textNext="Concluir"
                loading={loading}
              />
            )}
          </section>
          {/* <!-- Sidebar--> */}
          <AsideCheckout
            descount="0.0"
            subtotal="60.00"
            shipping="10.00"
            total="70.00"
            products={false}
          />
        </div>
        {/* <!-- Navigation (mobile)--> */}
        {!disabledButton && (
          <NavigationMobile
            onClick={() => postPedido()}
            linkBack={
              subSite != null
                ? `/site/${subSite.param}/checkout-endereco`
                : '/checkout-endereco'
            }
            linkNext={subSite != null ? `/site/${subSite.param}/` : '/'}
            textBack="Voltar ao pagamento"
            textNext="Concluir"
            loading={loading}
          />
        )}
      </div>
    </>
  )
}

export default inject(
  'PedidoStore',
  'ClientStore',
  'CarrinhoStore',
  'HeaderStore'
)(observer(CheckoutConclusion))
