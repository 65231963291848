import React, { useEffect, useState } from 'react'
import { inject, observer } from 'mobx-react'

import subSiteConfig from '../../services/subSite'
import { useParams, Link } from 'react-router-dom'

import Header from '../../components/revendedor-components/Header'
import MainBanner from '../../components/revendedor-components/MainBanner'
import SecondBanner from '../../components/revendedor-components/SecondBanner'
import AccordionDetails from '../../components/revendedor-components/AccordionDetails'
import ThirdBanner from '../../components/revendedor-components/ThirdBanner'
import Steps from '../../components/revendedor-components/Steps'
import Comentaries from '../../components/revendedor-components/Comentaries'
import ModalRegister from '../../components/revendedor-components/ModalRegister'

import { Area } from './styles'

const RevendedorHome = props => {
  const {
    visible,
    setVisible,
    promoVisible,
    setPromoVisible,
    setSubSite,
    subSite,
    setSubSiteParam,
  } = props.HeaderStore

  let { vendedor } = useParams()

  useEffect(() => {
    console.log('LinkProps:', {
      URL: props.location,
    })

    console.log('Props revendedro: ', props)

    if (visible.on) {
      setVisible(false)
    }
  }, [])

  //subsite
  const subSiteLayout = async v => {
    return await subSiteConfig.handleSubsite(v)
  }

  const reqSubsite = async v => {
    let obj = await subSiteConfig.getUrlParameter(v)
    setSubSite(obj)
  }

  useEffect(() => {
    reqSubsite(vendedor)
  }, [])

  useEffect(() => {
    console.log('subSite: ', subSite)

    if (subSite != null) {
      console.log('subSite: ENTROU')

      setSubSiteParam(subSite)
      subSiteLayout(subSite)
    }
  }, [subSite])
  //subsite

  return (
    <Area>
      <Header />
      <MainBanner />
      <SecondBanner />
      <AccordionDetails />
      <ThirdBanner style={{ marginTop: '10px' }} />
      <Steps />
      <Comentaries />
      <ModalRegister />
    </Area>
  )
}

export default inject('HeaderStore')(observer(RevendedorHome))
