import React from 'react';
import banner2 from '../../src/components/BannerMeio';
const CarouselMini = ({ data, tipo }) => {
  return (
    <div id="carouselExampleSlidesOnly" className="carousel slide mt-5" data-ride="carousel">
      <div className="carousel-inner">
            {
              data.filter((item) => item.tipo == tipo).map((item, key) =>
                <a key={key} href={item.link} className={key == 0 ? ' carousel-item  active bannerMini' : 'carousel-item bannerHome'} style={{ background: "url(" + item.imagem_url + ") no-repeat center top / cover" }}>

                </a>
              )
          }
      
        <a className="carousel-control-prev" href="#carouselExampleSlidesOnly" role="button" data-slide="prev">
          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
          <span className="sr-only">Previous</span>
        </a>
        <a className="carousel-control-next" href="#carouselExampleSlidesOnly" role="button" data-slide="next">
          <span className="carousel-control-next-icon" aria-hidden="true"></span>
          <span className="sr-only">Next</span>
        </a>


      </div>
    </div>
  );

}

export default CarouselMini;