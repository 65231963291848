import React, { useState, useEffect } from 'react'
import { inject, observer } from 'mobx-react'
import { useParams } from 'react-router-dom'
import { useAlert } from 'react-alert'
import subSiteConfig from '../../services/subSite'

const OrderItem = ({
  idOrder,
  dateOrder,
  status,
  value,
  data,
  setDataModal,
  cor,
  setReclamar,
  HeaderStore,
}) => {
  const { setSubSite, subSite, setSubSiteParam } = HeaderStore
  const { vendedor } = useParams()

  const handleDataModal = () => {
    setDataModal(data)
  }

  //subsite
  const subSiteLayout = async v => {
    return await subSiteConfig.handleSubsite(v)
  }

  const reqSubsite = async v => {
    let obj = await subSiteConfig.getUrlParameter(v)

    setSubSite(obj)
  }

  useEffect(() => {
    reqSubsite(vendedor)
  }, [])

  useEffect(() => {
    console.log('subSite: ', subSite)

    if (subSite != null) {
      console.log('subSite: ENTROU')
      setSubSiteParam(subSite)
      subSiteLayout(subSite)
    }
  }, [subSite])
  //subsite

  return (
    <>
      <tr>
        <td className="py-3">
          <a
            className="nav-link-style font-weight-medium font-size-sm"
            href="#order-details"
            data-toggle="modal"
            onClick={handleDataModal}
          >
            #{idOrder}
          </a>
        </td>

        <td className="py-3">{dateOrder}</td>

        <td className="py-3" style={{ color: cor }}>
          {status}
        </td>

        <td className="py-3">R$ {value}</td>

        <td className="py-3">
          <a
            href="#order-reclamar"
            data-toggle="modal"
            onClick={setReclamar}
            className="btn btn-primary"
          >
            Relatar problema
          </a>
        </td>
      </tr>
    </>
  )
}

export default inject('ClientStore', 'HeaderStore')(observer(OrderItem))
