import React, { useEffect, useState } from "react";
//import { inject, observer } from "mobx-react";
import api from "../../services/api";
import { closeModal } from "../../services/helpers";
import { Button, Modal } from "react-bootstrap";

import {
  ContainerAlert,
  ContainerInfo,
  ContainerX,
  SpanButton,
  Cadastrado,
  Area,
  AreaBtn,
  VoltarBtn,
  Promocao,
  InternalDiv,
} from "./styles";

const ModalPromocao = (props) => {
  //const { dados, token, setToken, setDados } = props.ClientStore;
  const Img = props.data?.imagemUrl;

  useEffect(() => {}, []);

  return (
    <>
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        style={{
          position: "absolute",
        }}
      >
        <InternalDiv>
          <Promocao src={Img} />

          <Button
            style={{
              zIndex: 999,
              alignSelf: "flex-end",
              position: "absolute",
              borderRadius: "50%",
              width: 30,
              height: 30,
              textAlign: "center",
              padding: 5,
              top: 20,
              right: 20,
            }}
            onClick={props.onHide}
          >
            X
          </Button>
        </InternalDiv>
      </Modal>
    </>
  );
};

export default ModalPromocao;
