import React, { useState } from 'react'
import api from '../../services/api'

import ButtonLoading from '../ButtonLoading'
import { inject, observer } from 'mobx-react'

const AddressEdit = ({
  zip_code,
  address,
  number,
  complement,
  neighborhood,
  city,
  state,
  id,
  setViewEdit,
  alert,
  getMyAddresses,
  ClientStore,
}) => {
  const { token } = ClientStore

  const [cep, setCep] = useState(zip_code)
  const [rua, setRua] = useState(address)
  const [numero, setNumero] = useState(number)
  const [complemento, setComplemento] = useState(complement ?? '')
  const [bairro, setBairro] = useState(neighborhood)
  const [cidade, setCidade] = useState(city)
  const [estado, setEstado] = useState(state)
  const [loading, setLoading] = useState(false)

  const updateAddress = async () => {
    setLoading(true)

    try {
      let response = await api.post(
        '/endereco/edit',
        {
          id,
          rua,
          cep,
          numero,
          complemento,
          bairro,
          cidade,
          estado,
        },
        {
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + token,
          },
        }
      )

      if (!response.data.errors || response.data.errors != '') {
        alert.success('Endereço atualizado com sucesso!')
        setLoading(false)
        setViewEdit(false)
        getMyAddresses()
      }
    } catch (error) {
      setLoading(false)
      alert.error('Ocorreu um erro ao tentar atualizar o endereço.')
      console.log(error)
    }
  }

  return (
    <div className="row justify-content-center">
      <div className="col-sm-12">
        <div className="form-group">
          <label htmlFor="address-zip">CEP *</label>

          <input
            className="form-control"
            type="text"
            id="address-zip"
            required
            value={cep}
            onChange={event => setCep(event.target.value)}
          />

          <div className="invalid-feedback">Preencha este campo.</div>
        </div>
      </div>

      <div className="col-sm-8">
        <div className="form-group">
          <label htmlFor="address-fn">Endereço *</label>

          <input
            className="form-control"
            type="text"
            id="address-fn"
            required
            value={rua}
            onChange={event => setRua(event.target.value)}
          />

          <div className="invalid-feedback">Preencha este campo.</div>
        </div>
      </div>

      <div className="col-sm-4">
        <div className="form-group">
          <label htmlFor="number">Número *</label>

          <input
            className="form-control"
            type="text"
            id="number"
            required
            value={numero}
            onChange={event => setNumero(event.target.value)}
          />
          <div className="invalid-feedback">Preencha este campo.</div>
        </div>
      </div>

      <div className="col-sm-12">
        <div className="form-group">
          <label htmlFor="address-ln">Complemento</label>

          <input
            className="form-control"
            type="text"
            id="address-ln"
            value={complemento}
            onChange={event => setComplemento(event.target.value)}
          />
        </div>
      </div>

      <div className="col-sm-4">
        <div className="form-group">
          <label htmlFor="address-company">Bairro *</label>

          <input
            className="form-control"
            type="text"
            id="address-company"
            required
            value={bairro}
            onChange={event => setBairro(event.target.value)}
          />
          <div className="invalid-feedback">Preencha este campo.</div>
        </div>
      </div>

      <div className="col-sm-4">
        <div className="form-group">
          <label htmlFor="city">Cidade *</label>

          <input
            className="form-control"
            type="text"
            id="city"
            required
            value={cidade}
            onChange={event => setCidade(event.target.value)}
          />

          <div className="invalid-feedback">Preencha este campo.</div>
        </div>
      </div>

      <div className="col-sm-4">
        <div className="form-group">
          <label htmlFor="state">Estado *</label>

          <input
            className="form-control"
            type="text"
            id="state"
            required
            value={estado}
            onChange={event => setEstado(event.target.value)}
          />

          <div className="invalid-feedback">Preencha este campo.</div>
        </div>
      </div>

      <div className="col-12 form-group">
        <small>(*) Campos obrigatórios</small>
      </div>

      <div className="col-12">
        <hr className="mt-2 mb-3" />

        <div className="d-flex flex-wrap justify-content-center align-items-center">
          <div className="custom-control custom-checkbox d-block"></div>

          <ButtonLoading
            class="btn btn-primary mt-3 mt-sm-0"
            onClick={updateAddress}
            title="Atualizar Endereço"
            loading={loading}
          />
        </div>
      </div>
    </div>
  )
}

export default inject('ClientStore')(observer(AddressEdit))
