import { inject, observer } from 'mobx-react';
import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { formatNumber } from '../../services/helpers';
import subSiteConfig from '../../services/subSite';



const CardCombineProduct = (props) => {
  const { setFavorito } = props.ProdutoStore;
  const { visible, setVisible, setSubSite, subSite, setSubSiteParam } = props.HeaderStore;


  //subsite
  const subSiteLayout = async (v) => {
      return await subSiteConfig.handleSubsite(v)

  }

  const reqSubsite = async (v) => {
      let obj = await subSiteConfig.getUrlParameter(v)
      setSubSite(obj);
  }

  useEffect(() => {
          
      reqSubsite(vendedor)

  },[]) 

  useEffect(() => {
          

      console.log("subSite: ", subSite)
      if(subSite!=null) {
          console.log("subSite: ENTROU")
          setSubSiteParam(subSite)
          subSiteLayout(subSite)
      }

  },[subSite]) 
  //subsite



  let { vendedor } = useParams();
  return (
    <div className="card product-card card-static text-center">
      <button className="btn-wishlist btn-sm" type="button" onClick={() => setFavorito(props.item)} data-toggle="tooltip" data-placement="left" title="Adicionar aos favoritos"><i className="czi-heart"></i></button>
      <a className="card-img-top d-block overflow-hidden text-center" href={subSite != null?`/site/${subSite.param}/produto/${props.item.id}/${props.item.slug}` : '/produto/' + props.item.id + '/' + props.item.slug}><img style={{ maxHeight: 200 }} src={props.item.url_fotos[0] && props.item.url_fotos[0]} alt="Product" /></a>
      <div className="card-body py-2"><a className="product-meta d-block font-size-xs pb-1" href={subSite != null?`/site/${subSite.param}/produto/${props.item.id}/${props.item.slug}` : '/produto/' + props.item.id + '/' + props.item.slug}>{props.item.categorias[0] && props.item.categorias[0].nome}</a>
        <h3 className="product-title font-size-sm"><a href={subSite != null?`/site/${subSite.param}/produto/${props.item.id}/${props.item.slug}` : '/produto/' + props.item.id + '/' + props.item.slug}>{props.item.nome}</a></h3>
        <div className="d-flex justify-content-between">
          <div className="product-price text-accent text-center" style={{ width: '100%' }}>
            R${props.item.desconto.length ? formatNumber(props.item.preco_final) : formatNumber(props.item.preco)}
            {(props.item.desconto.length > 0) &&
              <del className="font-size-sm text-muted"> R$ {formatNumber(props.item.preco)}</del>
            }
          </div>

        </div>
      </div>
    </div>
  );
}

export default inject("ProdutoStore", "HeaderStore")(observer(CardCombineProduct));