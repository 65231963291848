import React, { useState, useEffect } from 'react'
import { inject, observer } from 'mobx-react'
import ButtonLoading from '../components/ButtonLoading'
import InfoFooter from '../components/InfoFooter'
import { formatNumber } from '../services/helpers'
import api from '../services/api'
import { IsEmail } from '../services/helpers'
import { useAlert } from 'react-alert'
import Cookies from 'js-cookie'

const Footer = props => {
  const { getTotal, produtos } = props.CarrinhoStore
  const { menus } = props.HeaderStore
  const [email, setEmail] = useState('')
  const [loading, setLoading] = useState(false)
  const { visible, subSite } = props.HeaderStore
  const alert = useAlert()

  const newsletter = async () => {
    if (email == '' || !IsEmail(email)) {
      alert.show('Preencha o campo com e-mail válido.', { type: 'info' })
      return
    }

    setLoading(true)

    try {
      let response = await api.post('/newsletter', {
        email: email,
      })

      if (!response.data.errors) {
        setLoading(false)
        alert.show('E-mail cadastrado com sucesso.', { type: 'success' })
        setEmail('')
      } else {
        setLoading(false)
        alert.show('E-mail já cadastrado.', { type: 'info' })
        setEmail('')
      }
    } catch (error) {
      setLoading(false)
      alert.show('Ocorreu um erro desconhecido.', { type: 'error' })
    }
  }
  //subSite
  useEffect(() => {
    // console.log('subSite data!!🟢🟢🟢: ', 'props.data.vendedor_id')
    // Cookies.set('subSite', props.data)

  }, [props.data])
  //fim subSite

  return (
    <footer className="bg-secondary pt-5">
      <div className="container">
        <div className="row pb-2">
          <div className="col-md-3 col-sm-3">
            <div className="widget widget-links pb-2 mb-4">
              <h3 className="widget-title">Departamentos da loja</h3>

              <div className="row">
                <div className="col-12 col-md-4">
                  <ul className="widget-list">
                    {menus.categorias.map((item, key) => (
                      <li key={item.slug} className="widget-list-item">
                        <a
                          className="widget-list-link"
                          href={
                            props.data != null
                              ? `/site/${props.data.param}/categoria/${item.nome.toLowerCase()}/${item.id}`
                              : `/categoria/${item.nome.toLowerCase()}/${item.id}`
                          }
                        >
                          {item.nome}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-3 col-sm-3">
            <div className="widget widget-links pb-2 mb-4">
              <h3 className="widget-title">Conta &amp; Informação de Envio</h3>

              <ul className="widget-list">
                <li className="widget-list-item">
                  <a
                    className="widget-list-link"
                    href={
                      props.data != null
                        ? `/site/${props.data.param}/perfil`
                        : '/perfil'
                    }
                  >
                    Sua conta
                  </a>
                </li>

                <li className="widget-list-item">
                  <a
                    className="widget-list-link"
                    href={
                      props.data != null
                        ? `/site/${props.data.param}/enderecos`
                        : '/enderecos'
                    }
                  >
                    Endereços
                  </a>
                </li>

                <li className="widget-list-item">
                  <a
                    className="widget-list-link"
                    href={
                      props.data != null
                        ? `/site/${props.data.param}/favoritos`
                        : '/favoritos'
                    }
                  >
                    Favoritos
                  </a>
                </li>

                <li className="widget-list-item">
                  <a
                    className="widget-list-link"
                    href={
                      props.data != null
                        ? `/site/${props.data.param}/pedidos`
                        : '/pedidos'
                    }
                  >
                    Pedidos
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-md-3 col-sm-3">
            <div className="widget widget-links pb-2 mb-4">
              <h3 className="widget-title">Sobre</h3>

              <ul className="widget-list">
                <li className="widget-list-item">
                  <a
                    className="widget-list-link"
                    href={
                      props.data != null
                        ? `/site/${props.data.param}/quem-somos`
                        : '/quem-somos'
                    }
                  >
                    Quem Somos
                  </a>
                </li>
              </ul>

              <ul className="widget-list">
                <li className="widget-list-item">
                  <a
                    className="widget-list-link"
                    href={
                      props.data != null
                        ? `/site/${props.data.param}/onde-encontrar`
                        : '/onde-encontrar'
                    }
                  >
                    Onde Encontrar
                  </a>
                </li>
              </ul>

              <ul className="widget-list">
                <li className="widget-list-item">
                  <a
                    className="widget-list-link"
                    href={
                      props.data != null
                        ? `/site/${props.data.param}/contatos`
                        : '/contatos'
                    }
                  >
                    Contatos
                  </a>
                </li>
              </ul>

              <ul className="widget-list">
                <li className="widget-list-item">
                  <a
                    className="widget-list-link"
                    href={
                      props.data != null
                        ? `/site/${props.data.param}/trocas-devolucoes`
                        : '/trocas-devolucoes'
                    }
                  >
                    Troca e Devolução
                  </a>
                </li>
              </ul>

              <ul className="widget-list">
                <li className="widget-list-item">
                  <a
                    className="widget-list-link"
                    href={
                      props.data != null
                        ? `/site/${props.data.param}/garantias`
                        : '/garantias'
                    }
                  >
                    Garantia
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-md-3">
            <div className="widget pb-2 mb-4">
              <h3 className="widget-title pb-1">Mantenha-se informado</h3>

              <form className="validate">
                <div className="input-group input-group-overlay flex-nowrap">
                  <div className="input-group-prepend-overlay">
                    <span className="input-group-text text-muted font-size-base">
                      <i className="czi-mail"></i>
                    </span>
                  </div>

                  <input
                    onChange={e => setEmail(e.target.value)}
                    value={email}
                    className="form-control prepended-form-control"
                    type="email"
                    name="EMAIL"
                    id="mce-EMAIL"
                    placeholder="Seu e-mail"
                    required
                  />

                  <div className="input-group-append">
                    <ButtonLoading
                      class="btn btn-primary"
                      onClick={() => newsletter()}
                      loading={loading}
                      title="Se inscrever"
                    />
                  </div>
                </div>

                <div
                  style={{ position: 'absolute', left: '-5000px' }}
                  aria-hidden="true"
                >
                  <input
                    type="text"
                    name="b_c7103e2c981361a6639545bd5_29ca296126"
                    tabIndex="-1"
                  />
                </div>

                <small className="form-text opacity-50" id="mc-helper">
                  * Assine nossa newsletter para receber ofertas de descontos
                  antecipados, atualizações e informações sobre novos produtos.
                </small>

                <div className="subscribe-status"></div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div
        className="pt-5"
        style={{
          backgroundColor: '#ecf2f7',
          marginBotton: '0px',
          padding: '0px',
        }}
      >
        <div className="container">
          <InfoFooter data={menus} />
        </div>
      </div>

      {visible.on ? (
        <div className="cz-handheld-toolbar">
          <div className="d-table table-fixed w-100">
            <a
              className="d-table-cell cz-handheld-toolbar-item"
              href="account-wishlist.html"
            >
              <span className="cz-handheld-toolbar-icon">
                <i className="czi-heart"></i>
              </span>

              <span className="cz-handheld-toolbar-label">Favoritos</span>
            </a>

            <a
              className="d-table-cell cz-handheld-toolbar-item"
              href="#navbarCollapse"
              data-toggle="collapse"
              onClick={() => window.scrollTo(0, 0)}
            >
              <span className="cz-handheld-toolbar-icon">
                <i className="czi-menu"></i>
              </span>

              <span className="cz-handheld-toolbar-label">Menu</span>
            </a>

            <a
              className="d-table-cell cz-handheld-toolbar-item"
              href={
                props.data != null
                  ? `/site/${props.data.param}/carrinho`
                  : '/carrinho'
              }
            >
              <span className="cz-handheld-toolbar-icon">
                <i className="czi-cart"></i>

                <span className="badge badge-primary badge-pill ml-1">
                  {produtos.length}
                </span>
              </span>

              <span className="cz-handheld-toolbar-label">
                R$ {formatNumber(getTotal)}
              </span>
            </a>
          </div>
        </div>
      ) : (
        <></>
      )}
    </footer>
  )
}

export default inject('CarrinhoStore', 'HeaderStore')(observer(Footer))
/****
  <div className="col-12 col-md-4">
    <ul className="widget-list">
      {menus.categorias.map(
        (item, key) =>
          key > 6 && (
            <li key={item.slug} className="widget-list-item">
              <a
                className="widget-list-link"
                href={
                  props.data != null
                    ? `/site/${props.data.param}/categoria/${item.slug}`
                    : `/categoria/${item.slug}`
                }
              >
                {item.nome}
              </a>
            </li>
          )
      )}
    </ul>
  </div>
*/
