import React, { useEffect, useState } from 'react';
import PageTitle from '../components/PageTitle';
import FavoriteProduct from '../components/Profile/FavoriteProduct';
import AsideProfile from '../components/Profile/AsideProfile';
import { inject, observer } from 'mobx-react';
import Toolbar from '../components/Profile/Toolbar';
import { formatNumber } from '../services/helpers';
import subSiteConfig from '../services/subSite';
import {
  useParams
} from "react-router-dom";

const Favorites = (props) => {

  const { dados } = props.ClientStore;
  const { favorito, setFavorito } = props.ProdutoStore;
  const { setSubSite, subSite } = props.HeaderStore;
  let { vendedor } = useParams();

  console.log(favorito);


     
    //subsite
    const subSiteLayout = async (v) => {
      return await subSiteConfig.handleSubsite(v)

  }

  const reqSubsite = async (v) => {
      let obj = await subSiteConfig.getUrlParameter(v)
      setSubSite(obj);
  }

  useEffect(() => {
          
      reqSubsite(vendedor)

  },[]) 

  useEffect(() => {
          

      console.log("subSite: ", subSite)
      if(subSite!=null) {
          console.log("subSite: ENTROU")
          subSiteLayout(subSite)
      }

  },[subSite]) 
//subsite

  return (
    <>
      <PageTitle nameRoute="Favoritos"  url={subSite != null ?  `/site/${subSite.param}/favoritos` : '/favoritos'} title="Meus favoritos" />

      <div className="container pb-5 mb-2 mb-md-4">
        <div className="row">
          {/* <!-- Sidebar--> */}
          <AsideProfile
            name={dados.nome}
            email={dados.email}
            type='favoritos'
          />
          {/* <!-- Content  --> */}
          <section className="col-lg-8">
            {/* <!-- Toolbar--> */}
            <Toolbar title="Seus itens adicionados aos favoritos:" />

            {favorito.map((item) =>
              <FavoriteProduct
                title={item.nome}
                image={item.url_fotos[0] ? item.url_fotos[0] : null }
                brand={item.marca.nome}
                deletar={() => setFavorito(item)}
                id={item.id}
                key={item.id}
                slug={item.slug}
                price={formatNumber(item.preco)}
              />
            )}

            {!favorito.length && 
               <div className="text-center">
                <br/><br/><i className="h2 text-body czi-heart"></i><br/>
                <span>Nenhum produto favoritado.</span>
              </div>
            }
          </section>
        </div>
      </div>
    </>
  );
}

export default inject('ClientStore', 'ProdutoStore', "HeaderStore")(observer(Favorites));