/* eslint-disable react-hooks/exhaustive-deps */

import { inject, observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import { useAlert } from 'react-alert'
import Skeleton from 'react-loading-skeleton'
import { useParams, useRouteMatch } from 'react-router-dom'
import CardProductHome from '../components/CardProductHome'
import TitleCardProduct from '../components/TitleCardProduct'
import api from '../services/api'
import subSiteConfig from '../services/subSite'
import ButtonLoading from '../components/ButtonLoading'
import config from '../config'

const Categoria = props => {
  const [products, setProducts] = useState([])
  const [buscou, setBuscou] = useState(false)
  const [comprimento, setComprimento] = useState(null)
  const [filtroList, setFiltroList] = useState(false)
  const [filtro, setFiltro] = useState([])
  const [page, setPage] = useState(1)
  const [loading, setLoading] = useState(false)
  const tamanhoJoia = [
    { tamanho: 42 },
    { tamanho: 34 },
    { tamanho: 50 },
    { tamanho: 60 },
    { tamanho: 70 },
    { tamanho: 80 },
  ]


  useEffect(() => { }, [filtro, filtroList])


  const alert = useAlert()
  const { slug, subcategoria_id, categoria_id, vendedor } = useParams()
  const { token, dados } = props.ClientStore
  const { setSubSite, subSite } = props.HeaderStore


  const subSiteLayout = async v => {
    return await subSiteConfig.handleSubsite(v)
  }

  const reqSubsite = async v => {
    let obj = await subSiteConfig.getUrlParameter(v)

    setSubSite(obj)
  }

  useEffect(() => {
    reqSubsite(vendedor)
  }, [])

  useEffect(() => {
    console.log('subSite: ', subSite)

    if (subSite != null) {
      console.log('subSite: ENTROU')
      subSiteLayout(subSite)
    }
  }, [subSite])
  //subsite
  async function getPaginate() {

    setLoading(true)
    try {


      let response = await api.get(
        '/loja/paginate/' +
        config.loja_id +
        '?page=' +
        (page + 1) +
        '&categoria=' + categoria_id + (subcategoria_id ? '&subcategoria=' + subcategoria_id : ''),
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
          },
        }
      )
      if (response.data.data.produtos.data.length > 0) {
        setPage(page + 1)
      }
      if (response.data.data.produtos) {

        setProducts([...products, ...response.data.data.produtos.data])
      }



      setLoading(false)
    } catch (error) {
      setLoading(false)

      alert.show('Ocorreu um erro, verifique sua conexão.', { type: 'error' })
    }
  }


  const Buscar = async () => {
    setLoading(true)
    try {

      let response = await api.get(
        '/loja/paginate/' +
        config.loja_id +
        '?page=' +
        (page) +
        '&categoria=' + categoria_id + (subcategoria_id ? '&subcategoria=' + subcategoria_id : ''),
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
          },
        }
      )
      setLoading(false)

      setPage(response.data.data.produtos.current_page)
      if (!filtroList) {
        setBuscou(true)
      }

      if (response.data.data.produtos) {


        if (filtroList === false) {
          setProducts(response.data.data.produtos.data)

          console.log('Litagem: ', response.data.data.produtos)



          if (response.data.data.produtos.data.every(item => {
            return item.comprimento === null && item.comprimento > 5
          })) {
            setComprimento(null)
          } else {

            setComprimento(response.data.data.produtos.data[0].comprimento)
          }
          console.log(comprimento)


        }

        console.log('Produtos: ', response.data.data.produtos.data)
      } else {
        alert.show('Ocorreu um erro desconhecido.', { type: 'error' })
      }
    } catch (error) {

      console.log(error)

    }
  }

  const handleTamanho = valor => {
    Buscar()

    handleListfiltro(valor)

    return valor
  }

  const handleOrderBy = value => {
    return
  }

  const handleListfiltro = valor => {
    setFiltroList(true)

    console.log('vai pro switch')
    console.log('valor: ', valor)

    switch (parseInt(valor)) {



      case 34:
        //Instruções executadas quando o resultado da expressão for igual á valorN
        let arr34 = []

        for (let i = 0; i < products.length; i++) {
          if (parseInt(products[i].comprimento) === 34) {
            arr34.push(products[i])
          }
        }

        console.log('Tamanho: ', arr34)
        // dá [20, 4, 3, 0]

        setFiltro(arr34)

        break

      case 42:
        //Instruções executadas quando o resultado da expressão for igual á valor1
        console.log('entrou no 42 :', parseInt(valor))

        let arr42 = []

        for (let i = 0; i < products.length; i++) {
          if (parseInt(products[i].comprimento) === 42) {
            arr42.push(products[i])
          }
        }

        setFiltro(arr42)

        break

      case 50:
        //Instruções executadas quando o resultado da expressão for igual á valor2
        console.log('entrou no 42 :', parseInt(valor))

        let arr50 = []

        for (let i = 0; i < products.length; i++) {
          if (parseInt(products[i].comprimento) === 50) {
            arr50.push(products[i])
          }
        }

        setFiltro(arr50)

        break

      case 60:
        //Instruções executadas quando o resultado da expressão for igual á valorN
        console.log('entrou no 42 :', parseInt(valor))

        let arr60 = []

        for (let i = 0; i < products.length; i++) {
          if (parseInt(products[i].comprimento) === 60) {
            console.log(`${i}: `, valor)
            arr60.push(products[i])
          }
        }

        setFiltro(arr60)

        break

      case 70:
        //Instruções executadas quando o resultado da expressão for igual á valorN
        console.log('entrou no 42 :', parseInt(valor))

        let arr70 = []

        for (let i = 0; i < products.length; i++) {
          if (parseInt(products[i].comprimento) === 70) {
            arr70.push(products[i])
          }
        }

        setFiltro(arr70)

        break

      case 80:
        //Instruções executadas quando o resultado da expressão for igual á valorN
        console.log('entrou no 42 :', parseInt(valor))

        let arr80 = []

        for (let i = 0; i < products.length; i++) {
          if (parseInt(products[i].comprimento) === 80) {
            arr80.push(products[i])
          }
        }

        setFiltro(arr80)

        break
      case 0:
        //Instruções executadas quando o resultado da expressão for igual á valorN
        console.log('entrou no Todos :', valor)
        setFiltroList(false)
        setFiltro(products)

        break

      case 1:
        //Instruções executadas quando o resultado da expressão for igual á valorN
        console.log('entrou no 42 :', parseInt(valor))

        let menorPreco = products.sort(function compare(a, b) {
          if (parseFloat(a.preco) < parseFloat(b.preco)) return -1
          if (parseFloat(a.preco) > parseFloat(b.preco)) return 1

          return 0
        })

        console.log('Menor: ', menorPreco)
        // dá [0, 3, 4, 20]

        setFiltro(menorPreco)

        break

      case 2:
        //Instruções executadas quando o resultado da expressão for igual á valorN
        console.log('entrou no 42 :', parseInt(valor))

        let maiorPreco = products.sort(function compare(a, b) {
          if (parseFloat(a.preco) > parseFloat(b.preco)) return -1
          if (parseFloat(a.preco) < parseFloat(b.preco)) return 1

          return 0
        })

        console.log('Maior: ', maiorPreco)
        // dá [20, 4, 3, 0]

        setFiltro(maiorPreco)

        break

      case 3:
        //Instruções executadas quando o resultado da expressão for igual á valorN
        console.log('entrou no 42 :', parseInt(valor))

        let promocional = []

        for (let i = 0; i < products.length; i++) {
          if (products[i].preco_promocao != null) {
            promocional.push(products[i])
          }
        }

        setFiltro(promocional)

        break

      default:
        //Instruções executadas quando o valor da expressão é diferente de todos os cases
        break
    }
  }

  const handleSelectTamnho = () => {

    const element = <>
      <div>
        <select
          onChange={e => handleTamanho(e.target.value)}
          className="custom-select"
          required
          id="product-size"
        >
          {/*  <option value={4} key={4} >Tamanho</option> */}
          <option value={0} >
            Tamanho
          </option>
          <option value={0} >
            Todos
          </option>
          {tamanhoJoia.map(item => (
            <option value={item.tamanho} key={item.tamanho}>
              {item.tamanho === 0 ? 'Todos' : `${item.tamanho}cm`}

            </option>
          ))}
        </select>
      </div>
    </>


    if (

      comprimento > 5

    ) {
      return element
    } else {
      return <></>
    }
  }

  useEffect(() => {

    Buscar()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtroList])


  useEffect(() => { }, [])

  return (
    <>
      <div style={{ padding: '10px' }}>
        <h2 style={{ textAlign: 'center' }}>{slug?.toUpperCase()}</h2>
      </div>

      <div className="row" style={{ marginRight: '0px', marginLeft: '0px' }}>
        <div className="col-md-6"></div>

        <div className="col-md-6 offset-md-6">
          <div className="row">


            <div className="col-sm-4">
              <div >
                <select
                  onChange={e => handleTamanho(e.target.value)}
                  className="custom-select"
                  required
                  id="product-size"
                >


                  <option value="" >
                    Ordenar Por:
                  </option>
                  <option value={0} >

                    Todos
                  </option>
                  <option value={1}>
                    Menor preço
                  </option>
                  <option value={2}>
                    Maior preço
                  </option>
                  {/*<option value={3} key={3}>Preço promocional</option>*/}
                </select>
              </div>
            </div>

            <div className="col-sm-4">{handleSelectTamnho()}</div>
          </div>
        </div>
      </div>

      <TitleCardProduct subSite={subSite}>
        <div className="row" style={{ width: '100%' }}>
          {filtroList ? (
            filtro.length ? (
              filtro.map(item => (
                <CardProductHome
                  key={item.id}
                  product={item}
                  subSite={subSite}
                  cliente={dados.tipo_cliente_id}
                />
              ))
            ) : !buscou ? (
              <>
                <div className="col-lg-3 col-md-4 col-sm-6 px-2 mb-4">
                  <Skeleton height={200} />
                </div>

                <div className="col-lg-3 col-md-4 col-sm-6 px-2 mb-4">
                  <Skeleton height={200} />
                </div>

                <div className="col-lg-3 col-md-4 col-sm-6 px-2 mb-4">
                  <Skeleton height={200} />
                </div>

                <div className="col-lg-3 col-md-4 col-sm-6 px-2 mb-4">
                  <Skeleton height={200} />
                </div>
              </>
            ) : (
              <div className="col-12">
                <div className="text-center">
                  <br />
                  <br />
                  <i className="h2 text-body czi-cart"></i>
                  <br />
                  <span>Nenhum produto encontrado.</span>
                </div>
              </div>
            )
          ) : products.length ? (
            products.map(item => (
              <CardProductHome
                key={item.id}
                subSite={subSite}
                product={item}
                cliente={dados.tipo_cliente_id}
              />
            ))
          ) : !buscou ? (
            <>
              <div className="col-lg-3 col-md-4 col-sm-6 px-2 mb-4">
                <Skeleton height={200} />
              </div>

              <div className="col-lg-3 col-md-4 col-sm-6 px-2 mb-4">
                <Skeleton height={200} />
              </div>

              <div className="col-lg-3 col-md-4 col-sm-6 px-2 mb-4">
                <Skeleton height={200} />
              </div>

              <div className="col-lg-3 col-md-4 col-sm-6 px-2 mb-4">
                <Skeleton height={200} />
              </div>
            </>
          ) : (
            <div className="col-12">
              <div className="text-center">
                <br />
                <br />
                <i className="h2 text-body czi-cart"></i>
                <br />
                <span>Nenhum produto encontrado.</span>
              </div>
            </div>
          )}

          {/*(products.length) ?
            products.map((item) =>
                <CardProductHome key={item.id} product={item} />
            )
            :
            !buscou ?
                <>
                    <div className="col-lg-3 col-md-4 col-sm-6 px-2 mb-4">
                        <Skeleton height={200} />
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 px-2 mb-4">
                        <Skeleton height={200} />
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 px-2 mb-4">
                        <Skeleton height={200} />
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 px-2 mb-4">
                        <Skeleton height={200} />
                    </div>
                </>
                :
                <div className="col-12">
                    <div className="text-center">
                        <br /><br /><i className="h2 text-body czi-cart"></i><br />
                        <span>Nenhum produto encontrado.</span>
                    </div>
                </div>
               */}
        </div>
        {products.length && page ? <div className="container pt-md-3 pb-5 mb-md-3">
          <div className="text-center">
            <ButtonLoading
              class="btn btn-primary"
              onClick={() => getPaginate()}
              loading={loading}
              title="Mais produtos"
            />
          </div>
        </div> : ''}
      </TitleCardProduct>
    </>
  )
}

export default inject('ClientStore', 'HeaderStore')(observer(Categoria))
