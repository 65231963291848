import React, { useState } from 'react'
import api from '../../services/api'
import { inject, observer } from 'mobx-react'
import ButtonLoading from '../ButtonLoading'
import { useParams } from 'react-router-dom'
import { useAlert } from 'react-alert'
import subSiteConfig from '../../services/subSite'

const Toolbar = ({ title, ClientStore, HeaderStore }) => {
  const { logout, token } = ClientStore
  const { setSubSite, subSite, setSubSiteParam } = HeaderStore
  const { vendedor } = useParams()

  const [loading, setLoading] = useState(false)

  const handleLogout = async event => {
    event.preventDefault()

    setLoading(true)

    try {
      let response = await api.get('/logout', {
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer ' + token,
        },
      })

      if (response.data.message) {
        let url = subSite != null ? `/site/${subSite.param}` : '/'

        logout()
        setLoading(false)

        console.log('Logout: ', url)
        window.location.replace(url)
      }
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  return (
    <div className="d-none d-lg-flex justify-content-between align-items-center pt-lg-3 pb-4 pb-lg-5 mb-lg-4">
      <h6 className="font-size-base mb-0">{title}</h6>

      <ButtonLoading
        class="btn btn-primary btn-sm"
        title="Sair"
        loading={loading}
        onClick={handleLogout}
      />
    </div>
  )
}

export default inject('ClientStore', 'HeaderStore')(observer(Toolbar))
