import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { formatNumber } from '../services/helpers';
import subSiteConfig from '../services/subSite';
import {
    useParams
} from "react-router-dom";

const CartDropDown = (props) => {

    const { produtos, getTotal, removeProduto } = props.CarrinhoStore;
    const { visible, setVisible, setSubSite, subSite, setSubSiteParam } = props.HeaderStore;

    let { vendedor } = useParams();

    //subsite
    const subSiteLayout = async (v) => {
        return await subSiteConfig.handleSubsite(v)

    }

    const reqSubsite = async (v) => {
        let obj = await subSiteConfig.getUrlParameter(v)
        setSubSite(obj);
    }

    useEffect(() => {

        reqSubsite(vendedor)

    }, [])

    useEffect(() => {
        console.log("subSite: ", subSite)
        if (subSite != null) {
            console.log("subSite: ENTROU")
            setSubSiteParam(subSite)
            subSiteLayout(subSite)
        }
    }, [subSite])
    //subsite

    return (
        <div className="dropdown-menu dropdown-menu-right" style={{ width: "20rem" }}>
            <div className="widget widget-cart px-3 pt-2 pb-3">
                {produtos.map((item, key) =>

                    <div key={key} className="widget-cart-item pb-2 border-bottom">
                        <button onClick={() => removeProduto(key)} className="close text-danger" type="button" aria-label="Remove"><span aria-hidden="true">&times;</span></button>
                        <div className="media align-items-center"><a className="d-block mr-2" href="shop-single-v1.html"><img width="64" src={item.url_fotos} alt="Product" /></a>
                            <div className="media-body">
                                <h6 className="widget-product-title"><a href="shop-single-v1.html">{item.nome}</a></h6>
                                <div className="widget-product-meta"><span className="text-accent mr-2 ">R${`${item.desconto === 'sim' ? formatNumber(item.preco) : formatNumber(item.preco_final)}`}</span><span className="text-muted">x {item.quantidade}</span></div>
                            </div>
                        </div>
                    </div>
                )}
                <div className="d-flex flex-wrap justify-content-between align-items-center py-3">
                    <div className="font-size-sm mr-2 py-2"><span className="text-muted">Subtotal:</span><span className="text-accent font-size-base ml-1">R${formatNumber(getTotal)}</span></div>
                </div><a className="btn btn-primary btn-sm btn-block" href={subSite != null ? `/site/${subSite.param}/carrinho` : '/carrinho'}><i className="czi-card mr-2 font-size-base align-middle"></i>Finalizar</a>
            </div>
        </div>
    );
}

export default inject("CarrinhoStore", "HeaderStore")(observer(CartDropDown));