export const Data = [
    {
        img:"../img/cadastrar-revendedor/comentario/comentarioImgBackground.jpg",
        avatar:"../img/cadastrar-revendedor/comentario/graca.png",
        body:"Há mais de 10 anos trabalho com as Joias Folheadas Ronnelly, e com isso consigo garantir uma boa renda extra, completando o meu salário de professora aposentada. Mesmo com meu tempo curto, consigo conciliar as vendas horas vagas.",
        loc: "GRAÇA BASTOS - Belém/PA"
    },
    
]

/**
 * {
        img:"../img/cadastrar-revendedor/comentario/comentarioImgBackground.jpg",
        avatar:"../img/cadastrar-revendedor/comentario/homi.png",
        body:"A Ronnelly faz parte de mim, sou grato por ser um consultor , desde a sua fundação. Estou sempre atento as novidades e lançamentos.",
        loc: ""
    },
    {
        img:"../img/cadastrar-revendedor/comentario/comentarioImgBackground.jpg",
        avatar:"../img/cadastrar-revendedor/comentario/homi.png",
        body:"A Ronnelly faz parte de mim, sou grato por ser um consultor , desde a sua fundação. Estou sempre atento as novidades e lançamentos, pois meu prazer é deixar meus clientes amados e queridos. Eu amo ser Ronnelly.",
        loc: ""
    },
 */