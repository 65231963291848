import React, { useState, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { Route, Switch, useParams } from 'react-router-dom';
import AuthRoute from './services/AuthRoute';
import AuthSubsiteRoute from './services/AuthSubsiteRoute';
import subSiteConfig from './services/subSite';

import Home from './pages/Home';
import QuemSomos from './pages/QuemSomos';
import Busca from './pages/Busca';
import Cart from './pages/Cart';
import SingleProduct from './pages/SingleProduct';
import Signin from './pages/Signin';
import CheckoutDetail from './pages/CheckoutDetail';
import CheckoutShipping from './pages/CheckoutShipping';
import CheckoutPayment from './pages/CheckoutPayment';
import CheckoutConclusion from './pages/CheckoutConclusion';
import Profile from './pages/Profile';
import Orders from './pages/Orders';
import Page from './pages/Page';
import Categoria from './pages/Categoria';
import Obrigado from './pages/Obrigado';
import NotFound from './pages/NotFound';


import Favorites from './pages/Favorites';
import Addresses from './pages/Addresses';
import OndeEncontrar from './pages/OndeEncontrar';
import Contatos from './pages/Contatos';
import RevendedorHome from './pages/RevendedorHome';
import CheckoutSuccess from './pages/CheckoutSuccess';
import EsqueceuSenha from './pages/EsqueceuSenha'
import Garantias from './pages/Garantias';
import Trocas from './pages/Trocas'

function Routes(props) {

  let { vendedor } = useParams();

  const { subSiteParam } = props.HeaderStore;



  //recebe tema dinamico vindo do store do app.js
  //pegar paramentro da url this.props.match.params.id

  return (
    <Switch>

      {/**subvendedor */}

      {console.log("Params rota: ", { parametro: props.parametro, vendedor, subSiteParam })}
      <Route exact path={`/site/:vendedor`}>
        <Home />
      </Route>

      <Route path={`/site/:vendedor/cadastro-revendedor`}>
        <RevendedorHome />
      </Route>

      <Route path='/site/:vendedor/trocas-devolucoes'>
        <Trocas />
      </Route>

      <Route path={`/site/:vendedor/garantias`}>
        <Garantias />
      </Route>

      <Route path={`/site/:vendedor/quem-somos`}>
        <QuemSomos />
      </Route>

      <Route path={`/site/:vendedor/onde-encontrar`}>
        <OndeEncontrar />
      </Route>

      <Route path={`/site/:vendedor/contatos`} >
        <Contatos />
      </Route>

      <Route path={`/site/:vendedor/obrigado`} >
        <Obrigado />
      </Route>

      <Route exact path={`/site/:vendedor/busca`} >
        <Busca />
      </Route>

      <Route exact path={`/site/:vendedor/entrar`} >
        <Signin />
      </Route>

      <Route path={`/site/:vendedor/carrinho`} >
        <Cart />
      </Route>

      <Route path={`/site/:vendedor/esqueceu-senha`} >
        <EsqueceuSenha />
      </Route>

      <Route path={`/site/:vendedor/produto/:id/:slug`} >
        <SingleProduct />
      </Route>

      <Route path={`/site/:vendedor/detalhe/:slug`} >
        <Page />
      </Route>

      <Route path={`/site/:vendedor/categoria/:slug/:categoria_id/subcategoria/:subcategoria_id`} >
        <Categoria />
      </Route>

      <Route path={`/site/:vendedor/categoria/:slug/:categoria_id`} >
        <Categoria />
      </Route>

      <AuthSubsiteRoute data={subSiteParam} path={`/site/:vendedor/checkout-detalhes`} >
        <CheckoutDetail />
      </AuthSubsiteRoute>

      <AuthSubsiteRoute data={subSiteParam} path={`/site/:vendedor/checkout-endereco`}>
        <CheckoutShipping />
      </AuthSubsiteRoute>

      <AuthSubsiteRoute data={subSiteParam} path={`/site/:vendedor/checkout-pagamento`} >
        <CheckoutPayment />
      </AuthSubsiteRoute>

      <AuthSubsiteRoute data={subSiteParam} path={`/site/:vendedor/checkout-conclusao`} >
        <CheckoutConclusion />
      </AuthSubsiteRoute>

      <AuthSubsiteRoute data={subSiteParam} path={`/site/:vendedor/compra-efetuada-sucesso`} >
        <CheckoutSuccess />
      </AuthSubsiteRoute>

      <AuthSubsiteRoute data={subSiteParam} path={`/site/:vendedor/perfil`} >
        <Profile />
      </AuthSubsiteRoute>

      <AuthSubsiteRoute data={subSiteParam} path={`/site/:vendedor/favoritos`} >
        <Favorites />
      </AuthSubsiteRoute>


      <AuthSubsiteRoute data={subSiteParam} path={`/site/:vendedor/pedidos`} >
        <Orders />
      </AuthSubsiteRoute>

      <AuthSubsiteRoute data={subSiteParam} path={`/site/:vendedor/enderecos`} >
        <Addresses />
      </AuthSubsiteRoute>
      {/**Fim subvendedor */}

      <Route exact path={`/`}>
        <Home />
      </Route>

      <Route path="/cadastro-revendedor">
        <RevendedorHome />
      </Route>

      <Route path='/trocas-devolucoes'>
        <Trocas />
      </Route>

      <Route path='/garantias'>
        <Garantias />
      </Route>

      <Route path="/quem-somos">
        <QuemSomos />
      </Route>

      <Route path="/onde-encontrar">
        <OndeEncontrar />
      </Route>

      <Route path="/contatos">
        <Contatos />
      </Route>

      <Route exact path='/obrigado'>
        <Obrigado />
      </Route>

      <Route exact path='/busca'>
        <Busca />
      </Route>

      <Route exact path='/entrar'>
        <Signin />
      </Route>

      <Route path='/carrinho'>
        <Cart />
      </Route>

      <Route path='/esqueceu-senha'>
        <EsqueceuSenha />
      </Route>

      <Route path='/produto/:id/:slug'>
        <SingleProduct />
      </Route>

      <Route path='/detalhe/:slug'>
        <Page />
      </Route>

      <Route path='/categoria/:slug/:categoria_id/subcategoria/:subcategoria_id'>
        <Categoria />
      </Route>

      <Route path='/categoria/:slug/:categoria_id'>
        <Categoria />
      </Route>

      <AuthRoute path="/checkout-detalhes">
        <CheckoutDetail />
      </AuthRoute>

      <AuthRoute path='/checkout-endereco'>
        <CheckoutShipping />
      </AuthRoute>

      <AuthRoute path='/checkout-pagamento'>
        <CheckoutPayment />
      </AuthRoute>

      <AuthRoute path='/checkout-conclusao'>
        <CheckoutConclusion />
      </AuthRoute>

      <AuthRoute path='/compra-efetuada-sucesso'>
        <CheckoutSuccess />
      </AuthRoute>

      <AuthRoute path="/perfil">
        <Profile />
      </AuthRoute>

      <AuthRoute path="/favoritos">
        <Favorites />
      </AuthRoute>


      <AuthRoute path="/pedidos">
        <Orders />
      </AuthRoute>

      <AuthRoute path="/enderecos">
        <Addresses />
      </AuthRoute>

      <Route path='*' exact={true} component={NotFound} />

    </Switch>

  );
}

export default inject("HeaderStore")(observer(Routes));