import { inject, observer } from 'mobx-react';
import React, {useEffect} from 'react';
import { Link } from 'react-router-dom';
import { formatNumber } from '../services/helpers';
import $ from 'jquery';

const CardProductHome = (props) => {
    const { setFavorito } = props.ProdutoStore;


    const handleSubsite = (v) => {
        $(".bg-primary").css("background", v.cor);
        $(".btn-primary").css({"background-color":  v.cor, "border-color": v.cor});
        $(".text-primary").css("color", v.cor);
    }


    useEffect(() => {
        //console.log("subSite: ", props.subSite)
        if(props.subSite!=null) {
            //console.log("subSite: ENTROU")
            handleSubsite(props.subSite);
        }
        
    },[props.subSite]) 

    return (
        <div className="col-lg-4 col-md-4 col-sm-6 px-2 mb-4 text-center">
            <div className="card product-card">
                <button className="btn-wishlist btn-sm" type="button" data-toggle="tooltip" onClick={() => setFavorito(props.product)} data-placement="left" title="Adicionar aos favoritos">
                    <i className="czi-heart"></i>
                </button>
                <Link className="card-img-top d-block overflow-hidden text-center" to={props.subSite != null?`/site/${props.subSite.param}/produto/${props.product.id}/${props.product.slug}` : "/produto/" + props.product.id + "/" + props.product.slug}>
                    <img src={props.product.url_fotos[0] && props.product.url_fotos[0]} alt="Product" style={{ maxHeight: 250 }} />
                </Link>
                <div className="card-body py-2">
                    {props.cliente === 2 &&
                        <>
                            {props.product.codigo && props.product.codigo != null ?
                                <p
                                    style={{
                                        color:"#717177",
                                        fontSize: "12px"
                                    }}
                                >
                                    Codigo: {props.product.codigo}
                                </p>
                                :
                                <></>
                            }
                        </>
                    }
                    <a className="product-meta d-block font-size-xs pb-1" href={"/produto/" + props.product.id + "/" + props.product.slug}>{props.product.categorias[0] && props.product.categorias[0].nome}</a>
                    <h3 className="product-title font-size-sm"><Link to={props.subSite != null?`/site/${props.subSite.param}/produto/${props.product.id}/${props.product.slug}` : "/produto/" + props.product.id + "/" + props.product.slug}>{props.product.nome}</Link></h3>
                    <div className="d-flex justify-content-between">
                        <div className="product-price text-center" style={{ width: '100%' }}>
                            <span className="text-accent">R$ {props.product.desconto.length ? formatNumber(props.product.preco_final) : formatNumber(props.product.preco)}</span>
                            {(props.product.desconto.length > 0) &&
                                <del className="font-size-sm text-muted"> R$ {formatNumber(props.product.preco)}</del>
                            } 
                            
                        </div>
                    </div>
                </div>
                <div className="card-body card-body-hidden">
                    <Link id="sub-site" to={props.subSite != null?`/site/${props.subSite.param}/produto/${props.product.id}/${props.product.slug}` : "/produto/" + props.product.id + "/" + props.product.slug} className="btn btn-primary btn-sm btn-block mb-2"
                    style={props.subSite != null ? {"color":`${props.subSite.corFonte}`} : {} }
                    
                    ><i className="czi-cart font-size-sm mr-1"></i>Ver mais</Link>
                </div>
            </div>
            <hr className="d-sm-none" />
        </div >
    );
}

export default inject("ProdutoStore")(observer(CardProductHome));