import React, { useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import {
    useParams,
    useLocation
  } from "react-router-dom";
import subSiteConfig from '../services/subSite';
import CardProduct from '../components/CardProduct';
import Skeleton from 'react-loading-skeleton';
import TitleCardProduct from '../components/TitleCardProduct';
import queryString from 'query-string';
import api from '../services/api';
import config from '../config';
import {observer, inject} from 'mobx-react';


const Busca = (props) => {

    const [products, setProducts] = useState([]);
    const [buscou, setBuscou] = useState(false);
    const alert = useAlert();
    const {token} = props.ClientStore;
    const { visible, setVisible, setSubSite, subSite, setSubSiteParam } = props.HeaderStore;

    let { vendedor } = useParams();

    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }

    let query = useQuery();

    const Buscar = async () => {
        try {
            const response = await api.get(`/produtos/search?string=${query.get("texto")}&loja_id=${config.loja_id}`, {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            });
            setBuscou(true);
            if (response.data.data) {
                setProducts(response.data.data.produtos);
            } else {
                alert.show('Ocorreu um erro desconhecido.', { type: 'error' });
            }
        } catch (error) {
            alert.show(error.message, { type: 'error' });
        }
    }

    useEffect(() => {
        Buscar();
    }, []);


    //subsite
        const subSiteLayout = async (v) => {
            return await subSiteConfig.handleSubsite(v)
    
        }

        const reqSubsite = async (v) => {
            let obj = await subSiteConfig.getUrlParameter(v)
            setSubSite(obj);
        }

        useEffect(() => {
                
            reqSubsite(vendedor)

        },[]) 

        useEffect(() => {
                

            console.log("subSite: ", subSite)
            if(subSite!=null) {
                console.log("subSite: ENTROU")
                setSubSiteParam(subSite)
                subSiteLayout(subSite)
            }

        },[subSite]) 
    //subsite

    return (
        <>
            <section className="container pt-md-3 pb-5 mb-md-3 pt-5">
                <h2 className="h3 text-center">Resultado da busca</h2>
                <div className="d-block text-center">
                    <p>Encontramos {products.length} produto(s) para a sua busca por {query.get("texto")}.</p>
                </div>
                <div className="row pt-4 mx-n2">
                    {(products.length) ?
                        products.map((item) =>
                            <CardProduct key={item.id} product={item} />
                        )
                        :
                        !buscou ?
                            <>
                                <div className="col-lg-3 col-md-4 col-sm-6 px-2 mb-4">
                                    <Skeleton height={200} />
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6 px-2 mb-4">
                                    <Skeleton height={200} />
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6 px-2 mb-4">
                                    <Skeleton height={200} />
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6 px-2 mb-4">
                                    <Skeleton height={200} />
                                </div>
                            </>
                            :
                            <div className="col-12">
                                <div className="text-center">
                                    <br /><br /><i className="h2 text-body czi-cart"></i><br />
                                    <span>Nenhum produto encontrado.</span>
                                </div>
                            </div>
                    }
                </div>
            </section>

        </>
    );
}

export default inject("ClientStore", "HeaderStore")(observer(Busca));