import styled from 'styled-components';



export const FormBox = styled.div`
    padding: 15px;
`;

export const Cartao = styled.div`

    @media only screen and (max-width: 576px)  { 
        .jp-card-front {
    
            width: 80% !important;

        }
        .jp-card-back {
    
            width: 80% !important;

        }
    }
`;

export const CardBox = styled.div`
    display:flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 572.5px;


`;

