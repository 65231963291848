import React, { useState } from 'react';
import { Data } from './Data';

import {
    Area,
    AreaStep,
    AreaSeta,
    AreImg,
    Marcador,
    SetaNo,
    SetaCurva,
    Obs

} from './styles';

const  Steps = (props) => {
    return(
            <>
                <div className="container">
                    <div className="row" style={{padding: "2rem"}}>
                        <div className="col-md-3 col-sm-6" style={{padding:"0px"}}>
                            <Area>
                                <AreaStep>
                                    <h2>{Data[0].title}</h2>
                                    <p>
                                        Faça o seu <Marcador>pré cadastro online</Marcador> neste site para que a nossa equipe possa avalia-lo, se aprovado entraremos em contato
                                    </p>
                                </AreaStep>
                            </Area>
                        </div>  
                        <div className="col-md-1" style={{padding:"0px"}}>
                            <Area>
                                <AreaSeta>
                                    <AreImg className="col-12 p-2 text-center" >
                                        <SetaNo />
                                    </AreImg>
                                </AreaSeta>
                            </Area>
                        </div> 
                        <div className="col-md-3 col-sm-6" style={{padding:"0px"}}>
                            <Area>
                                <AreaStep>
                                    <h2>{Data[1].title}</h2>
                                    <p>
                                        Após aprovado você deve fazer uma compra mínima no valor de <Marcador> 700,00 R$*</Marcador>, levando R$ 1,400  em semijoias
                                    </p>
                                    <Obs>
                                        *as próximas compras poderão ser feitas em outros valores.
                                    </Obs>
                                </AreaStep>
                            </Area>
                        </div>
                        <div className="col-md-2" style={{padding:"0px"}}>
                            <Area>
                                <AreaSeta>
                                    <AreImg className="col-12 p-2 text-center" >
                                        <SetaCurva />
                                    </AreImg>
                                </AreaSeta>
                            </Area>
                        </div> 
                        <div className="col-md-3 col-sm-6" style={{padding:"0px"}}>
                            <Area>
                                <AreaStep style={{border: "5px solid #CDAF66"}}>
                                    <h2>{Data[2].title}</h2>
                                    <p>
                                        Pronto!!! a partir desse momento você já faz parte da <Marcador>Equipe Ronnelly</Marcador>
                                    </p>
                                </AreaStep>
                            </Area>
                        </div>
                    </div> 
                </div>
                
            </>
                 
            
    );
}

export default Steps;