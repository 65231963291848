import React, { useState, useEffect } from 'react'
import { inject, observer } from 'mobx-react'
import api from '../../services/api'
import { closeModal } from '../../services/helpers'
import ButtonLoading from '../ButtonLoading'
import InputMask from 'react-input-mask'
import { useParams } from 'react-router-dom'
import { useAlert } from 'react-alert'
import subSiteConfig from '../../services/subSite'

import Loading from '../Loading'

const ModalAddress = props => {
  const { token } = props.ClientStore
  const { setSubSite, subSite, setSubSiteParam } = props.HeaderStore
  const { vendedor } = useParams()

  const [cep, setCep] = useState('')
  const [rua, setRua] = useState('')
  const [numero, setNumero] = useState('')
  const [complemento, setComplemento] = useState('')
  const [bairro, setBairro] = useState('')
  const [cidade, setCidade] = useState('')
  const [estado, setEstado] = useState('')
  const [loading, setLoading] = useState(false)
  const alert = useAlert()

  const addAddress = async event => {
    event.preventDefault()

    try {
      setLoading(true)

      let response = await api.post(
        '/endereco',
        {
          rua,
          cep,
          numero,
          complemento,
          bairro,
          cidade,
          estado,
        },
        {
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + token,
          },
        }
      )
      
      if (!response.data.errors) {
        setCep('')
        setRua('')
        setNumero('')
        setComplemento('')
        setBairro('')
        setCidade('')
        setEstado('')
        setLoading(false)
        props.getMyAddresses()
        closeModal('#add-address')
      }
    } catch (error) {
      setLoading(false)

      console.log(error)
    }
  }

  async function getCep() {
    try {
      const response = await api.get(
        'https://ceps.sitebeta.com.br/api/endereco?cep=' + cep,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )

      if (!response.data.error) {
        setRua(response.data.data.logradouro)
        setBairro(response.data.data.bairro)
        setCidade(response.data.data.cidade)
        setEstado(response.data.data.uf)
      } else {
        alert.show(response.data.message, { type: 'info' })
      }
    } catch (error) {}
  }

  //subsite
  const subSiteLayout = async v => {
    return await subSiteConfig.handleSubsite(v)
  }

  const reqSubsite = async v => {
    let obj = await subSiteConfig.getUrlParameter(v)

    setSubSite(obj)
  }

  useEffect(() => {
    reqSubsite(vendedor)
  }, [])

  useEffect(() => {
    console.log('subSite: ', subSite)

    if (subSite != null) {
      console.log('subSite: ENTROU')
      setSubSiteParam(subSite)
      subSiteLayout(subSite)
    }
  }, [subSite])
  //subsite

  return (
    <form
      className="modal fade"
      method="post"
      id="add-address"
      tabIndex="-1"
      noValidate
      onSubmit={addAddress}
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Adicionar endereço</h5>

            <button
              className="close"
              id="action-close"
              type="button"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div className="modal-body">
            <div className="row">
              <div className="col-sm-12">
                <div className="form-group">
                  <label htmlFor="address-zip">CEP *</label>

                  <InputMask
                    className="form-control"
                    mask="99999-999"
                    maskChar={null}
                    value={cep}
                    onChange={e => setCep(e.target.value)}
                    placeholder="CEP"
                    onBlur={() => getCep()}
                  />

                  <div className="invalid-feedback">Preencha este campo.</div>
                </div>
              </div>

              <div className="col-sm-8">
                <div className="form-group">
                  <label htmlFor="address-fn">Endereço *</label>

                  <input
                    className="form-control"
                    type="text"
                    id="address-fn"
                    required
                    value={rua}
                    placeholder="Endereço"
                    onChange={event => setRua(event.target.value)}
                  />

                  <div className="invalid-feedback">Preencha este campo.</div>
                </div>
              </div>

              <div className="col-sm-4">
                <div className="form-group">
                  <label htmlFor="number">Número *</label>

                  <input
                    className="form-control"
                    type="text"
                    placeholder="Número"
                    id="number"
                    required
                    value={numero}
                    onChange={event => setNumero(event.target.value)}
                  />

                  <div className="invalid-feedback">Preencha este campo.</div>
                </div>
              </div>

              <div className="col-sm-12">
                <div className="form-group">
                  <label htmlFor="address-ln">Complemento</label>

                  <input
                    className="form-control"
                    type="text"
                    placeholder="Complemento"
                    id="address-ln"
                    value={complemento}
                    onChange={event => setComplemento(event.target.value)}
                  />
                </div>
              </div>

              <div className="col-sm-4">
                <div className="form-group">
                  <label htmlFor="address-company">Bairro *</label>

                  <input
                    className="form-control"
                    type="text"
                    placeholder="Bairro"
                    id="address-company"
                    required
                    value={bairro}
                    onChange={event => setBairro(event.target.value)}
                  />

                  <div className="invalid-feedback">Preencha este campo.</div>
                </div>
              </div>

              <div className="col-sm-4">
                <div className="form-group">
                  <label htmlFor="city">Cidade *</label>

                  <input
                    className="form-control"
                    type="text"
                    id="city"
                    placeholder="Cidade"
                    required
                    value={cidade}
                    onChange={event => setCidade(event.target.value)}
                  />

                  <div className="invalid-feedback">Preencha este campo.</div>
                </div>
              </div>

              <div className="col-sm-4">
                <div className="form-group">
                  <label htmlFor="state">Estado *</label>

                  <input
                    className="form-control"
                    type="text"
                    id="state"
                    placeholder="Estado"
                    required
                    value={estado}
                    onChange={event => setEstado(event.target.value)}
                  />

                  <div className="invalid-feedback">Preencha este campo.</div>
                </div>
              </div>

              <div className="col-12 form-group">
                <small>(*) Campos obrigatórios</small>
              </div>
            </div>
          </div>

          <div className="modal-footer">
            <button
              className="btn btn-secondary"
              type="button"
              data-dismiss="modal"
            >
              Fechar
            </button>

            <ButtonLoading
              class="btn btn-primary btn-shadow"
              type="submit"
              loading={loading}
              title="Adicionar Endereço"
            />
          </div>
        </div>
      </div>
    </form>
  )
}

export default inject('ClientStore', 'HeaderStore')(observer(ModalAddress))
