import styled from 'styled-components';



export const AreaBemVindo = styled.div`

    display:flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    background-image: url("../img/cadastrar-revendedor/golden-banner.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;

    /**
        Mobile only    
     */
    @media only screen and (max-width: 576px)  {
        background-size: auto;
        background-position-x: center;
        text-shadow: 1px 1px #717177;
    }
  
`;

export const Wrapper = styled.div`

    width:50%;
    text-shadow: 1px 1px #717177;

    /**
        Mobile only    
     */
    @media only screen and (max-width: 576px)  {

        width:90%;
    }



`;

export const Title = styled.h2`

    color: #FFFFFF;   
    text-align: center !important;

    @media only screen and (max-width: 768px)  {

        font-size: 20px;    
    }

    
`;

export const TextContent = styled.h5`

    color: #FFFFFF;   
    text-align: center !important;  
    margin-bottom: 50px;

    @media only screen and (max-width: 768px)  {

        font-size: 15px; 
        margin-bottom: 5px;   
    }

    

    @media only screen and (max-width: 280px)  {

        margin-bottom: 5px;   
    }

    
`;

export const ContainerContent = styled.div`

    display: flex;
    justify-content: center;

`;




export const Area = styled.div`

    padding: 10px;
    
    @media only screen and (max-width: 768px)  {
        
    }

    
  
`;
export const AreaBtn = styled(Area)`
    display: flex;
    justify-content: center;

    @media only screen and (max-width: 1024px)  {

    }
  
`;


export const CadastrarBtn = styled.button`
    background-color: #002D6D ;  
    color: #FFFFFF;
    font-size: 30px;
    border: none; 
    text-align: center !important;  
    border-radius: 7px;
    width: 60%;
    height: 80px;
    padding: 15px;

    /**
    Mobile only    
    */
    @media only screen and (max-width: 576px)  {
        height: 48px;
        font-size: 15px;
        padding: 5px;
    }
    

`;

export const AreImg = styled(Area)` 
    padding: 10px;

    /**
    Mobile only    
    */
    @media only screen and (max-width: 572px)  { 
        margin-bottom: 5px;
        margin-top: 5px;
    }
`;


