import React, { useEffect, useState } from 'react'
import PageTitle from '../components/PageTitle'
import Step from '../components/Checkout/Step'
import AsideCheckout from '../components/Checkout/AsideCheckout'
import NavigationDesktop from '../components/Checkout/NavigationDesktop'
import NavigationMobile from '../components/Checkout/NavigationMobile'
import loadjs from 'loadjs'
import { useAlert } from 'react-alert'
import { useHistory, useParams } from 'react-router-dom'
import TypeCard from '../components/Checkout/TypeCard'
import { inject, observer } from 'mobx-react'
import api from '../services/api'
import subSiteConfig from '../services/subSite'
import _ from 'lodash'
import { getCardFlag } from '../services/helpers'

const CheckoutPayment = props => {
  let history = useHistory()
  const alert = useAlert()
  const [loading, setLoading] = useState(false)
  const [cards, setCards] = useState([])
  const {
    codigo_frete,
    setTypePagamento,
    type_pagamento,
    setCardTokenId,
    setObjectToken,
    cardTokenId,
    setCartao,
    setValidade,
    setCvv,
    cvv,
    cartao,
    validade,
    bandeira,
    setBandeira,
    nome,
    setNome,
    parcelas,
    setQtdParcelas,
    qtdParcelas,
    tipo_pagamento,
    setTipoPagamento,
  } = props.PedidoStore

  const { token } = props.ClientStore
  const { setSubSite, subSite } = props.HeaderStore
  let { vendedor } = useParams()

  const changeCheck = e => {
    let index = _.findIndex(cards, o => o.id == e.target.htmlFor)

    setCardTokenId(e.target.htmlFor)
    setObjectToken(cards[index])
  }

  async function getCards() {
    setLoading(true)

    try {
      let response = await api.get('/card', {
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer ' + token,
        },
      })

      if (response.data.data) {
        setCards(response.data.data)
      }

      setLoading(false)
    } catch (error) {
      setLoading(false)

      alert.show('Verifique a sua conexão!', { type: 'error' })
    }
  }

  useEffect(() => {
    loadjs('/js/vendor.min.js', function () {
      loadjs('/js/theme.min.js', function () {
        loadjs('js/card.min.js')
      })
    })

    if (subSite != null) {
      if (!codigo_frete) {
        alert.show('Selecione um metodo de entrega!', { type: 'info' })
        history.push(`/site/${subSite.param}/checkout-endereco`)
      }
    } else {
      if (!codigo_frete) {
        alert.show('Selecione um metodo de entrega!', { type: 'info' })
        history.push('/checkout-endereco')
      }
    }

    window.scrollTo(0, 0)
    //getCards();
  }, [])

  //subsite
  const subSiteLayout = async v => await subSiteConfig.handleSubsite(v)

  const reqSubsite = async v => {
    let obj = await subSiteConfig.getUrlParameter(v)
    setSubSite(obj)
  }

  useEffect(() => {
    reqSubsite(vendedor)
  }, [])

  useEffect(() => {

    if (subSite != null) {
      subSiteLayout(subSite)
    }
  }, [subSite])
  //subsite

  return (
    <>
      <PageTitle
        nameRoute="Pagamento"
        url={'/checkout-pagamento'}
      />

      <div className="container pb-5 mb-2 mb-md-4">
        <div className="row">
          <section className="col-lg-8">
            {/* <!-- Steps--> */}
            <Step numberCurrent="4" />

            {/* <!-- Payment methods accordion--> */}
            <h2 className="h6 pb-3 mb-2">Escolha o método de pagamento</h2>

            <div className="table-responsive">
              <table className="table table-hover font-size-sm border-bottom">
                <thead>
                  <tr>
                    <th className="align-middle"></th>
                    <th className="align-middle"></th>
                    <th className="align-middle">Método de pagamento</th>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <td>
                      <div className="custom-control custom-radio mb-4">
                        <input
                          value={4}
                          checked={tipo_pagamento == 4 ? true : false}
                          onChange={e => setTipoPagamento(4)}
                          className="custom-control-input"
                          type="radio"
                          name={'shipping-method'}
                        />

                        <label
                          onClick={e => setTipoPagamento(e.target.htmlFor)}
                          className="custom-control-label"
                          htmlFor={4}
                        ></label>
                      </div>
                    </td>

                    <td className="align-middle">
                      <img
                        style={{ maxWidth: 150 }}
                        src="/img/Logo_of_Cielo.svg.png"
                        alt="Cielo logo"
                      />
                    </td>

                    <td className="align-middle">
                      <span className="text-dark font-weight-medium">
                        CARTÃO DE CREDITO
                      </span>
                      <br />
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <div className="custom-control custom-radio mb-4">
                        <input
                          value={1}
                          checked={tipo_pagamento == 1 ? true : false}
                          onChange={e => setTipoPagamento(1)}
                          className="custom-control-input"
                          type="radio"
                          name={'shipping-method'}
                        />

                        <label
                          onClick={e => setTipoPagamento(e.target.htmlFor)}
                          className="custom-control-label"
                          htmlFor={1}
                        ></label>
                      </div>
                    </td>

                    <td className="align-middle">
                      <img style={{ maxWidth: 50 }} src="/img/pix.png" />
                    </td>

                    <td className="align-middle">
                      <span className="text-dark font-weight-medium">PIX</span>
                      <br />
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <div className="custom-control custom-radio mb-4">
                        <input
                          value={3}
                          checked={tipo_pagamento == 3 ? true : false}
                          onChange={e => setTipoPagamento(3)}
                          className="custom-control-input"
                          type="radio"
                          name={'shipping-method'}
                        />

                        <label
                          onClick={e => setTipoPagamento(e.target.htmlFor)}
                          className="custom-control-label"
                          htmlFor={3}
                        ></label>
                      </div>
                    </td>

                    <td className="align-middle">
                      <img style={{ maxWidth: 80 }} src="/img/santander.png" />
                    </td>

                    <td className="align-middle">
                      <span className="text-dark font-weight-medium">
                        TRANSFERENCIA
                      </span>
                      <br />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            {/* <div className="accordion mb-2" id="payment-method" role="tablist">
              <div className="card">
                <div className="card-header" role="tab">
                  <h3 className="accordion-heading"><a href="#card" onClick={() => setTypePagamento(1)} data-toggle="collapse" ><i className="czi-card font-size-lg mr-2 mt-n1 align-middle"></i>Pagar com cartão de crédito.<span className="accordion-indicator"><i data-feather="chevron-up"></i></span></a></h3>
                </div>

                <div className={"collapse show"} id="card" data-parent="#payment-method" role="tabpanel">
                  <div className="card-body">
                    <form className="interactive-credit-card row">
                      <div className="col-sm-6">
                        <div className="card-wrapper"></div>
                      </div>

                      <div className="col-sm-6 row mt-4">
                        <div className="col-sm-12">
                          <input className="form-control" onClick={() => setTypePagamento(1)} value={nome} onChange={(e) => setNome(e.target.value)} type="text" name="name" placeholder="Nome Completo" required />
                        </div>

                        <div className="col-sm-12">
                          <input className="form-control" value={cartao} onChange={(e) => { setCartao(e.target.value); setBandeira(getCardFlag(e.target.value)) }} type="text" name="number" placeholder="Número do cartão" required />
                        </div>

                        <div className="col-sm-6">
                          <input className="form-control" value={validade} onChange={(e) => setValidade(e.target.value)} type="text" name="expiry" placeholder="MM/AAAA" required />
                        </div>

                        <div className="col-sm-6">
                          <input className="form-control" value={cvv} onChange={(e) => setCvv(e.target.value)} type="text" name="cvc" placeholder="CVC" required />
                        </div>

                        <div className="col-sm-12">
                          <select value={qtdParcelas} className="form-control" onChange={(e) => setQtdParcelas(e.target.value)} >
                            {parcelas.map((item) =>
                              <option key={item.value} value={item.value}>{item.label}</option>
                            )}
                          </select>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>

              {(cards.length > 0) &&
                <div className="card">
                  <div className="card-header" role="tab">
                    <h3 className="accordion-heading"><a onClick={() => setTypePagamento(2)} href="#paypal" data-toggle="collapse"><i className="czi-wallet mr-2 align-middle"></i>Pagar com cartão salvo<span className="accordion-indicator"><i data-feather="chevron-up"></i></span></a></h3>
                  </div>

                  <div className={"collapse"} id="paypal" data-parent="#payment-method" role="tabpanel">
                    <div className="card-body font-size-sm">
                      <div className="table-responsive">
                        <table className="table table-hover font-size-sm border-bottom">
                          <thead>
                            <tr>
                              <th className="align-middle"></th>
                              <th className="align-middle">Bandeira</th>
                              <th className="align-middle">Cartão</th>
                            </tr>
                          </thead>
                          <tbody>
                            {cards.map((item) =>
                              <TypeCard
                                key={item.id}
                                checked={cardTokenId == item.id ? true : false}
                                change={(e) => changeCheck(e)}
                                value={item.id}
                                card={item.cartao}
                                validade={item.validade}
                                bandeira={item.bandeira}
                              />
                            )}
                          </tbody>
                        </table>
                      </div>

                      <input className="form-control" value={cvv} onChange={(e) => setCvv(e.target.value)} type="number" name="cvc" placeholder="Código de segurança" required /><br/>

                      <select value={qtdParcelas} className="form-control" onChange={(e) => setQtdParcelas(e.target.value)} >
                        {parcelas.map((item) =>
                          <option key={item.value} value={item.value}>{item.label}</option>
                        )}
                      </select>
                    </div>
                  </div>
                </div>
              }
            </div> */}

            {/* <!-- Navigation (desktop)--> */}
            <NavigationDesktop
              linkBack={
                subSite != null
                  ? `/site/${subSite.param}/checkout-endereco`
                  : '/checkout-endereco'
              }
              linkNext={
                subSite != null
                  ? `/site/${subSite.param}/checkout-conclusao`
                  : '/checkout-conclusao'
              }
              textBack="Retornar à forma de entrega"
              textNext="Concluir"
            />
          </section>

          {/* <!-- Sidebar--> */}
          <AsideCheckout
            descount="0.0"
            shipping="10.0"
            subtotal="60.0"
            total="70.0"
          />
        </div>

        {/* <!-- Navigation (mobile)--> */}
        <NavigationMobile
          linkBack={
            subSite != null
              ? `/site/${subSite.param}/checkout-endereco`
              : '/checkout-endereco'
          }
          linkNext={
            subSite != null
              ? `/site/${subSite.param}/checkout-conclusao`
              : '/checkout-conclusao'
          }
          textBack="Retornar à forma de entrega"
          textNext="Concluir"
        />
      </div>
    </>
  )
}

export default inject(
  'PedidoStore',
  'CarrinhoStore',
  'ClientStore',
  'HeaderStore'
)(observer(CheckoutPayment))

