import React from 'react'

export const NavLinkDropdown = ({ subCategorie, categoriaName, categorieId, dataLink }) => {
    return (
        <div className="dropdown-menu dropdown-menu-left" style={{ width: "5rem", padding: '1rem' }}>
            {subCategorie?.map((item, key) => {
                return (
                    <a key={item.id} className="dropdown-item" href={dataLink != null
                        ? `/site/${dataLink.param}/categoria/${categoriaName}/${categorieId}/subcategoria/${item.id}`
                        : `/categoria/${categoriaName.toLowerCase()}/${categorieId}/subcategoria/${item.id}`}>{item.nome}</a>
                )
            })}
        </div>
    )
}
