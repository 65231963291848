import { inject, observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import { useAlert } from 'react-alert'
import { useParams, Link } from 'react-router-dom'
import subSiteConfig from '../services/subSite'
import { formatNumber } from '../services/helpers'

const CardProduct = props => {
  const { setFavorito } = props.ProdutoStore
  const { visible, setVisible, setSubSite, subSite, setSubSiteParam } =
    props.HeaderStore

  let { vendedor } = useParams()

  //subsite
  const subSiteLayout = async v => {
    return await subSiteConfig.handleSubsite(v)
  }

  const reqSubsite = async v => {
    let obj = await subSiteConfig.getUrlParameter(v)

    setSubSite(obj)
  }

  useEffect(() => {
    reqSubsite(vendedor)
  }, [])

  useEffect(() => {
    console.log('subSite: ', subSite)

    if (subSite != null) {
      console.log('subSite: ENTROU')
      setSubSiteParam(subSite)
      subSiteLayout(subSite)
    }
  }, [subSite])
  //subsite

  return (
    <div className="col-lg-3 col-md-4 col-sm-6 px-2 mb-4 text-center">
      <div className="card product-card">
        <button
          className="btn-wishlist btn-sm"
          type="button"
          data-toggle="tooltip"
          onClick={() => setFavorito(props.product)}
          data-placement="left"
          title="Adicionar aos favoritos"
        >
          <i className="czi-heart"></i>
        </button>

        <Link
          className="card-img-top d-block overflow-hidden text-center"
          to={
            subSite != null
              ? `/site/${subSite.param}/produto/${props.product.id}/${props.product.slug}`
              : '/produto/' + props.product.id + '/' + props.product.slug
          }
        >
          <img
            src={props.product.url_fotos[0] && props.product.url_fotos[0]}
            alt="Product"
            style={{ maxHeight: 150 }}
          />
        </Link>

        <div className="card-body py-2">
          <a
            className="product-meta d-block font-size-xs pb-1"
            href={
              subSite != null
                ? `/site/${subSite.param}/produto/${props.product.id}/${props.product.slug}`
                : '/produto/' + props.product.id + '/' + props.product.slug
            }
          >
            {props.product.categorias[0] && props.product.categorias[0].nome}
          </a>

          <h3 className="product-title font-size-sm">
            <Link
              to={
                subSite != null
                  ? `/site/${subSite.param}/produto/${props.product.id}/${props.product.slug}`
                  : '/produto/' + props.product.id + '/' + props.product.slug
              }
            >
              {props.product.nome}
            </Link>
          </h3>

          <div className="d-flex justify-content-between">
            <div
              className="product-price text-center"
              style={{ width: '100%' }}
            >
              <span className="text-accent">
                R${' '}
                {props.product.desconto.length
                  ? formatNumber(props.product.preco_final)
                  : formatNumber(props.product.preco)}
              </span>

              {props.product.desconto.length > 0 && (
                <del className="font-size-sm text-muted">
                  {' '}
                  R$ {formatNumber(props.product.preco)}
                </del>
              )}
            </div>
          </div>
        </div>

        <div className="card-body card-body-hidden">
          <Link
            to={
              subSite != null
                ? `/site/${subSite.param}/produto/${props.product.id}/${props.product.slug}`
                : '/produto/' + props.product.id + '/' + props.product.slug
            }
            className="btn btn-primary btn-sm btn-block mb-2"
          >
            <i className="czi-cart font-size-sm mr-1"></i>Ver mais
          </Link>
        </div>
      </div>

      <hr className="d-sm-none" />
    </div>
  )
}

export default inject('ProdutoStore', 'HeaderStore')(observer(CardProduct))

