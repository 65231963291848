import styled from 'styled-components';

export const Area = styled.div`
    

    width: 100%
  
`;


