import styled from 'styled-components';



export const AreaBemVindo = styled.div`
    display:flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 450px;
    background-image: url("../img/cadastrar-revendedor/mainBanner.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    

    @media only screen and (max-width: 567px)  {
        height: 280px;
        background-size: cover;
        background-position-x: right;
        text-shadow: 1px 1px #717177;

        h3 {
            font-size: 20px;
        }   

        h5 {
            font-size: 15px;
            margin-bottom: 30px !important;
        }
    }
  
`;

export const Title = styled.h3`
    color: #FFFFFF;   
    text-align: center !important;

    @media only screen and (max-width: 1024px)  {
        
    }

    
`;

export const TextContent = styled.h5`
    color: #FFFFFF;   
    text-align: center !important;  

    @media only screen and (max-width: 1024px)  {
      
    } 
    
`;


export const AreaCadastrarBtn = styled.div`
    display:flex;
    justify-content: center;

    @media (max-width: 770px) { 
        
    }

`;

export const CadastrarBtn = styled.button`
    background-color: #002D6D ;  
    color: #FFFFFF;
    font-size: 30px;
    border: none; 
    text-align: center !important;  
    border-radius: 7px;
    width: 60%;
    height: 80px;

    @media (max-width: 768px) { 
        z-index: 99;
        width: 70%;
        font-size: 15px;
        height: 48px
    }
    

`;

export const Area = styled.div`

    
    text-shadow: 1px 1px #717177;

    @media only screen and (max-width: 768px)  {
        padding:1rem;
    }
  
`;

export const AreaBtn = styled(Area)``;


