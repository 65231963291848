import styled from 'styled-components';




export const Area =  styled.div`  
    display:flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    @media only screen and (max-width: 760px)  { 
        margin-top: 20px;

    }
    
`;

export const AreaSeta = styled.div`
    display:flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 372px;

    @media only screen and (max-width: 760px)  { 
        width: 60%;
        height: 223px;
        padding: 10px;

    }
`;

export const AreaStep = styled(AreaSeta)`
    padding: 2rem;
    border-radius: 15px;
    flex-direction: column;
    padding: 0.2rem;

    h2 {
        color: #002D6D; 

        
    }
    

    p {
        padding: 5px;
        text-align: center;
        color: #717177;
    }

    @media only screen and (max-width: 567px)  { 
        p {
            font-size: 12px;
            padding: 0.2rem;
        }
        

    }


`;

export const AreaBanner = styled(Area)`
    padding: 0;
    background-image: url("../img/cadastrar-revendedor/joias.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;

`;


export const AreImg = styled(Area)`
    
`;

export const SetaNo = styled.div`

    height: 60px;
    width: 100px;
    background-image: url("../img/cadastrar-revendedor/rotated-right-arrow-with-broken-line.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;

    @media only screen and (max-width: 760px)  { 
        background-image: url("../img/cadastrar-revendedor/seta-curva-mobile.jpg");
        background-repeat: no-repeat;
        background-size: 100% 100%;
    }   
`;

export const SetaCurva = styled.div`

    height: 120px;
    width: 120px;
    background-image: url("../img/cadastrar-revendedor/curved-arrow-with-broken-line.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;

    @media only screen and (max-width: 760px)  { 
        background-image: url("../img/cadastrar-revendedor/seta-line-mobile.jpg");
        background-repeat: no-repeat;
        background-size: 100% 100%;
    }   
`;

export const Marcador = styled.span`
    font-weight: bold;
    color: #002D6D;
    

    @media only screen and (max-width: 567px)  { 
        font-size: 10px;

    }

`;

export const Obs = styled(Marcador)`
    font-weight: normal;
    font-size: 15px;
    color: #002D6D;
    text-align: left;
   

`;


