import { inject, observer } from "mobx-react";
import React, { useEffect, useState, useRef } from "react";
import { Navbar, Container, Nav, NavDropdown } from "react-bootstrap";
import { formatNumber } from "../services/helpers";
import { Route, Redirect, Link } from "react-router-dom";
import $ from "jquery";
import CartDropDown from "./CartDropDown";
import { NavLinkDropdown } from "./NavLinkDropdown";

const Header = (props) => {
  const { dados } = props.ClientStore;
  const { produtos, getTotal } = props.CarrinhoStore;
  const { menus, visible, subSite } = props.HeaderStore;
  const [evento, setEvento] = useState(false);
  const menuColapse = useRef(null);
  const navbarRef = useRef(null);
  const outroRef = useRef(null);

  const [firstName, setFirstName] = useState("");

  const handlePerfil = (v) => {
    //console.log("handlePerfil: ", props.data);
    if (v != null) {
      return `/site/${props.data.param}/perfil`;
    } else {
      return `/perfil`;
    }
  };

  const handleEntrar = (v) => {
    //console.log("handlePerfil: ", props.data);
    if (v != null) {
      return `/site/${props.data.param}/entrar`;
    } else {
      return `/entrar`;
    }
  };

  function handleToggleMenu(v) {
    if (v == 0) {
      navbarRef.current.classList.toggle("d-none");
    } else {
      setEvento(!evento);
    }
  }

  useEffect(() => {
    // console.log("dadosHeaderSubsite: ", props.data);
    if (dados.nome) {
      let n = dados.nome;
      //console.log("Nome: ", dados.nome);
      let nome = n.split(" ");
      //console.log("Primeiro nome: ", nome[0]);
      setFirstName(nome[0]);
    } else {
    }
  }, []);

  useEffect(() => { }, [firstName]);
  useEffect(() => {
    //console.log({ evento });
  }, [evento]);

  return (
    <>
      {visible.on ? (
        <header className="box-shadow-sm">
          <div className="topbar topbar-dark bg-primary d-none d-md-block">
            <div className="container">
              <div className="topbar-text dropdown d-md-none">
                <a
                  className="topbar-link dropdown-toggle"
                  href="# "
                  data-toggle="dropdown"
                >
                  Links úteis
                </a>
                <ul className="dropdown-menu">
                  <li>
                    <a className="dropdown-item" href="tel:9130857656">
                      <i className="czi-support text-muted mr-2"></i>(91)
                      3085-7656
                    </a>
                  </li>
                </ul>
              </div>
              <div className="topbar-text text-nowrap d-none d-md-inline-block">
                <i
                  className={
                    subSite != null ? "czi-support" : "czi-support text-light"
                  }
                  style={subSite != null ? { color: subSite.corFonte } : {}}
                ></i>
                <span
                  className={subSite != null ? "mr-1" : "text-muted mr-1"}
                  style={subSite != null ? { color: subSite.corFonte } : {}}
                >
                  Suporte
                </span>
                <a className="topbar-link" href="tel:9130857656">
                  (91) 3085-7656
                </a>
              </div>
            </div>
          </div>

          <div className="navbar-sticky bg-dark">
            <div className="navbar navbar-expand-lg navbar-light bg-light">
              <div className="container">
                <form
                  id="buscar2"
                  action={
                    subSite != null
                      ? `/site/${props.data.param}/busca`
                      : "/busca"
                  }
                  className="input-group-overlay d-none d-lg-flex mx-4 col-sm-3"
                >
                  <input
                    name="texto"
                    className="form-control appended-form-control"
                    type="text"
                    placeholder="Buscar produtos"
                  />
                  <div
                    style={{ cursor: "pointer", marginRight: 10 }}
                    onClick={() => document.getElementById("buscar2").submit()}
                    className="input-group-append-overlay"
                  >
                    <span className="input-group-text">
                      <i className="czi-search"></i>
                    </span>
                  </div>
                </form>
                <div className="d-flex justify-content-center col-sm-6">
                  <a
                    className="navbar-brand d-none d-sm-block mr-3 flex-shrink-0 "
                    href={subSite != null ? `/site/${props.data.param}` : "/"}
                    style={{ minWidth: "7rem" }}
                  >
                    <img src="/img/logo-light.png" />
                  </a>
                </div>

                <div className="d-none d-sm-block">
                  <div className="navbar-toolbar d-flex flex-shrink-0 align-items-center">
                    <button
                      className="navbar-toggler"
                      type="button"
                      data-toggle="collapse"
                      data-target="#navbarCollapse"
                    >
                      <span className="navbar-toggler-icon"></span>
                    </button>
                    <a className="navbar-tool navbar-stuck-toggler" href="#">
                      <span className="navbar-tool-tooltip">Expandir menu</span>
                      <div className="navbar-tool-icon-box">
                        <i className="navbar-tool-icon czi-menu"></i>
                      </div>
                    </a>
                    <a
                      className="navbar-tool d-none d-lg-flex"
                      href={
                        props.data != null
                          ? `/site/${props.data.param}/favoritos`
                          : "/favoritos"
                      }
                    >
                      <span className="navbar-tool-tooltip">Favoritos</span>
                      <div className="navbar-tool-icon-box">
                        <i className="navbar-tool-icon czi-heart"></i>
                      </div>
                    </a>
                    <a
                      className="navbar-tool ml-1 ml-lg-0 mr-n1 mr-lg-2"
                      href={
                        dados.nome
                          ? handlePerfil(props.data)
                          : handleEntrar(props.data)
                      }
                    >
                      <div className="navbar-tool-icon-box">
                        <i className="navbar-tool-icon czi-user"></i>
                      </div>
                      <div className="navbar-tool-text ml-n3">
                        <small>
                          Olá, {dados.nome ? firstName : "entrar em"}
                        </small>
                        Minha conta
                      </div>
                    </a>
                    <div className="navbar-tool dropdown ml-3">
                      <a
                        className="navbar-tool-icon-box bg-secondary dropdown-toggle"
                        href={
                          props.data != null
                            ? `/site/${props.data.param}/carrinho`
                            : "/carrinho"
                        }
                      >
                        <span className="navbar-tool-label bg-primary">
                          {produtos.length}
                        </span>
                        <i className="navbar-tool-icon czi-cart"></i>
                      </a>
                      <a
                        className="navbar-tool-text"
                        href={
                          props.data != null
                            ? `/site/${props.data.param}/carrinho`
                            : "/carrinho"
                        }
                      >
                        <small>Meu carrinho</small> R${formatNumber(getTotal)}
                      </a>
                      <CartDropDown />
                    </div>
                  </div>
                </div>

                <div className="d-sm-none" style={{ width: "100%" }}>
                  <div className="navbar-toolbar d-flex justify-content-between">
                    <div
                      className="d-flex justify-content-between"
                      style={{ width: "33.3%" }}
                    >
                      <button
                        ref={menuColapse}
                        className="navbar-toggler"
                        onClick={() => handleToggleMenu(0)}
                        type="button"
                        data-toggle="collapse"
                        data-target="#navbarCollapse"
                      >
                        <span className="navbar-toggler-icon"></span>
                      </button>
                      <a className="navbar-tool navbar-stuck-toggler" href="# ">
                        <span className="navbar-tool-tooltip">
                          Expandir menu
                        </span>
                        <div className="navbar-tool-icon-box">
                          <i className="navbar-tool-icon czi-menu"></i>
                        </div>
                      </a>
                      <a
                        className="navbar-tool d-none d-lg-flex"
                        href={
                          props.data != null
                            ? `/site/${props.data.param}/favoritos`
                            : "/favoritos"
                        }
                      >
                        <span className="navbar-tool-tooltip">Favoritos</span>
                        <div className="navbar-tool-icon-box">
                          <i className="navbar-tool-icon czi-heart"></i>
                        </div>
                      </a>
                    </div>

                    <a
                      className="navbar-brand d-sm-none mr-2"
                      href={subSite != null ? `/site/${props.data.param}` : "/"}
                      style={{ minWidth: "4.625rem" }}
                    >
                      <img
                        width="100"
                        src="/img/logo-icon-light.png"
                        alt="Cartzilla"
                      />
                    </a>
                    <div
                      className="d-flex justify-content-between"
                      style={{ width: "33.3%" }}
                    >
                      <a
                        className="navbar-tool ml-1 ml-lg-0 mr-n1 mr-lg-2"
                        href={
                          dados.nome
                            ? handlePerfil(props.data)
                            : handleEntrar(props.data)
                        }
                      >
                        <div className="navbar-tool-icon-box">
                          <i className="navbar-tool-icon czi-user"></i>
                        </div>
                        <div className="navbar-tool-text ml-n3">
                          <small>
                            Olá, {dados.nome ? firstName : "entrar em"}
                          </small>
                          Minha conta
                        </div>
                      </a>
                      <div className="navbar-tool dropdown ml-3">
                        <a
                          className="navbar-tool-icon-box bg-secondary dropdown-toggle"
                          href={
                            props.data != null
                              ? `/site/${props.data.param}/carrinho`
                              : "/carrinho"
                          }
                        >
                          <span className="navbar-tool-label">
                            {produtos.length}
                          </span>
                          <i className="navbar-tool-icon czi-cart"></i>
                        </a>
                        <a
                          className="navbar-tool-text"
                          href={
                            props.data != null
                              ? `/site/${props.data.param}/carrinho`
                              : "/carrinho"
                          }
                        >
                          <small>Meu carrinho</small> R${formatNumber(getTotal)}
                        </a>
                        <CartDropDown />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="navbar navbar-expand-lg navbar-light bg-light mt-n2 pt-0 pb-2">
              <div className="container">
                <div
                  className="collapse navbar-collapse d-none"
                  id="navbarCollapse"
                  ref={navbarRef}
                >
                  <form
                    className="input-group-overlay d-lg-none my-3"
                    id="buscar1"
                    action={
                      props.data != null
                        ? `/site/${props.data.param}/busca`
                        : "/busca"
                    }
                  >
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        document.getElementById("buscar1").submit()
                      }
                      className="input-group-prepend-overlay"
                    >
                      <span className="input-group-text">
                        <i className="czi-search"></i>
                      </span>
                    </div>
                    <input
                      name="texto"
                      className="form-control prepended-form-control"
                      type="text"
                      placeholder="Buscar produtos"
                    />
                  </form>
                  <ul
                    className="navbar-nav d-flex justify-content-center"
                    style={{ width: "100%" }}
                  >
                    <li className="nav-item dropdown">
                      <a
                        className="nav-link"
                        href={
                          props.data != null ? `/site/${props.data.param}` : "/"
                        }
                      >
                        INÍCIO
                      </a>
                    </li>
                    {menus.categorias.map(
                      (item, index) =>
                        index <= 5 && (
                          <li key={item.slug} className="nav-item dropdown">
                            <a
                              className="nav-link"
                              href={
                                props.data != null
                                  ? `/site/${props.data.param}/categoria/${item.nome.toLowerCase()}/${item.id}`
                                  : `/categoria/${item.nome.toLowerCase()}/${item.id}`
                              }
                            >
                              {item.nome}
                            </a>
                            {item.subcategorias.length !== 0 && <NavLinkDropdown subCategorie={item.subcategorias} dataLink={props.data} categoriaName={item.nome} categorieId={item.id} />}
                          </li>
                        )
                    )}

                    {menus.categorias.length > 5 && (
                      <li className="nav-item dropdown">
                        <a
                          className="nav-link dropdown-toggle"
                          onClick={() => handleToggleMenu(1)}
                          href="#"
                        >
                          OUTROS
                        </a>
                        {evento ? (
                          <>
                            {menus.categorias.map(
                              (item, chave) => {
                                return chave > 5 && (

                                  <li
                                    key={item.slug}
                                    className="nav-item dropdown pb-2"
                                  >
                                    <a
                                      className="widget-list-link"
                                      href={
                                        props.data != null
                                          ? `/site/${props.data.param}/categoria/${item.slug}/${item.id}`
                                          : `/categoria/${item.slug}/${item.id}`
                                      }
                                    >
                                      {item.nome}
                                    </a>
                                    {item.subcategorias.length !== 0 && <NavLinkDropdown subCategorie={item.subcategorias} dataLink={props.data} categoriaName={item.nome} categorieId={item.id} />}
                                  </li>
                                )
                              })}
                          </>
                        ) : (
                          <></>
                        )}
                      </li>
                    )}

                    {menus.paginas.map(
                      (item) =>
                        item.categoria == "menu" &&
                        item.paginas.map((pagina) => (
                          <li key={pagina.slug} className="nav-item dropdown">
                            <a
                              className="nav-link"
                              href={
                                props.data != null
                                  ? `/site/${props.data.param}/detalhe/${pagina.slug}`
                                  : "/detalhe/" + pagina.slug
                              }
                            >
                              {pagina.nome}
                            </a>
                          </li>
                        ))
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </header>
      ) : (
        <></>
      )}
    </>
  );
};

export default inject(
  "ClientStore",
  "CarrinhoStore",
  "HeaderStore"
)(observer(Header));

/**
 * <>
      {visible.on ? (
        <header className="box-shadow-sm">
          <div className="topbar topbar-dark bg-primary d-none d-md-block">
            <div className="container">
              <div className="topbar-text dropdown d-md-none">
                <a
                  className="topbar-link dropdown-toggle"
                  href="# "
                  data-toggle="dropdown"
                >
                  Links úteis
                </a>
                <ul className="dropdown-menu">
                  <li>
                    <a className="dropdown-item" href="tel:9130857656">
                      <i className="czi-support text-muted mr-2"></i>(91)
                      3085-7656
                    </a>
                  </li>
                </ul>
              </div>
              <div className="topbar-text text-nowrap d-none d-md-inline-block">
                <i
                  className={
                    subSite != null ? "czi-support" : "czi-support text-light"
                  }
                  style={subSite != null ? { color: subSite.corFonte } : {}}
                ></i>
                <span
                  className={subSite != null ? "mr-1" : "text-muted mr-1"}
                  style={subSite != null ? { color: subSite.corFonte } : {}}
                >
                  Suporte
                </span>
                <a className="topbar-link" href="tel:9130857656">
                  (91) 3085-7656
                </a>
              </div>
            </div>
          </div>

          <div className="navbar-sticky bg-dark">
            <div className="navbar navbar-expand-lg navbar-light bg-light">
              <div className="container">
                <form
                  id="buscar2"
                  action={
                    subSite != null
                      ? `/site/${props.data.param}/busca`
                      : "/busca"
                  }
                  className="input-group-overlay d-none d-lg-flex mx-4 col-sm-3"
                >
                  <input
                    name="texto"
                    className="form-control appended-form-control"
                    type="text"
                    placeholder="Buscar produtos"
                  />
                  <div
                    style={{ cursor: "pointer", marginRight: 10 }}
                    onClick={() => document.getElementById("buscar2").submit()}
                    className="input-group-append-overlay"
                  >
                    <span className="input-group-text">
                      <i className="czi-search"></i>
                    </span>
                  </div>
                </form>
                <div className="d-flex justify-content-center col-sm-6">
                  <a
                    className="navbar-brand d-none d-sm-block mr-3 flex-shrink-0 "
                    href={subSite != null ? `/site/${props.data.param}` : "/"}
                    style={{ minWidth: "7rem" }}
                  >
                    <img src="/img/logo-light.png" />
                  </a>
                </div>

                <div className="d-none d-sm-block">
                  <div className="navbar-toolbar d-flex flex-shrink-0 align-items-center">
                    <button
                      onClick={() => teste(1)}
                      className="navbar-toggler"
                      type="button"
                      data-toggle="collapse"
                      data-target="#navbarCollapse"
                    >
                      <span className="navbar-toggler-icon"></span>
                    </button>
                    <a className="navbar-tool navbar-stuck-toggler" href="#">
                      <span className="navbar-tool-tooltip">Expandir menu</span>
                      <div className="navbar-tool-icon-box">
                        <i className="navbar-tool-icon czi-menu"></i>
                      </div>
                    </a>
                    <a
                      className="navbar-tool d-none d-lg-flex"
                      href={
                        props.data != null
                          ? `/site/${props.data.param}/favoritos`
                          : "/favoritos"
                      }
                    >
                      <span className="navbar-tool-tooltip">Favoritos</span>
                      <div className="navbar-tool-icon-box">
                        <i className="navbar-tool-icon czi-heart"></i>
                      </div>
                    </a>
                    <a
                      className="navbar-tool ml-1 ml-lg-0 mr-n1 mr-lg-2"
                      href={
                        dados.nome
                          ? handlePerfil(props.data)
                          : handleEntrar(props.data)
                      }
                    >
                      <div className="navbar-tool-icon-box">
                        <i className="navbar-tool-icon czi-user"></i>
                      </div>
                      <div className="navbar-tool-text ml-n3">
                        <small>
                          Olá, {dados.nome ? firstName : "entrar em"}
                        </small>
                        Minha conta
                      </div>
                    </a>
                    <div className="navbar-tool dropdown ml-3">
                      <a
                        className="navbar-tool-icon-box bg-secondary dropdown-toggle"
                        href={
                          props.data != null
                            ? `/site/${props.data.param}/carrinho`
                            : "/carrinho"
                        }
                      >
                        <span className="navbar-tool-label bg-primary">
                          {produtos.length}
                        </span>
                        <i className="navbar-tool-icon czi-cart"></i>
                      </a>
                      <a
                        className="navbar-tool-text"
                        href={
                          props.data != null
                            ? `/site/${props.data.param}/carrinho`
                            : "/carrinho"
                        }
                      >
                        <small>Meu carrinho</small> R${formatNumber(getTotal)}
                      </a>
                      <CartDropDown />
                    </div>
                  </div>
                </div>

                <div className="d-sm-none" style={{ width: "100%" }}>
                  <div className="navbar-toolbar d-flex justify-content-between">
                    <div
                      className="d-flex justify-content-between"
                      style={{ width: "33.3%" }}
                    >
                      <button
                        className="navbar-toggler"
                        onClick={() => teste(2)}
                        type="button"
                        data-toggle="collapse"
                        data-target="#navbarCollapse"
                      >
                        <span className="navbar-toggler-icon"></span>
                      </button>
                      <a className="navbar-tool navbar-stuck-toggler" href="# ">
                        <span className="navbar-tool-tooltip">
                          Expandir menu
                        </span>
                        <div className="navbar-tool-icon-box">
                          <i className="navbar-tool-icon czi-menu"></i>
                        </div>
                      </a>
                      <a
                        className="navbar-tool d-none d-lg-flex"
                        href={
                          props.data != null
                            ? `/site/${props.data.param}/favoritos`
                            : "/favoritos"
                        }
                      >
                        <span className="navbar-tool-tooltip">Favoritos</span>
                        <div className="navbar-tool-icon-box">
                          <i className="navbar-tool-icon czi-heart"></i>
                        </div>
                      </a>
                    </div>

                    <a
                      className="navbar-brand d-sm-none mr-2"
                      href={subSite != null ? `/site/${props.data.param}` : "/"}
                      style={{ minWidth: "4.625rem" }}
                    >
                      <img
                        width="100"
                        src="/img/logo-icon-light.png"
                        alt="Cartzilla"
                      />
                    </a>
                    <div
                      className="d-flex justify-content-between"
                      style={{ width: "33.3%" }}
                    >
                      <a
                        className="navbar-tool ml-1 ml-lg-0 mr-n1 mr-lg-2"
                        href={
                          dados.nome
                            ? handlePerfil(props.data)
                            : handleEntrar(props.data)
                        }
                      >
                        <div className="navbar-tool-icon-box">
                          <i className="navbar-tool-icon czi-user"></i>
                        </div>
                        <div className="navbar-tool-text ml-n3">
                          <small>
                            Olá, {dados.nome ? firstName : "entrar em"}
                          </small>
                          Minha conta
                        </div>
                      </a>
                      <div className="navbar-tool dropdown ml-3">
                        <a
                          className="navbar-tool-icon-box bg-secondary dropdown-toggle"
                          href={
                            props.data != null
                              ? `/site/${props.data.param}/carrinho`
                              : "/carrinho"
                          }
                        >
                          <span className="navbar-tool-label">
                            {produtos.length}
                          </span>
                          <i className="navbar-tool-icon czi-cart"></i>
                        </a>
                        <a
                          className="navbar-tool-text"
                          href={
                            props.data != null
                              ? `/site/${props.data.param}/carrinho`
                              : "/carrinho"
                          }
                        >
                          <small>Meu carrinho</small> R${formatNumber(getTotal)}
                        </a>
                        <CartDropDown />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="navbar navbar-expand-lg navbar-light bg-light mt-n2 pt-0 pb-2">
              <div className="container">
                <div className="collapse navbar-collapse" id="navbarCollapse">
                  <form
                    className="input-group-overlay d-lg-none my-3"
                    id="buscar1"
                    action={
                      props.data != null
                        ? `/site/${props.data.param}/busca`
                        : "/busca"
                    }
                  >
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        document.getElementById("buscar1").submit()
                      }
                      className="input-group-prepend-overlay"
                    >
                      <span className="input-group-text">
                        <i className="czi-search"></i>
                      </span>
                    </div>
                    <input
                      name="texto"
                      className="form-control prepended-form-control"
                      type="text"
                      placeholder="Buscar produtos"
                    />
                  </form>
                  <ul
                    className="navbar-nav d-flex justify-content-center"
                    style={{ width: "100%" }}
                  >
                    <li className="nav-item dropdown">
                      <a
                        className="nav-link"
                        href={
                          props.data != null ? `/site/${props.data.param}` : "/"
                        }
                      >
                        INÍCIO
                      </a>
                    </li>
                    {menus.categorias.map(
                      (item, index) =>
                        index <= 5 && (
                          <li key={item.slug} className="nav-item dropdown">
                            <a
                              className="nav-link"
                              href={
                                props.data != null
                                  ? `/site/${props.data.param}/categoria/${item.slug}`
                                  : `/categoria/${item.slug}`
                              }
                            >
                              {item.nome}
                            </a>
                          </li>
                        )
                    )}

                    {menus.categorias.length > 5 && (
                      <li className="nav-item dropdown">
                        <a
                          className="nav-link dropdown-toggle"
                          onClick={() => teste(3)}
                          href="#"
                          data-toggle="dropdown"
                        >
                          OUTROS
                        </a>
                        <div className="dropdown-menu p-0">
                          <div className="d-flex flex-wrap flex-md-nowrap px-2">
                            <div className="mega-dropdown-column py-4 px-3">
                              <div className="widget widget-links mb-3">
                                <ul className="widget-list">
                                  {menus.categorias.map(
                                    (item, chave) =>
                                      chave > 5 && (
                                        <li
                                          key={item.slug}
                                          className="widget-list-item pb-1"
                                        >
                                          <a
                                            className="widget-list-link"
                                            href={
                                              props.data != null
                                                ? `/site/${props.data.param}/categoria/${item.slug}`
                                                : `/categoria/${item.slug}`
                                            }
                                          >
                                            {item.nome}
                                          </a>
                                        </li>
                                      )
                                  )}
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                    )}

                    {menus.paginas.map(
                      (item) =>
                        item.categoria == "menu" &&
                        item.paginas.map((pagina) => (
                          <li key={pagina.slug} className="nav-item dropdown">
                            <a
                              className="nav-link"
                              href={
                                props.data != null
                                  ? `/site/${props.data.param}/detalhe/${pagina.slug}`
                                  : "/detalhe/" + pagina.slug
                              }
                            >
                              {pagina.nome}
                            </a>
                          </li>
                        ))
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </header>
      ) : (
        <></>
      )}
    </>
 */
