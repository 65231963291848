import React from 'react';
import { inject, observer } from 'mobx-react';
import { Route, Redirect } from 'react-router-dom';
import Cookies from 'js-cookie'


const AuthRoute = ({logged, data,  ...props}) => {
    
    return (
        <Route {...props} >
            
            {Cookies.get('token')
                ? props.children
                : <> <Redirect to='/entrar' /> </>
            }
        </Route>
    );
}

export default inject("HeaderStore")(observer(AuthRoute));