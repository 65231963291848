import styled from 'styled-components';



export const AreaBemVindo = styled.div`
    display:flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 379px;
    background-image: url("../img/cadastrar-revendedor/secondBanner.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    margin-top: -5px;

    @media only screen and (max-width: 770px)  {

        height:300px !important;

        h3 {
            font-size: 20px !important;
        }

        h5 {
            font-size: 15px !important;
        }
    }
  
`;

export const Title = styled.h3`
    color: #FFFFFF;   
    text-align: center !important;

    @media only screen and (max-width: 1024px)  {
        
    }

    
`;

export const TextContent = styled.h5`
    color: #FFFFFF;   
    text-align: center !important;  

    @media only screen and (max-width: 1024px)  {
        
    } 

    
`;

export const ContainerContent = styled.div`
    display: flex;
    justify-content: center;

`;

export const Area = styled.div`
    margin-top: -50px;
    @media only screen and (max-width: 1024px)  {
    }
  
`;

export const AreImg = styled(Area)`
    margin-bottom: 60px;

    @media only screen and (max-width: 770px)  { 
        margin-bottom: 30px;
        margin-top: 40px;
    }
`;


