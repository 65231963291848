import axios from "axios";
// producao: 'https://www.ronnellypara.com.br/api/'
// desenvolvimento:"http://localhost:8000/api/"
//http://192.168.15.13:8000/api/
//let base = "http://ronnelly.bredi/api/";
let base = "https://app.ronnellypara.com.br/api/";

const api = axios.create({
  baseURL: base,
});

//window.location.href = window.location.href.replace('https','http')

export default api;
