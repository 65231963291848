import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import subSiteConfig from '../../services/subSite';

const FavoriteProduct = ({ image, title, brand, price, deletar, id, slug, HeaderStore}) => {


  const { setSubSite, subSite } = HeaderStore;
  let { vendedor } = useParams();

   //subsite
   const subSiteLayout = async (v) => {
    return await subSiteConfig.handleSubsite(v)

}

const reqSubsite = async (v) => {
    let obj = await subSiteConfig.getUrlParameter(v)
    setSubSite(obj);
}

useEffect(() => {
        
    reqSubsite(vendedor)

},[]) 

useEffect(() => {
        

    console.log("subSite: ", subSite)
    if(subSite!=null) {
        console.log("subSite: ENTROU")
        subSiteLayout(subSite)
    }

},[subSite]) 
//subsite


  return (
    <div className="d-sm-flex justify-content-between mt-lg-4 mb-4 pb-3 pb-sm-2 border-bottom">
      <div className="media media-ie-fix d-block d-sm-flex text-center text-sm-left"><Link className="d-inline-block mx-auto mr-sm-4" to={subSite != null ? `/site/${subSite.param}/produto/${id}/${slug}` : "/produto/"+id+"/"+slug} style={{ width: '10rem' }}>
        <img src={ image } alt="Product" /></Link>
        <div className="media-body pt-2">
          <h3 className="product-title font-size-base mb-2">
            <Link to={subSite != null ? `/site/${subSite.param}/produto/${id}/${slug}` : "/produto/"+id+"/"+slug}>
              { title }
            </Link>
          </h3>
          <div className="font-size-sm">
            <span className="text-muted mr-2">Marca:</span>
            { brand }
          </div>
          <div className="font-size-lg text-accent pt-2">
            R$ { price }
          </div>
        </div>
      </div>
      <div className="pt-2 pl-sm-3 mx-auto mx-sm-0 text-center">
        <button onClick={deletar} className="btn btn-outline-danger btn-sm" type="button">
          <i className="czi-trash mr-2"></i>Remove
        </button>
      </div>
    </div>
  );
}

export default inject('ClientStore', 'ProdutoStore', "HeaderStore")(observer(FavoriteProduct));