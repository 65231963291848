import React, { useState } from 'react';

import {
    AreaBemVindo,
    Title,
    TextContent,
    Area,
    AreImg,
    CadastrarBtn,
    AreaBtn,
    ContainerContent,
    Wrapper
} from './styles';

const  ThirdBanner = (props) => {


    return(
        <AreaBemVindo> 
            <Area>
                <AreImg className="col-12 p-2 text-center" >
                    <img src="/img/cadastrar-revendedor/logo-branca.png"/>
                </AreImg>
                <ContainerContent>
                    <Wrapper >
                        <Title>Seja um Consultor de sucesso!</Title>
                        <TextContent>Conquiste sua indepêndencia financeira e conte com suporte e apoio completo da Ronnelly em todo o Brasil. Conheça os benefícios:</TextContent> 
                    </Wrapper>
                </ContainerContent>
                <AreaBtn>
                    <CadastrarBtn className="btn-primary btn-shadow" data-target="#revendedor-register" data-toggle="modal">
                        Cadastre-se Agora
                    </CadastrarBtn> 
                </AreaBtn>
            </Area>
        </AreaBemVindo>
    );
}

export default ThirdBanner;