import { decorate, observable, action } from "mobx";

class CadastroRevendedorStore {

    
    dados = {};
    

    getDados =  async (data) =>{
        if (data) {
            this.dados = JSON.parse(data);
        }
    }

}

decorate(CadastroRevendedorStore, {
    getDados: action,
});

export default new CadastroRevendedorStore();