/* eslint-disable react-hooks/exhaustive-deps */
import loadjs from 'loadjs'
import { inject, observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import { useAlert } from 'react-alert'
import { useParams } from 'react-router-dom'
import Skeleton from 'react-loading-skeleton'
import ButtonLoading from '../components/ButtonLoading'
import CardProductHome from '../components/CardProductHome'
import Carousel from '../components/Carousel'
import CarouselCategory from '../components/CarouselCategory'
import Banner2 from '../../src/components/BannerMeio'
import CarouselMini from '../components/CarouselMini'
import MiniBanner from '../components/MiniBanner'
import TitleCardProduct from '../components/TitleCardProduct'
import ModalPromocao from '../components/ModalPromocao'
import { Button, Modal } from 'react-bootstrap'
import config from '../config'
import api from '../services/api'
import subSiteConfig from '../services/subSite'
import Cookies from 'js-cookie'

const Home = props => {
  const [products, setProducts] = useState([])
  const [banners, setBanners] = useState([])
  const [miniBanners, setMiniBanners] = useState([])
  const [category, setCategory] = useState({ produtos: [] })
  const [page, setPage] = useState(1)
  const [loading, setLoading] = useState(false)
  const alert = useAlert()
  const { visible, setVisible, setSubSite, subSite, setSubSiteParam } =
    props.HeaderStore
  const { token, dados } = props.ClientStore

  const [modalShow, setModalShow] = useState(false)
  const [modalShowData, setModalShowData] = useState({})

  let { vendedor } = useParams()

  const getProduct = async () => {
    setLoading(true)

    const response = await api.get(
      '/loja/paginate/' + config.loja_id + '?page=' + page + '&categoria=0',
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        },
      }
    )
    //console.log("Produtos: ", response);
    setLoading(false)

    if (response.data.data) {
      setProducts(response.data.data.produtos.data)
    }
  }

  const handleModalPopup = async () => {
    try {
      const response = await api.get('popup', {
        headers: {
          'Content-Type': 'application/json',
          //Authorization: "Bearer " + token,
        },
      })

      if (response.data.errors == false) {
        setModalShowData(response.data.data.popup)

        if (response.data.data.popup.ativo == 1) {
          setModalShow(true)
        } else {
          setModalShow(false)
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  const getBanners = async () => {
    const response = await api.get('/banners?loja_id=' + config.loja_id, {
      headers: {
        'Content-Type': 'application/json',
      },
    })

    if (response.data.data) {
      setBanners(response.data.data.banners)

      setMiniBanners(response.data.data.minibanners)
    }
  }

  const getCategory = async () => {
    const response = await api.get(
      'categoria/destaque?loja_id=' + config.loja_id,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        },
      }
    )

    if (response.data.data) {
      setCategory(response.data.data)
    }
  }

  async function getPaginate() {
    try {
      setLoading(true)

      let response = await api.get(
        '/loja/paginate/' +
        config.loja_id +
        '?page=' +
        (page + 1) +
        '&categoria=',
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
          },
        }
      )

      if (response.data.data) {
        setProducts([...products, ...response.data.data.produtos.data])
      }

      setPage(page + 1)

      setLoading(false)
    } catch (error) {
      setLoading(false)

      alert.show(error.message, { type: 'error' })
    }
  }

  useEffect(() => {
    getBanners()
    loadjs('/js/theme.min.js')
    getProduct()
    getCategory()
    handleModalPopup()
    loadjs('/js/theme.min.js')

    if (visible.on != true) {
      setVisible(true)
    }
  }, [])

  //subsite
  const subSiteLayout = async v => {
    return await subSiteConfig.handleSubsite(v)
  }

  const reqSubsite = async v => {
    let obj = await subSiteConfig.getUrlParameter(v)

    Cookies.set('vendedor_id', obj?.vendedor_id, { expires: 1 })

    setSubSite(obj)
  }

  useEffect(() => {
    reqSubsite(vendedor)
  }, [])

  useEffect(() => {
    if (subSite != null) {
      setSubSiteParam(subSite)
      subSiteLayout(subSite)
    }
  }, [subSite])

  return (
    <>
      {banners.length ? <Carousel data={banners} /> : <Skeleton height={300} />}

      {banners.length && (
        <div className="container">
          <CarouselMini data={banners} tipo="2" />
        </div>
      )}

      <Banner2 />

      <MiniBanner data={miniBanners} />

      {/* <CardBanner data={miniBanners} /> */}

      <TitleCardProduct title="Nossos Clássicos">
        {products.length ? (
          products.map(item => (
            <CardProductHome
              key={item.id}
              product={item}
              subSite={subSite}
              cliente={dados.tipo_cliente_id}
            />
          ))
        ) : (
          <>
            <div className="col-lg-3 col-md-4 col-sm-6 px-2 mb-4">
              <Skeleton height={200} />
            </div>

            <div className="col-lg-3 col-md-4 col-sm-6 px-2 mb-4">
              <Skeleton height={200} />
            </div>

            <div className="col-lg-3 col-md-4 col-sm-6 px-2 mb-4">
              <Skeleton height={200} />
            </div>

            <div className="col-lg-3 col-md-4 col-sm-6 px-2 mb-4">
              <Skeleton height={200} />
            </div>
          </>
        )}
      </TitleCardProduct>

      <div className="container pt-md-3 pb-5 mb-md-3">
        <div className="text-center">
          <ButtonLoading
            class="btn btn-primary"
            onClick={() => getPaginate()}
            loading={loading}
            title="Mais produtos"
          />
        </div>
      </div>

      {banners.length && (
        <div className="container mb-5">
          <CarouselMini data={banners} tipo="3" />
        </div>
      )}

      {category.produtos.length > 0 && <CarouselCategory data={category} />}

      <ModalPromocao
        show={modalShow}
        data={modalShowData}
        onHide={() => setModalShow(false)}
      />
    </>
  )
}

export default inject('ClientStore', 'HeaderStore')(observer(Home))
