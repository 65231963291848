import React from 'react';


const InfoFooter = (props) => {

  return (
    <>
      <div className="row pb-3">
        {/*
        <div className="col-md-3 col-sm-6 mb-4">
            <div className="media"><i className="czi-rocket text-primary" style={{ fontSize: "2.25rem" }}></i>
                <div className="media-body pl-3">
                    <h6 className="font-size-base mb-1">Entrega rápida e gratuita</h6>
                    <p className="mb-0 font-size-ms opacity-50">Entrega gratuita para todas as encomendas superiores a R$ 200</p>
                </div>
            </div>
        </div>
        <div className="col-md-3 col-sm-6 mb-4">
            <div className="media"><i className="czi-currency-exchange text-primary" style={{ fontSize: "2.25rem" }}></i>
                <div className="media-body pl-3">
                    <h6 className="font-size-base mb-1">Garantia de devolução de dinheiro</h6>
                    <p className="mb-0 font-size-ms opacity-50">Devolvemos dinheiro dentro de 30 dias</p>
                </div>
            </div>
        </div>
        */}
        <div className="col-md-3 col-sm-6 mb-4">
            <div className="media"><i className="czi-support text-primary" style={{ fontSize: "2.25rem" }}></i>
                <div className="media-body pl-3">
                    <h6 className="font-size-base mb-1">Suporte ao cliente 24/7</h6>
                    <p className="mb-0 font-size-ms opacity-50">Suporte ao cliente amigável 24/7</p>
                </div>
            </div>
        </div>
        <div className="col-md-3 col-sm-6 mb-4">
            <div className="media"><i className="czi-card text-primary" style={{ fontSize: "2.25rem" }}></i>
                <div className="media-body pl-3">
                    <h6 className="font-size-base mb-1">Pagamento online seguro</h6>
                    <p className="mb-0 font-size-ms opacity-50">Possuímos certificado SSL / Secure</p>
                </div>
            </div>
        </div>
    </div>
    <hr className="pb-4 mb-3" />
    <div className="row pb-2">
        <div className="col-md-6 text-center text-md-left mb-4">
            <div className="text-nowrap mb-4"><a className="d-inline-block align-middle mt-n1 mr-3" href="# "><img className="d-block" width="117" src="/img/footer-logo-light.png" alt="Cartzilla" /></a>
            </div>
            <div className="widget widget-links">
                <ul className="widget-list d-flex flex-wrap justify-content-center justify-content-md-start">
                    {props.data.paginas.map((item) =>
                        (item.categoria == 'rodape') &&
                        item.paginas.map((pagina) =>
                            <li key={pagina.slug} className="widget-list-item mr-4"><a className="widget-list-link" href={"/detalhe/" + pagina.slug}>{pagina.nome}</a></li>
                        )
                    )}
                </ul>
            </div>
        </div>
        <div className="col-md-6 text-center text-md-right mb-4">
            <div className="mb-3">
                <a className="social-btn sb-outline sb-facebook ml-2 mb-2" href="https://www.facebook.com/ronnellyjoiasfolheadas/" target="_blank">
                    <i className="czi-facebook"></i>
                </a>
                <a className="social-btn sb-outline sb-instagram ml-2 mb-2" href="https://www.instagram.com/ronnellypara/" target="_blank">
                    <i className="czi-instagram"></i>
                </a>
            </div>
            <img className="d-inline-block" width="187" src="/img/cards-alt.svg" alt="Payment methods" />
        </div>
    </div>
    <div className="pb-4 font-size-xs opacity-50 text-center text-md-left">© Todos os direitos reservados.</div>
    <div style={{height:"3.5rem"}}></div>

    </>
  );
}

export default InfoFooter;