import React, { useEffect,  useState } from 'react';
import { inject, observer } from 'mobx-react';
import { closeModal } from '../../services/helpers';
import ButtonLoading from '../../components/ButtonLoading';
import TitleCardProduct from '../../components/TitleCardProduct';
import InputMask from 'react-input-mask';
import { useAlert } from 'react-alert';
import api from '../../services/api';
import subSiteConfig from '../../services/subSite';
import {
  useParams
} from "react-router-dom";



import {
  ContainerAlert,
  ContainerInfo,
  ContainerX,
  SpanButton
} from './styles';

import Loading from '../../components/Loading';

const Garantias = (props) => {  
  const { setSubSite, subSite } = props.HeaderStore;
  const { vendedor } = useParams();

  useEffect(()=>{
    
  },[]);

  //subsite
    const subSiteLayout = async (v) => {
        return await subSiteConfig.handleSubsite(v)

    }

    const reqSubsite = async (v) => {
        let obj = await subSiteConfig.getUrlParameter(v)
        setSubSite(obj);
    }

    useEffect(() => {
                  
        reqSubsite(vendedor)

    },[]) 

    useEffect(() => {
            

        console.log("subSite: ", subSite)
        if(subSite!=null) {
            console.log("subSite: ENTROU")
            subSiteLayout(subSite)
        }

    },[subSite])  
//subsite


  return (
    <>
            <TitleCardProduct>
                <div className="col-12 p-5 text-center">
                    <div style={{padding: "10px", margin: "10px"}}>
                        <img src="/img/logo-light-rosa.png" style={{minWidth: "3.5rem"}}/>
                    </div>
                    <h2>Garantia</h2>
                    <div className="text-left" style={{color: "#727176"}}>
                        <div className="col-12 p-3">
                            <section>
                            A garantia das semijoias Ronnelly Pará tem um prazo de 1 (um) ano mediante apresentação do produto acompanhado do certificado de garantia, entrando em contato com consultor (a) ou loja onde tenha adquirido a peça . Nossa garantia só será validada após a análise do produto e a certificação de que a avaria foi por fabricação da peça (folheação), e não por mau uso. 
                            </section>  
                            <section>
                            Validada a garantia,  nosso cliente receberá o produto consertado ou será gerado um crédito no valor que foi comprado, se o produto já estiver fora de linha/não disponível a pronta entrega.
                            </section>  
                        </div>
                        <div className="col-12 p-3">
                            <section >
                              <strong>As peças consideradas em garantia são:</strong>
                            </section> 
                            <section >
                              • Qualquer defeito de fabricação (peças novas);
                            </section> 
                            <section >
                              • Com desprendimento total ou parcial da folheação;
                            </section>   
                        </div>
                        <div className="col-12 p-3">
                            <section >
                              <strong>Exclui-se da garantia peças: </strong>
                            </section> 
                            <section >
                            • Escurecidas por sujeira;
                            </section> 
                            <section >
                            • Quebradas;
                            </section> 
                            <section >
                            • Incompletas;
                            </section> 
                            <section >
                            • Arranhadas;
                            </section> 
                            <section >
                            • Amassadas;
                            </section> 
                            <section >
                            • Avermelhadas;
                            </section>   
                            <section >
                            • Com pedras opacas ou leitosas;
                            </section> 
                            <section >
                            • Pedras caídas;
                            </section> 
                            <section >
                            • Por mau uso;
                            </section>
                            <section >
                            • Peças gravadas;
                            </section> 
                            <section >
                            • Desprendimento ou escurecimento de resina;
                            </section> 
                        </div>
                        <div className="col-12 p-3">
                            <p >
                              CUIDADOS PARA EVITAR MAU USO DO PRODUTO
                            </p> 
                            <section >
                              <strong>Evitar o contato com:</strong>
                            </section> 
                            <section >
                            • Água do mar, da piscina;
                            </section>   
                            <section >
                            • Cremes;
                            </section> 
                            <section >
                            • Produtos Químicos;
                            </section> 
                            <section >
                            • Umidade;
                            </section> 
                            <section >
                            • Suor excessivo;
                            </section>  
                            <section >
                            • Perfumes;
                            </section> 
                            <section >
                            • Qualquer produto abrasivo.
                            </section> 
                        </div>
                    </div>
                </div>
            </TitleCardProduct>

        </>
    
  );
}

export default inject('CadastroRevendedorStore', "HeaderStore")(observer(Garantias));