import React, { useState } from 'react'
import { Data } from './Data'
import { IconContext } from 'react-icons'
import { FiPlus, FiMinus } from 'react-icons/fi'

import {
  Area,
  AccordionSection,
  AccordionContainer,
  Wrap,
  Dropdown,
  AreaBanner,
} from './styles'

const AccordionDetails = props => {
  const [clicked, setClicked] = useState(false)

  const handleToggle = index => {
    if (clicked === index) {
      //clicado, impedindo que abra duas vezes
      return setClicked(null)
    }
    setClicked(index)
  }

  return (
    <>
      <div
        className="row"
        style={{ padding: '0', marginRight: '0px', marginLeft: '0px' }}
      >
        <div
          className="col-md-6 col-sm-6"
          style={{
            padding: '0px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Area>
            <IconContext.Provider value={{ color: '#002D6D', size: '21px' }}>
              <AccordionSection>
                <AccordionContainer>
                  {Data.map((item, index) => {
                    return (
                      <div key={index}>
                        <Wrap onClick={() => handleToggle(index)} key={index}>
                          <h4>{item.title}</h4>
                          <span>
                            {clicked === index ? <FiMinus /> : <FiPlus />}
                          </span>
                        </Wrap>
                        {clicked === index ? (
                          <Dropdown>
                            <h6>{item.body}</h6>
                          </Dropdown>
                        ) : null}
                      </div>
                    )
                  })}
                </AccordionContainer>
              </AccordionSection>
            </IconContext.Provider>
          </Area>
        </div>
        <div className="col-md-6 col-sm-6" style={{ padding: '0px' }}>
          <AreaBanner src="../img/cadastrar-revendedor/joias.png" />
        </div>
      </div>
    </>
  )
}

export default AccordionDetails
