import React from 'react';
import { Link } from 'react-router-dom';

const Breadcrumb = ({ name, link, isHome = false }) => {
  return (
    <li className="breadcrumb-item">
      <Link class="text-nowrap text-dark" to={link}>

        {isHome &&
          <>
            <i className="czi-home text-dark"></i>
            {name}
          </>
        }
        {!isHome &&
          name
        }

      </Link>
    </li>
  );
}

export default Breadcrumb;
