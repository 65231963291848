import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import {
    Area,
} from './styles';

import CompraSucesso from '../../components/CompraSucesso';


const  CheckoutSuccess = () => {


    return(
        <div className="container">
            <CompraSucesso />
        </div>
            
    );
}

export default CheckoutSuccess;  