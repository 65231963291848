import React from 'react';
import { Link } from 'react-router-dom';

const NavigationDesktop = ({ linkNext, linkBack, textNext, textBack, loading, onClick }) => {
  return (
    <div className="d-none d-lg-flex pt-4">
      <div className="w-50 pr-3">
        <Link className="btn btn-secondary btn-block" to={linkBack}>
          <i className="czi-arrow-left mt-sm-0 mr-1"></i>
          <span className="d-none d-sm-inline">
            {textBack}
          </span>
          <span className="d-inline d-sm-none">
            Voltar
          </span>
        </Link>
      </div>

      <div className="w-50 pl-2">
        {onClick ?
          <button className="btn btn-primary btn-block" onClick={onClick} disabled={loading}>
            <span className="d-none d-sm-inline">
              {!loading ? textNext : <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>}
            </span>
            <span className="d-inline d-sm-none">
              {!loading ? 'Prosseguir' : <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>}
            </span>
            <i className="czi-arrow-right mt-sm-0 ml-1"></i>
          </button>
          :
          <Link className="btn btn-primary btn-block" to={linkNext}>
            <span className="d-none d-sm-inline">
              {textNext}
            </span>
            <span className="d-inline d-sm-none">
              Prosseguir
            </span>
            <i className="czi-arrow-right mt-sm-0 ml-1"></i>
          </Link>
        }

      </div>
    </div>
  );
}

export default NavigationDesktop;