import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { Link, useParams } from 'react-router-dom';
import subSiteConfig from '../../services/subSite';

const TypeAddress = ({ name, description, checked, change, value, city, state, HeaderStore }) => {

  const { visible, setVisible, setSubSite, subSite, setSubSiteParam } = HeaderStore;

  const handleChecked = async (v) => {
    await subSiteConfig.handleChecked(v)
  }

  useEffect(() => {
    if(subSite!=null) {
        handleChecked(subSite)
    }

},[subSite, checked]) 

  
  return (
    <tr>
      <td>
        <div className="custom-control custom-radio mb-4">
          <input value={value} checked={checked} onChange={change} className="custom-control-input" type="radio" name={"shipping-method"} />
          <label onClick={change} className="custom-control-label" htmlFor={value}></label>
        </div>
      </td>
      <td className="align-middle">
        <span className="text-dark font-weight-medium">
          { name }
        </span><br />
        <span className="text-muted">
          { description }
        </span>
      </td>
      <td className="align-middle">
        { city }
      </td>
      <td className="align-middle">
        { state }
      </td>
    </tr>
  );
}

export default inject("ClientStore","HeaderStore")(observer(TypeAddress));