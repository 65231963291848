import React, { useState, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import api from '../../services/api';
import AddressEdit from './AddressEdit';
import { useParams } from "react-router-dom";
import { useAlert } from 'react-alert';
import subSiteConfig from '../../services/subSite';

const ItemAddress = ({
   address, number, neighborhood,
   city, state, id,
   token, alert, getMyAddresses,
   setLoading, zip_code, complement, tipoCliente, HeaderStore
  }) => {
  
  console.log("tipo cliente: ", tipoCliente)
  const [viewEdit, setViewEdit] = useState(false);
  const { setSubSite, subSite, setSubSiteParam } = HeaderStore;
  const { vendedor } = useParams();

  const handleEdit = event => {
    event.preventDefault();

    setViewEdit(!viewEdit);
  }

  const deleteAddress = async event => {
    event.preventDefault();
    setLoading(true);
    try {
      let response = await api.post('/endereco/delete', {
        id
      }, {
        headers: {
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + token
        }
      });

      if (!response.data.errors) {
        setLoading(false);
        getMyAddresses();
        alert.success('Endereço excluído com sucesso!');
      }
    } catch (error) {
      setLoading(false);
      console.log(error)
    }
  }

   //subsite
   const subSiteLayout = async (v) => {
        return await subSiteConfig.handleSubsite(v)

    }

    const reqSubsite = async (v) => {
        let obj = await subSiteConfig.getUrlParameter(v)
        setSubSite(obj);
    }

    useEffect(() => {
            
        reqSubsite(vendedor)

    },[]) 

    useEffect(() => {
            

        console.log("subSite: ", subSite)
        if(subSite!=null) {
            console.log("subSite: ENTROU")
            setSubSiteParam(subSite)
            subSiteLayout(subSite)
        }

    },[subSite]) 
    //subsite


  return (
    <>
      <tr>
        <td className="py-3 align-middle">
          {`${address}, nº ${number}, ${neighborhood}, ${city}, ${state}`}
        </td>
        <td className="py-3 align-middle">
          {tipoCliente == 2 ?
            <></>

            :
            <>
              <a className="nav-link-style mr-2" onClick={handleEdit} href="/" data-toggle="tooltip" title="Editar">
                <i className="czi-edit"></i>
              </a>
              <a className="nav-link-style text-danger" onClick={deleteAddress} href="/" data-toggle="tooltip" title="Excluir">
                <div className="czi-trash"></div>
              </a>
            </>
          }
          
        </td>
      </tr>
      {viewEdit &&
        <tr>
          <td colSpan="2" className="overflow-hidden">
            <AddressEdit
              address={address}
              city={city}
              complement={complement}
              neighborhood={neighborhood}
              number={number}
              state={state}
              zip_code={zip_code}
              id={id}
              setViewEdit={setViewEdit}
              alert={alert}
              getMyAddresses={getMyAddresses}
              tipoCliente={tipoCliente}
            />
          </td>
        </tr>
      }
    </>
  );
}

export default inject('ClientStore', "HeaderStore")(observer(ItemAddress));