

function formatNumber(amount, decimalCount = 2, decimal = ",", thousands = ".") {
    try {
        decimalCount = Math.abs(decimalCount);
        decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

        const negativeSign = amount < 0 ? "-" : "";

        let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
        let j = (i.length > 3) ? i.length % 3 : 0;

        return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
    } catch (e) {
        console.log(e)
    }
}

const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
}

const getCardFlag = (cardnumber) => {
    console.log("setbandeira: ", cardnumber)
    var cardnumberNum = cardnumber.replace(/[^0-9]+/g, '');

    var cards = {
        amex: /^3[47][0-9]{5}/,
        diners: /^3(?:0[0-5]|[68][0-9])[0-9]{5}/,
        discover: /^6(?:011|5[0-9]{2})[0-9]{5}/,
        elo: /^((((636368)|(438935)|(504175)|(451416)|(636297))\d{0,10})|((5067)|(4576)|(4011))\d{0,5})/,
        jcb: /^(?:2131|1800|35\d{3})\d{5}/,
        master: /^5[1-5][0-9]{5}/,
        visa: /^4[0-9]{5}(?:[0-9]{3})/
    };

    for (var flag in cards) {
        if (cards[flag].test(cardnumberNum)) {
            return flag;
        }
    }

    return false;
}


const getDay = (value) => {
    switch (value) {
        case 0:
            return 'Domingo';
        case 1:
            return 'Segunda';
        case 2:
            return 'Terça';
        case 3:
            return 'Quarta';
        case 4:
            return 'Quinta';
        case 5:
            return 'Sexta';
        case 6:
            return 'Sábado';
        default:
            return false;
    }
}

const getNameBandeira = (value) => {
    switch (value) {
        case 'visa':
            return 'Visa';
        case 'amex':
            return 'American Express';
        case 'diners':
            return 'Diners Club';
        case 'discover':
            return 'Discover';
        case 'elo':
            return 'Elo';
        case 'jcb':
            return 'JCB';
        case 'master':
            return 'MasterCard';
        default:
            return false;
    }
}


const IsEmail = (email) => {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
        return (true)
    }
    return (false)
}

function closeModal(idModal) {
    let element = document.querySelector(idModal);
    let elementBack = document.querySelector('.modal-backdrop');
    let body = document.querySelector('body');

    element.classList.remove('show');
    element.removeAttribute('aria-modal');
    element.setAttribute('aria-hidden', true);
    element.style.display = 'none';

    elementBack.style.display = 'none';
    elementBack.classList.remove('show');

    body.classList.remove('modal-open');
    body.style.paddingRight = '0px';

    return true;
}

export { formatNumber, capitalize, getCardFlag, getNameBandeira, IsEmail, getDay, closeModal };