import styled from 'styled-components';

export const Area =  styled.div`  
    display:flex;
    justify-content: center;
    align-items: center;
    width:100%;
    height:auto;

        h4 {
            color: #002D6D !important;
            font-size: 2rem !important;
            margin-right: 20px !important;
        
        } 

    @media only screen and (max-width: 768px)  { 
        
        height: 500px;

        h4 {
            font-size: 1rem !important;
        
        }

    }

    @media only screen and (max-width: 280px)  { 

        height: 400px;

        h4 {
            font-size: 1rem !important;
        
        }

    }
    
`;

export const AreaBanner = styled.img`
 

`;

export const AccordionSection =  styled.div` 
    justify-content: center;
    align-items: center;
    height: auto;
    width: 100%;
    background: #FFFFFF;


    @media only screen and (max-width: 768px)  { 

        height: 300px;
        width: 300px;

    }  
`;

export const AccordionContainer =  styled.section` 

    padding: 40px;
    width:100%

    @media only screen and (max-width: 768px)  { 

        padding: 10px;

    }

    @media only screen and (max-width: 280px)  { 

        padding: 10px;

    }  
`;



export const Wrap =  styled.div` 
    
    background: #FFFFFF;
    display: flex;
    width:100%;
    justify-content: space-between;
    align-items: center;
    text-align: left;
    cursor: pointer;
    border-bottom: 1px solid #717177;


    @media only screen and (max-width: 768px)  { 

        h4 {
            font-size: 20px;
            padding: 10px;
            color: #002D6D !important;
        
        }    

    }
`;

export const Dropdown =  styled.div` 
    background: #FFFFFF;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; 
    border-top: 1px solid #717177;

    h6 {
        color: #717177 !important;
        padding: 15px;
    } 

    @media only screen and (max-width: 768px)  { 

        h6 {

            font-size: 14px;

        }    

    }
    
`;

