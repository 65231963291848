import React, { useState } from 'react';
import { Carousel } from 'react-bootstrap';

import { Data } from './Data';

import {
    Area,
    CarouselImg,
    BodyContent,
    Avatar,
    ContainerComentario,
    ContainerAspasLeft,
    ContainerAspasRight,
    AspasLeft,
    AspasRight
} from './styles';

const  Comentaries = (props) => {
    return(
            <>
               <Area>
                    <Carousel className="CarouselCustom">
                            {Data.map((item, index)=>{
                                return (
                                <Carousel.Item  key={index} >
                                    <CarouselImg
                                        className="d-block w-100"
                                        src={item.img}
                                        alt="Comentarios"
                                    />
                                    <Carousel.Caption>
                                        <ContainerComentario>
                                            <ContainerAspasLeft>
                                                <AspasLeft />
                                            </ContainerAspasLeft>
                                            <BodyContent>
                                                <h4>{item.body}</h4>
                                                <h6 style={{textAlign:"center"}}>{item.loc}</h6>
                                            </BodyContent>
                                            <ContainerAspasRight>
                                                <AspasRight />
                                            </ContainerAspasRight>
                                        </ContainerComentario>
                                    </Carousel.Caption>
                                    <Avatar>
                                        <img src={item.avatar} />
                                    </Avatar>
                                </Carousel.Item>
                                );
                            })}  
                    </Carousel>   
               </Area>  
            </>
                 
            
    );
}

export default Comentaries;