import React, { useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { transitions, positions, Provider as AlertProvider } from 'react-alert'
import Routes from './routes';
import $ from 'jquery';



import Header from './components/Header';
import Footer from './components/Footer';

const App = (props) => {
  const { getToken, getDados } = props.ClientStore;
  const { getProdutosCarrinho } = props.CarrinhoStore;
  const { getFavorito } = props.ProdutoStore;
  const { getMenus, subSite, subSiteParam } = props.HeaderStore;
  const [ config, setConfig ] = useState(false);
  const [ headerVisible, setHeaderVisible] = useState(true)


  async function initialConfig() {
    getMenus();
    await getToken();
    await getDados();
    await getProdutosCarrinho();
    await getFavorito();
    setConfig(true);
  }

  
  const options = {
    position: positions.TOP_RIGHT,
    timeout: 5000,
    offset: '10px',
    transition: transitions.SCALE,
    containerStyle: {
      zIndex: 99999
    }
  }





   useEffect(() => {
    if(headerVisible){
      initialConfig();
    } else {
      setHeaderVisible(true)
    }
   
    
   }, [headerVisible]);



  const AlertTemplate = ({ style, options, message, close }) => {
    let type = null;
    let icon = null;
    if (options.type === 'info') {
      type = 'info';
      icon = 'czi-bell';
    } else if (options.type === 'success') {
      type = 'success';
      icon = 'czi-check-circle';
    } else if (options.type === 'error') {
      type = 'danger';
      icon = 'czi-close-circle';
    }

    return (
      <div style={style} className={`alert alert-${type} alert-dismissible fade show alert-with-icon`} role="alert">
        <div className="alert-icon-box">
          <i className={`alert-icon ${icon}`}></i>
        </div>
        {message}
        <button onClick={close} type="button" className="close" data-dismiss="alert" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    )
  }

  if (config) {
    return (
      <BrowserRouter>
        <AlertProvider template={AlertTemplate} {...options}>

          <Header data={subSite}/>
          <Routes data={subSite} parametro={subSiteParam}/>
          <Footer  data={subSite}/>
        </AlertProvider>
      </BrowserRouter>
    );

  }

}

export default inject("ClientStore", "CarrinhoStore", "ProdutoStore", "HeaderStore")(observer(App));

//props para manipulacao de temas direto no Routes