import React, { useEffect, useState } from 'react'
import { inject, observer } from 'mobx-react'
import api from '../../../services/api'
import { closeModal } from '../../../services/helpers'
import ButtonLoading from '../../ButtonLoading'
import TitleCardProduct from '../../TitleCardProduct'
import InputMask from 'react-input-mask'
import { useAlert } from 'react-alert'

import {
  ContainerAlert,
  ContainerInfo,
  ContainerX,
  SpanButton,
  Cadastrado,
  Area,
  AreaBtn,
  VoltarBtn,
} from './styles'

import Loading from '../../Loading'

const ModalRegister = props => {
  const { dados, token, setToken, setDados } = props.ClientStore
  const [name, setName] = useState('')
  const [cpf, setCpf] = useState('')
  const [rg, setRg] = useState('')
  const [email, setEmail] = useState('')
  const [emailInfo, setEmailInfo] = useState('')
  const [checkEmail, setCheckEmail] = useState(null)
  const [cep, setCep] = useState('')
  const [rua, setRua] = useState('')
  const [celular, setCelular] = useState('')
  const [numero, setNumero] = useState('')
  const [complemento, setComplemento] = useState('')
  const [bairro, setBairro] = useState('')
  const [cidade, setCidade] = useState('')
  const [estado, setEstado] = useState('')
  const [senha, setSenha] = useState('')
  const [resenha, setResenha] = useState('')
  const [loading, setLoading] = useState(false)
  const [statusCadastrado, setStatusCadastrado] = useState(false)
  const [statusModal, setStatusModal] = useState(false)
  const alert = useAlert()

  const addAddress = async (id, t) => {
    try {
      //necessita do Endpoint de cadastro do cliente
      console.log('Processo cadastro se inciará')

      let response = await api.post(
        '/endereco',
        {
          cliente_id: id,
          rua,
          cep,
          numero,
          complemento,
          bairro,
          cidade,
          estado,
        },
        {
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + t,
          },
        }
      )
      console.log('responseAddAdress: ', response.data)
      if (!response.data.errors) {
        setCep('')
        setRua('')
        setNumero('')
        setComplemento('')
        setBairro('')
        setCidade('')
        setEstado('')

        setLoading(false)
        props.getMyAddresses()
        return response.data.data.errors
      }
    } catch (error) {
      console.log(error)
    }
  }

  const cadastrar = async () => {
    console.log('aqui')
    if (name == '') {
      alert.show('Preencha o campo nome e sobrenome.', { type: 'info' })
      return
    } else if (cpf == '' || cpf.length < 14) {
      alert.show('Preencha o campo com CPF válido.', { type: 'info' })
      return
    } else if (senha == '' || senha.length < 6) {
      alert.show('Preencha o campo senha com minimo 6 caracteres.', {
        type: 'info',
      })
      return
    } else if (senha != resenha) {
      alert.show('As duas senhas são diferente!', { type: 'info' })
      return
    }

    setLoading(true)
    try {
      let response = await api.post('/cliente', {
        tipo_cliente_id: 2,
        nome: name,
        email,
        telefone: celular,
        cpf,
        password: senha,
      })

      console.log('response: ', response.data)
      if (!response.data.errors) {
        console.log('entrou no response')
        if (response.data.data.token) {
          setToken(response.data.data.token)
          setDados(response.data.data.data)
        }
        let endereco = await addAddress(
          response.data.data.data.id,
          response.data.data.token
        )
        console.log('enderecoStatusFalse: ', endereco)
        if (!endereco) {
          setStatusModal(true)
          alert.show('Cadastror realizado com sucesso!', { type: 'info' })
          setStatusCadastrado(true)
          setLoading(false)
          setName('')
          setCpf('')
          setRg('')
          setEmail('')
          setEmailInfo('')
          setCheckEmail(null)
          setCep('')
          setRua('')
          setCelular('')
          setNumero('')
          setComplemento('')
          setBairro('')
          setCidade('')
          setEstado('')
          setSenha('')
          setResenha('')
        }
      } else {
        console.log('responseError: ', response.data.error)
        alert.show(response.data.message, { type: 'info' })
      }
    } catch (error) {
      setLoading(false)
      console.log('Error: ', error)
      alert.show('Ocorreu um erro ao se cadastrar!', { type: 'error' })
    }
  }

  const isValidEmail = async () => {
    const reg = new RegExp(/^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/)

    if (!reg.test(email)) {
      // nao é um email valido, nao vale a pena perguntar se existe na DB
      setEmail('')
      setCheckEmail(true)
      alert.show('Preencha o campo com e-mail válido.', { type: 'info' })
    }
  }

  async function getCep() {
    try {
      const response = await api.get(
        'https://ceps.sitebeta.com.br/api/endereco?cep=' + cep,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )

      if (!response.data.error) {
        setRua(response.data.data.logradouro)
        setBairro(response.data.data.bairro)
        setCidade(response.data.data.cidade)
        setEstado(response.data.data.uf)
      } else {
        alert.show(response.data.message, { type: 'info' })
      }
    } catch (error) {
      console.log('Error: ', error)
    }
  }

  const celularMask = () => {
    const textoAtual = celular
    let textoAjustado
    const ddd = textoAtual.slice(0, 2)
    const parte1 = textoAtual.slice(2, 7)
    const parte2 = textoAtual.slice(7, 13)

    textoAjustado = `(${ddd}) ${parte1}-${parte2}`
    console.log('celular: ', textoAjustado)

    setCelular(textoAjustado)
  }

  const handleStatusCadastro = () => {
    if (statusCadastrado) {
      setStatusCadastrado(false)
    }
  }

  const handleStatusModal = () => {
    handleStatusCadastro()
    setStatusModal(false)
  }

  useEffect(() => {
    if (checkEmail) {
      setEmailInfo('Email invalido.')
    }
  }, [checkEmail, emailInfo, celular])

  useEffect(() => {}, [dados, statusModal, token, statusCadastrado])

  return (
    <>
      <form
        className="modal fade"
        method="post"
        id="revendedor-register"
        tabIndex="-1"
        noValidate
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            {statusModal ? (
              <>
                <Area>
                  <TitleCardProduct>
                    <div className="col-12 p-3 text-center">
                      <div style={{ padding: '10px' }}>
                        <img
                          src="/img/logo-light-rosa.png"
                          style={{ minWidth: '3.5rem' }}
                          alt=""
                        />
                      </div>

                      <h2 style={{ color: '#002D6D' }}>
                        Cadastro efetuado com sucesso
                      </h2>
                    </div>
                  </TitleCardProduct>

                  <AreaBtn className="container">
                    <VoltarBtn
                      class="back-bnt"
                      onClick={() => handleStatusModal()}
                      data-dismiss="modal"
                    >
                      Fechar
                    </VoltarBtn>
                  </AreaBtn>
                </Area>
              </>
            ) : (
              <>
                <div className="modal-header">
                  <h5 className="modal-title">Cadastrar Revendedor</h5>

                  <button
                    className="close"
                    id="action-close"
                    type="button"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>

                <div className="modal-body">
                  <div className="row">
                    {/**adicionar nome, cpf, rg, celular, e-mail */}
                    <div className="col-sm-12">
                      <div className="form-group">
                        <label htmlFor="address-zip">Nome</label>

                        <InputMask
                          className="form-control"
                          maskChar={null}
                          value={name}
                          onChange={e => setName(e.target.value)}
                          placeholder="Nome Completo"
                        />

                        <div className="invalid-feedback">
                          Preencha este campo.
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-12">
                      <div className="form-group">
                        <label htmlFor="address-zip">Email</label>

                        <InputMask
                          className="form-control"
                          maskChar={null}
                          value={email}
                          onChange={e => setEmail(e.target.value)}
                          placeholder="Email"
                          onBlur={isValidEmail}
                        />

                        {checkEmail == true ? (
                          <div
                            className="col-sm-12 alert alert-danger alert-dismissible"
                            style={{ marginTop: '10px' }}
                            role="alert"
                          >
                            <ContainerAlert>
                              <ContainerInfo>
                                <strong>Atenção!</strong> {emailInfo}
                              </ContainerInfo>

                              <ContainerX>
                                <SpanButton
                                  type="button"
                                  onClick={() => setCheckEmail(false)}
                                >
                                  <span aria-hidden="true">&times;</span>
                                </SpanButton>
                              </ContainerX>
                            </ContainerAlert>
                          </div>
                        ) : null}

                        <div className="invalid-feedback">
                          Preencha este campo.
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="form-group">
                        <label htmlFor="address-zip">CPF</label>

                        <InputMask
                          className="form-control"
                          mask="999.999.999-99"
                          maskChar={null}
                          value={cpf}
                          onChange={e => setCpf(e.target.value)}
                          placeholder="CPF"
                        />

                        <div className="invalid-feedback">
                          Preencha este campo.
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-4">
                      <div className="form-group">
                        <label htmlFor="address-zip">RG</label>

                        <InputMask
                          className="form-control"
                          maskChar={null}
                          value={rg}
                          onChange={e => setRg(e.target.value)}
                          placeholder="RG"
                        />

                        <div className="invalid-feedback">
                          Preencha este campo.
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-4">
                      <div className="form-group">
                        <label htmlFor="address-zip">CEP *</label>

                        <InputMask
                          className="form-control"
                          mask="99999-999"
                          maskChar={null}
                          value={cep}
                          onChange={e => setCep(e.target.value)}
                          placeholder="CEP"
                          onBlur={() => getCep()}
                        />

                        <div className="invalid-feedback">
                          Preencha este campo.
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-12">
                      <div className="form-group">
                        <label htmlFor="address-fn">Endereço *</label>

                        <input
                          className="form-control"
                          type="text"
                          id="address-fn"
                          required
                          value={rua}
                          placeholder="Endereço"
                          onChange={event => setRua(event.target.value)}
                        />

                        <div className="invalid-feedback">
                          Preencha este campo.
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label htmlFor="addressNumber">Número *</label>

                        <input
                          className="form-control"
                          type="text"
                          placeholder="Número"
                          id="addressNumber"
                          required
                          value={numero}
                          onChange={event => setNumero(event.target.value)}
                        />

                        <div className="invalid-feedback">
                          Preencha este campo.
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-6">
                      <div className="form-group">
                        <label htmlFor="phoneNumber">Celular *</label>

                        <input
                          className="form-control"
                          type="text"
                          placeholder="Celular"
                          id="phoneNumber"
                          required
                          value={celular}
                          onChange={event => setCelular(event.target.value)}
                          onBlur={celularMask}
                        />

                        <div className="invalid-feedback">
                          Preencha este campo.
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-12">
                      <div className="form-group">
                        <label htmlFor="address-ln">Complemento</label>

                        <input
                          className="form-control"
                          type="text"
                          placeholder="Complemento"
                          id="address-ln"
                          value={complemento}
                          onChange={event => setComplemento(event.target.value)}
                        />
                      </div>
                    </div>

                    <div className="col-sm-4">
                      <div className="form-group">
                        <label htmlFor="address-company">Bairro *</label>

                        <input
                          className="form-control"
                          type="text"
                          placeholder="Bairro"
                          id="address-company"
                          required
                          value={bairro}
                          onChange={event => setBairro(event.target.value)}
                        />

                        <div className="invalid-feedback">
                          Preencha este campo.
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-4">
                      <div className="form-group">
                        <label htmlFor="city">Cidade *</label>

                        <input
                          className="form-control"
                          type="text"
                          id="city"
                          placeholder="Cidade"
                          required
                          value={cidade}
                          onChange={event => setCidade(event.target.value)}
                        />

                        <div className="invalid-feedback">
                          Preencha este campo.
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-4">
                      <div className="form-group">
                        <label htmlFor="state">Estado *</label>

                        <input
                          className="form-control"
                          type="text"
                          id="state"
                          placeholder="Estado"
                          required
                          value={estado}
                          onChange={event => setEstado(event.target.value)}
                        />

                        <div className="invalid-feedback">
                          Preencha este campo.
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label htmlFor="reg-password">Senha</label>

                        <input
                          value={senha}
                          onChange={e => setSenha(e.target.value)}
                          className="form-control"
                          type="password"
                          required
                          id="reg-password"
                          placeholder="Senha"
                        />

                        <div className="invalid-feedback">
                          Please enter password!
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label htmlFor="reg-password-confirm">
                          Confirme a senha
                        </label>

                        <input
                          value={resenha}
                          onChange={e => setResenha(e.target.value)}
                          className="form-control"
                          type="password"
                          required
                          id="reg-password-confirm"
                          placeholder="Confirme a senha"
                        />

                        <div className="invalid-feedback">
                          Passwords do not match!
                        </div>
                      </div>
                    </div>

                    <div className="col-12 form-group">
                      <small>(*) Campos obrigatórios</small>
                    </div>
                  </div>
                </div>
              </>
            )}
            {statusModal ? (
              <></>
            ) : (
              <>
                <div className="modal-footer">
                  <button
                    className="btn btn-secondary"
                    onClick={() => handleStatusCadastro()}
                    type="button"
                    data-dismiss="modal"
                  >
                    Fechar
                  </button>
                  {statusCadastrado ? (
                    <Cadastrado>Cadastrado com sucesso!</Cadastrado>
                  ) : (
                    <ButtonLoading
                      class="btn btn-primary btn-shadow"
                      onClick={() => cadastrar()}
                      loading={loading}
                      title="Concluir"
                    />
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </form>
      <script></script>
    </>
  )
}

export default inject('ClientStore')(observer(ModalRegister))
