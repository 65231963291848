import React, { useState } from 'react';

import {
    AreaBemVindo,
    Title,
    TextContent,
    Area,
    AreImg,
    ContainerContent
} from './styles';

const  SecondBanner = (props) => {


    return(
        <AreaBemVindo> 
            <Area style={{padding:"10px"}}>
                <AreImg className="col-12 p-2 text-center" >
                    <img src="/img/cadastrar-revendedor/logo-branca.png" />
                </AreImg>
                <ContainerContent>
                    <div style={{width:'70%'}}>
                        <Title>Seja um Consultor de sucesso!</Title>
                        <TextContent>Conquiste sua indepêndencia financeira e conte com suporte e apoio completo da Ronnelly em todo o Brasil. Conheça os benefícios:</TextContent> 
                    </div>
                </ContainerContent>
            </Area>
        </AreaBemVindo>
    );
}

export default SecondBanner;