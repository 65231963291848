import React, { useEffect } from 'react'
import { inject, observer } from 'mobx-react'
import TitleCardProduct from '../components/TitleCardProduct'
import ButtonLoading from '../components/ButtonLoading'
import InputMask from 'react-input-mask'
import subSiteConfig from '../services/subSite'
import { useParams } from 'react-router-dom'

const Contatos = props => {
  const { setSubSite, subSite } = props.HeaderStore
  const { vendedor } = useParams()

  //subsite
  const subSiteLayout = async v => {
    return await subSiteConfig.handleSubsite(v)
  }

  const reqSubsite = async v => {
    let obj = await subSiteConfig.getUrlParameter(v)

    setSubSite(obj)
  }

  useEffect(() => {
    reqSubsite(vendedor)
  }, [])

  useEffect(() => {
    console.log('subSite: ', subSite)

    if (subSite != null) {
      console.log('subSite: ENTROU')

      subSiteLayout(subSite)
    }
  }, [subSite])
  //subsite

  return (
    <>
      <TitleCardProduct>
        <div className="col-12 p-5 text-center">
          <div style={{ padding: '10px', margin: '10px' }}>
            <img
              src="/img/logo-light-rosa.png"
              style={{ minWidth: '3.5rem' }}
            />
          </div>

          <h2>Entre em contato</h2>

          <div className="row text-left" style={{ padding: '10px' }}>
            <div className="col-md-8 col-sm-8 mb-6">
              <form className="needs-validation" noValidate>
                <div className="row">
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label htmlFor="reg-fn">Nome</label>

                      <input
                        className="form-control"
                        type="text"
                        required
                        id="reg-fn"
                      />

                      <div className="invalid-feedback">
                        Please enter your first name!
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-6">
                    <div className="form-group">
                      <label htmlFor="reg-phone">Telefone</label>

                      <InputMask
                        className="form-control"
                        mask="(99) 99999-9999"
                      />

                      <div className="invalid-feedback">
                        Please enter your phone number!
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-12">
                    <div className="form-group">
                      <label htmlFor="reg-email">E-mail</label>

                      <input
                        className="form-control"
                        type="email"
                        required
                        id="reg-email"
                      />

                      <div className="invalid-feedback">
                        Please enter valid email address!
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-12">
                    <div className="form-group">
                      <label htmlFor="reg-email">E-mail</label>

                      <textarea
                        name="mensagem"
                        style={{
                          padding: '10px 12px',
                          color: '#111',
                          borderColor: '#dae1e7',
                          width: '100%',
                          marginBottom: '20px',
                          height: '145px',
                        }}
                      ></textarea>
                    </div>
                  </div>
                </div>

                <div className="text-right">
                  <ButtonLoading title="Enviar" class="btn btn-primary" />
                </div>
              </form>
            </div>

            <div className="col-md-4 col-sm-4 mb-6 ">
              <h6 className="text-center">Contatos</h6>

              <div className="col-md-12 ">
                <div className="media">
                  <i
                    className="czi-phone text-primary"
                    style={{ fontSize: '2.25rem', padding: '5px' }}
                  ></i>

                  <div className="media-body pl-3">
                    <p className="mb-0  opacity-20 text-left">(91) 32335122</p>
                  </div>
                </div>
              </div>

              <div className="col-md-12">
                <div className="media">
                  <i
                    className="czi-mail text-primary "
                    style={{ fontSize: '2.25rem', padding: '5px' }}
                  ></i>

                  <div className="media-body pl-3">
                    <p className="mb-0  opacity-20 text-left">
                      ronnelly@ronnellypara.com.br
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="col-md-12 col-sm-4 mb-12 text-center"
            style={{ borderTop: 'solid 1px #dadbde', padding: '25px' }}
          >
            <div className="mb-3">
              <a
                className="social-btn sb-outline sb-facebook ml-2 mb-2"
                href="https://www.facebook.com/ronnellyjoiasfolheadas/"
              >
                <i className="czi-facebook"></i>
              </a>

              <a
                className="social-btn sb-outline sb-instagram ml-2 mb-2"
                href="https://www.instagram.com/ronnellypara/"
              >
                <i className="czi-instagram"></i>
              </a>
            </div>
          </div>
        </div>
      </TitleCardProduct>
    </>
  )
}
export default inject('HeaderStore')(observer(Contatos))

