import React, { useState, useEffect } from 'react'
import PageTitle from '../components/PageTitle'
import CardCombineProduct from '../components/Checkout/CardCombineProduct'
import api from '../services/api'
import { useParams, Link, useHistory, Redirect } from 'react-router-dom'
import loadjs from 'loadjs'
import { formatNumber } from '../services/helpers'
import Skeleton from 'react-loading-skeleton'
import ButtonLoading from '../components/ButtonLoading'
import InputMask from 'react-input-mask'
import { inject, observer } from 'mobx-react'
import { useAlert } from 'react-alert'
import subSiteConfig from '../services/subSite'

const SingleProduct = props => {
  let history = useHistory()
  const [loading, setLoading] = useState(false)
  const [loadingGif, setLoadingGif] = useState(false)
  const [produto, setProduto] = useState({ marca: {}, url_fotos: [] })
  const [cep, setCep] = useState('')
  const [quantidade, setQuantidade] = useState(1)
  const [fretes, setFretes] = useState([])
  const [fotos, setFotos] = useState([])
  const { id } = useParams()
  const { setProdutoCarrinho } = props.CarrinhoStore
  const [cor, setCor] = useState(null)
  const [estampa, setEstampa] = useState(null)
  const [personalizado, setPersonalizado] = useState(null)
  const [corString, setCorString] = useState(null)
  const [tamanho, setTamanho] = useState(null)
  const [tamanhoString, setTamanhoString] = useState(null)
  const [linkConfig, setLinkConfig] = useState('')
  const [keyCor, setKeyCor] = useState(null)
  const { setFavorito } = props.ProdutoStore
  const alert = useAlert()
  const { token, dados } = props.ClientStore

  const { visible, setVisible, setSubSite, subSite, setSubSiteParam } =
    props.HeaderStore

  let { vendedor, slug } = useParams()

  async function getProduto() {
    try {
      setLoading(true)
      let response = await api.get('/produto/' + id, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        },
      })

      if (response.data.data) {
        setProduto(response.data.data)
        setFotos(response.data.data.url_fotos)
      }

      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const initialConfig = async () => {
    await getProduto()

    loadjs('/js/theme.min.js')
  }

  async function getFretes() {
    try {
      if (cep == '') {
        return
      }

      setLoading(true)

      let response = await api.post(
        '/frete',
        {
          produto_id: produto.id,
          cep_destino: cep,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
          },
        }
      )

      if (response.data.data) {
        if (produto.loja.entrega_gratis == 1) {
          setFretes([
            {
              codigo: 3,
              valor: 0.0,
              prazo: 'Entrega grátis',
              tipo: 'Entrega grátis',
            },
          ])
        } else {
          setFretes(response.data.data)
        }
      }

      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  function adicionarCarrinho() {
    if (produto.tem_cor > 0) {
      if (!cor) {
        alert.show('Selecine a cor.', { type: 'info' })
        return
      }
    }

    if (!produto.tem_cor && produto.detalhe_estoque[0].tamanho) {
      if (!tamanho) {
        alert.show('Selecine o tamanho.', { type: 'info' })
        return
      }
    }

    if (
      produto.detalhe_estoque[keyCor] &&
      produto.detalhe_estoque[keyCor].estoque[0].tamanho
    ) {
      if (!tamanho) {
        alert.show('Selecine o tamanho.', { type: 'info' })
        return
      }
    }

    if (produto.personalizado == '1') {
      if (!personalizado) {
        alert.show('Preencha o campo personalizado.', { type: 'info' })

        return
      }
    }

    setProdutoCarrinho(
      produto,
      quantidade,
      cor,
      tamanho,
      corString,
      tamanhoString,
      estampa,
      personalizado
    )

    alert.show('Produto adicionado com sucesso.', { type: 'success' })
  }

  function selectEstampa(index) {
    setFotos([produto.url_fotos[index]])
  }

  //subsite
  const subSiteLayout = async v => {
    return await subSiteConfig.handleSubsite(v)
  }

  const reqSubsite = async v => {
    let obj = await subSiteConfig.getUrlParameter(v)

    setSubSite(obj)
  }

  useEffect(() => {
    reqSubsite(vendedor)

    console.log('OLA')
  }, [])

  useEffect(() => {
    console.log('subSite: ', subSite)

    if (subSite != null) {
      console.log('subSite: ENTROU')
      setSubSiteParam(subSite)
      subSiteLayout(subSite)
    }

    //handleAvisoEstoque();
  }, [subSite])
  //subsite

  const handleBread = () => {
    if (subSite !== null) {
      if (slug != '' && slug != null && slug.length > 0) {
        return `/site/${subSite.param}/produto/${produto.id}/${slug}`
      } else {
        return `/site/${subSite.param}/produto/${produto.id}`
      }
    } else {
      if (slug != '' && slug != null && slug.length > 0) {
        return `/produto/${produto.id}/${slug}`
      } else {
        return `/produto/${produto.id}`
      }
    }
  }

  useEffect(() => {
    console.log({ dados: subSite != null ? subSite.vendedor_id : 0 })

    initialConfig()

    window.scrollTo(0, 0)
  }, [])

  /*   const handleAvisoEstoque = () => {
    if (token) {
      return "#";
    } else {
      if (subSite !== null) {
        setLinkConfig(`/site/${subSite.param}/entrar`);
      } else {
        setLinkConfig(`/entrar`);
      }
    }
  };
 */

  const handleAviso = async () => {
    console.log({
      cliente_id: dados.id,
      produto_id: produto.id,
      vendedor_id: subSite != null ? subSite.vendedor_id : 0,
    })

    if (token != null) {
      //enviar aviso
      //Integracao
      setLoadingGif(true)

      let body = {
        cliente_id: dados.id,
        produto_id: produto.id,
        vendedor_id: subSite != null ? subSite.vendedor_id : 0,
      }

      // "https://www.ronnellypara.com.br/api/alerta-estoque"

      //console.log({ id: dados.id });
      const req = await fetch(
        `https://www.app.ronnellypara.com.br/api/alerta-estoque`,
        {
          method: 'POST',

          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
          },

          body: JSON.stringify(body),
        }
      )

      const response = await req.json()

      console.log({ response })

      //let rota = "/alerta-estoque";
      /*  let response = await api.post(
        "/alerta-estoque",
        {
          data: JSON.stringify({
            cliente_id: dados.id,
            produto_id: produto.id,
            vendedor_id: subSite != null ? subSite.vendedor_id : 0,
          }),
        },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
 */
      //console.log("Alerta estoque: ", { response });

      if (response.errors) {
        //error

        setLoadingGif(false)

        window.scrollTo(0, 0)

        alert.show(response.message, {
          type: 'error',
        })
      } else {
        //success

        setLoadingGif(false)

        window.scrollTo(0, 0)

        alert.show(response.message, {
          type: 'success',
        })
      }
    } else {
      //nao estar logado
      window.scrollTo(0, 0)

      alert.show(
        '*Atenção você precisa fazer login para solicitar o aviso do produto',
        {
          type: 'error',
        }
      )
    }
  }

  return (
    <>
      <PageTitle
        nameRoute={produto.nome}
        url={subSite != null ? handleBread() : 'produto/' + produto.id}
      />

      <div className="container">
        <div className="bg-light box-shadow-lg rounded-lg">
          <ul className="nav nav-tabs" role="tablist">
            <li className="nav-item">
              <a
                className="nav-link p-4 active"
                href="#general"
                data-toggle="tab"
                role="tab"
              >
                Informações gerais
              </a>
            </li>
          </ul>

          <div className="px-4 pt-lg-3 pb-3 mb-5">
            <div className="tab-content px-lg-3">
              <div
                className="tab-pane fade show active"
                id="general"
                role="tabpanel"
              >
                <div className="row">
                  <div className="col-lg-7 pr-lg-0">
                    {/*
                      atributo de zoon da imagem, basta adicionar na foto abaixo
                        className="cz-image-zoom" data-zoom={foto}
                    */}

                    {fotos[0] ? (
                      <div className="cz-product-gallery">
                        {Object.keys(produto).length && (
                          <div className="cz-preview order-sm-2">
                            {fotos.map((foto, keyFoto) =>
                              !keyFoto ? (
                                <div
                                  key={keyFoto}
                                  className="cz-preview-item active"
                                  id={'foto' + keyFoto}
                                >
                                  <img
                                    style={{ maxWidth: 600 }}
                                    src={foto}
                                    alt=""
                                  />
                                  <div className="cz-image-zoom-pane"></div>
                                </div>
                              ) : (
                                <div
                                  key={keyFoto}
                                  className="cz-preview-item"
                                  id={'foto' + keyFoto}
                                >
                                  <img
                                    style={{ maxWidth: 600 }}
                                    src={foto}
                                    alt=""
                                  />
                                  <div className="cz-image-zoom-pane"></div>
                                </div>
                              )
                            )}
                          </div>
                        )}

                        {Object.keys(produto).length && (
                          <div className="cz-thumblist order-sm-1">
                            {fotos.map((foto, keyFoto) =>
                              !keyFoto ? (
                                <>
                                  {subSite != null ? (
                                    <a
                                      key={keyFoto}
                                      className="cz-thumblist-item active"
                                      style={{ borderColor: subSite.cor }}
                                      href={'#foto' + keyFoto}
                                    >
                                      <img src={foto} alt="Product thumb" />
                                    </a>
                                  ) : (
                                    <a
                                      key={keyFoto}
                                      className="cz-thumblist-item active"
                                      href={'#foto' + keyFoto}
                                    >
                                      <img src={foto} alt="Product thumb" />
                                    </a>
                                  )}
                                </>
                              ) : (
                                <a
                                  key={keyFoto}
                                  className="cz-thumblist-item"
                                  href={'#foto' + keyFoto}
                                >
                                  <img src={foto} alt="Product thumb" />
                                </a>
                              )
                            )}
                          </div>
                        )}
                      </div>
                    ) : (
                      <Skeleton height={200} width="100%" />
                    )}
                  </div>
                  <div className="col-lg-5 pt-4 pt-lg-0">
                    <div className="product-details ml-auto pb-3">
                      <h5 className="mb-0">{produto.nome}</h5>

                      <br />

                      <div className="h5 font-weight-normal text-accent mb-3 mr-1">
                        {produto.preco ? (
                          'R$' +
                          formatNumber(
                            produto.desconto.length
                              ? produto.preco_final
                              : produto.preco
                          )
                        ) : (
                          <Skeleton height={30} width="70%" />
                        )}

                        {produto.preco_final && produto.desconto.length > 0 && (
                          <>
                            <del className="text-muted font-size-lg mr-3">
                              {' '}
                              {formatNumber(produto.preco)}
                            </del>
                          </>
                        )}
                        <br />
                        <br />

                        <div
                          style={{
                            color: '#717177',
                            fontSize: '14px',
                          }}
                        >
                          {console.log(
                            'Buscando codigo: ',
                            produto.qtd_estoque
                          )}

                          {produto.codigo && produto.codigo != null ? (
                            <p
                              style={{
                                fontSize: '12px',
                              }}
                            >
                              Codigo: {produto.codigo}
                            </p>
                          ) : (
                            <></>
                          )}

                          {produto.descricao ? (
                            <span
                              style={{ color: '#2f2f33' }}
                              dangerouslySetInnerHTML={{
                                __html: produto.descricao,
                              }}
                            ></span>
                          ) : null}
                          {produto.altura && produto.comprimento ? (
                            <p>
                              {produto.altura}cm x {produto.comprimento}cm
                            </p>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>

                      {/* <div className="font-size-sm mb-4"><span className="text-heading font-weight-medium mr-1">Marca:</span><span className="text-muted">{produto.marca.nome ? produto.marca.nome : <Skeleton height={15} width='65%' />}</span></div> */}

                      {produto.detalhe_estoque && (
                        <>
                          <div className="position-relative mr-n4 mb-3">
                            {produto.tem_cor &&
                              produto.detalhe_estoque.map((item, key) => (
                                <div
                                  className="custom-control custom-option custom-control-inline mb-2"
                                  key={item.id}
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={item.nome_cor}
                                >
                                  <input
                                    className="custom-control-input"
                                    type="radio"
                                    name="color"
                                    id={item.id}
                                    value={item.id}
                                    onChange={e => {
                                      setCor(e.target.value)
                                      setKeyCor(key)
                                      setCorString(item.nome_cor)
                                      setTamanho(null)
                                    }}
                                    checked={cor == item.id ? true : false}
                                  />

                                  <label
                                    className="custom-option-label rounded-circle"
                                    htmlFor={item.id}
                                  >
                                    <span
                                      className="custom-option-color rounded-circle"
                                      style={{ backgroundColor: item.hex_cor }}
                                    ></span>
                                  </label>
                                </div>
                              ))}
                          </div>

                          {(cor || !produto.tem_cor) && (
                            <div className="form-group">
                              {produto.detalhe_estoque[keyCor] &&
                                produto.detalhe_estoque[keyCor].estoque[0]
                                  .tamanho && (
                                  <>
                                    <div className="d-flex justify-content-between align-items-center pb-1">
                                      <label
                                        className="font-weight-medium"
                                        htmlFor="product-size"
                                      >
                                        Tamanho:
                                      </label>
                                    </div>

                                    <select
                                      onChange={e => {
                                        setTamanho(e.target.value)
                                        setTamanhoString(
                                          e.target.selectedOptions[0].getAttribute(
                                            'tamanho'
                                          )
                                        )
                                      }}
                                      className="custom-select"
                                      required
                                      id="product-size"
                                    >
                                      <option value="">Selecione</option>
                                      {produto.detalhe_estoque[
                                        keyCor
                                      ].estoque.map((item, key) => (
                                        <option
                                          tamanho={item.tamanho}
                                          value={key}
                                          key={item.estoque_id}
                                          disabled={
                                            item.quantidade > 0 ? false : true
                                          }
                                        >
                                          {item.quantidade > 0
                                            ? item.tamanho
                                            : item.tamanho + ' -- ESGOTADO'}
                                        </option>
                                      ))}
                                    </select>
                                  </>
                                )}

                              {!produto.tem_cor &&
                                produto.detalhe_estoque[0].tamanho && (
                                  <>
                                    <div className="d-flex justify-content-between align-items-center pb-1">
                                      <label
                                        className="font-weight-medium"
                                        htmlFor="product-size"
                                      >
                                        Tamanho:
                                      </label>
                                    </div>

                                    <select
                                      onChange={e => {
                                        setTamanho(e.target.value)
                                        setTamanhoString(
                                          e.target.selectedOptions[0].getAttribute(
                                            'tamanho'
                                          )
                                        )
                                      }}
                                      className="custom-select"
                                      required
                                      id="product-size"
                                    >
                                      <option value="">Selecione</option>
                                      {produto.detalhe_estoque.map(
                                        (item, key) => (
                                          <option
                                            tamanho={item.tamanho}
                                            value={key}
                                            key={item.estoque_id}
                                            disabled={
                                              item.quantidade > 0 ? false : true
                                            }
                                          >
                                            {item.quantidade > 0
                                              ? item.tamanho
                                              : item.tamanho + ' -- ESGOTADO'}
                                          </option>
                                        )
                                      )}
                                    </select>
                                  </>
                                )}
                            </div>
                          )}
                        </>
                      )}

                      {produto.estampas_data &&
                        produto.estampas_data.length > 0 && (
                          <>
                            <div className="d-flex justify-content-between align-items-center pb-1">
                              <label
                                className="font-weight-medium"
                                htmlFor="product-size"
                              >
                                Estampas: {estampa}
                              </label>
                            </div>

                            <div className="position-relative mr-n4 mb-3">
                              {produto.estampas_data.map((item, key) => (
                                <div
                                  className="custom-control custom-option custom-control-inline mb-2"
                                  key={item.id}
                                >
                                  <input
                                    className="custom-control-input"
                                    type="radio"
                                    name="color"
                                    id={item.id}
                                    value={item.titulo}
                                    onChange={e => setEstampa(e.target.value)}
                                    checked={
                                      estampa == item.titulo ? true : false
                                    }
                                  />

                                  <label
                                    className="custom-option-label rounded-circle"
                                    style={{
                                      width: 50,
                                      height: 50,
                                      padding: 5,
                                    }}
                                    htmlFor={item.titulo}
                                  >
                                    <div
                                      onClick={() => {
                                        setEstampa(item.titulo)
                                        selectEstampa(key)
                                      }}
                                      className="rounded-circle"
                                      style={{
                                        background: `url(${item.url_foto}) no-repeat center top / cover`,
                                        height: '100%',
                                      }}
                                    ></div>
                                  </label>
                                </div>
                              ))}
                            </div>
                          </>
                        )}

                      {produto.personalizado == '1' && (
                        <div className="form-group">
                          <div className="d-flex justify-content-between align-items-center pb-1">
                            <label
                              className="font-weight-medium"
                              htmlFor="product-size"
                            >
                              Espaço para personalização:
                            </label>
                          </div>

                          <input
                            type="text"
                            onChange={e => setPersonalizado(e.target.value)}
                            className="form-control prepended-form-control"
                          />
                        </div>
                      )}

                      {produto.preco ? (
                        <>
                          <div className="d-flex align-items-center pt-2 pb-4">
                            {produto.tem_cor &&
                              produto.detalhe_estoque[keyCor] &&
                              produto.tem_cor &&
                              produto.detalhe_estoque[keyCor].estoque[
                              tamanho
                              ] &&
                              produto.detalhe_estoque[keyCor].estoque[tamanho]
                                .quantidade > 0 && (
                                <select
                                  className="custom-select mr-3"
                                  value={quantidade}
                                  onChange={e => setQuantidade(e.target.value)}
                                  style={{ width: '5rem' }}
                                >
                                  {Array(
                                    produto.detalhe_estoque[keyCor].estoque[
                                      tamanho
                                    ].quantidade <= 20
                                      ? parseInt(
                                        produto.detalhe_estoque[keyCor]
                                          .estoque[tamanho].quantidade
                                      )
                                      : 20
                                  )
                                    .fill()
                                    .map((item, key) => (
                                      <option key={key} value={key + 1}>
                                        {key + 1}
                                      </option>
                                    ))}
                                </select>
                              )}

                            {!produto.tem_cor &&
                              !produto.detalhe_estoque[0].tamanho && (
                                <select
                                  className="custom-select mr-3"
                                  value={quantidade}
                                  onChange={e => setQuantidade(e.target.value)}
                                  style={{ width: '5rem' }}
                                >
                                  {Array(
                                    produto.detalhe_estoque[0].quantidade <= 20
                                      ? parseInt(
                                        produto.detalhe_estoque[0].quantidade
                                      )
                                      : 20
                                  )
                                    .fill()
                                    .map((item, key) => (
                                      <option key={key} value={key + 1}>
                                        {key + 1}
                                      </option>
                                    ))}
                                </select>
                              )}

                            {!produto.tem_cor &&
                              produto.detalhe_estoque[tamanho] && (
                                <select
                                  className="custom-select mr-3"
                                  value={quantidade}
                                  onChange={e => setQuantidade(e.target.value)}
                                  style={{ width: '5rem' }}
                                >
                                  {Array(
                                    produto.detalhe_estoque[tamanho]
                                      .quantidade <= 20
                                      ? parseInt(
                                        produto.detalhe_estoque[tamanho]
                                          .quantidade
                                      )
                                      : 20
                                  )
                                    .fill()
                                    .map((item, key) => (
                                      <option key={key} value={key + 1}>
                                        {key + 1}
                                      </option>
                                    ))}
                                </select>
                              )}
                            {produto.qtd_estoque ? (
                              <button
                                onClick={() => adicionarCarrinho()}
                                className="btn btn-primary btn-shadow btn-block btn-add"
                                style={
                                  subSite != null
                                    ? { backgroundColor: subSite.cor }
                                    : {}
                                }
                                type="button"
                              >
                                <i className="czi-cart font-size-lg mr-2"></i>
                                Adicionar
                              </button>
                            ) : (
                              <div
                                style={{
                                  width: '100%',
                                  flexDirection: 'row',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                }}
                              >
                                {loadingGif ? (
                                  <div className="d-flex justify-content-center">
                                    <img
                                      style={{
                                        width: 25,
                                        height: 25,
                                      }}
                                      src="/img/loadingGif.gif"
                                      alt=""
                                    />
                                  </div>
                                ) : (
                                  <>
                                    <Link
                                      className="text-center"
                                      style={{
                                        fontSize: '14px',
                                        textAlign: 'center',
                                        color: '#002E6E',
                                        textDecoration: 'underline',
                                        textDecorationColor: '#002E6E',
                                      }}
                                      onClick={() => handleAviso()}
                                    //to={linkConfig}
                                    >
                                      <strong>Sem estoque</strong>, Para ser
                                      avisado quando estiver disponivel,{' '}
                                      <i>clique aqui</i>...
                                    </Link>
                                  </>
                                )}
                              </div>
                            )}
                          </div>
                          <div className="d-flex mb-4">
                            <div className="w-100 mr-3">
                              <button
                                className="btn btn-secondary btn-block"
                                type="button"
                                onClick={() => setFavorito(produto)}
                              >
                                <i className="czi-heart font-size-lg mr-2"></i>

                                <span className="d-none d-sm-inline">
                                  Adicionar aos{' '}
                                </span>
                                Favoritos
                              </button>
                            </div>
                          </div>

                          {/* {produto.loja.taxa_entrega == 1 &&
                            <div className="accordion mb-4" id="productPanels">
                              <div className="card">
                                <div className="card-header">
                                  <h3 className="accordion-heading"><a href="#shippingOptions" role="button" data-toggle="collapse" aria-expanded="true" aria-controls="shippingOptions"><i className="czi-delivery text-muted lead align-middle mt-n1 mr-2"></i>Frete<span className="accordion-indicator"><i data-feather="chevron-up"></i></span></a></h3>
                                </div>
                                <div className="collapse show" id="shippingOptions" data-parent="#productPanels">
                                  <div className="card-body font-size-sm">

                                    {fretes.map((fret) =>
                                      <div key={fret.codigo} className="d-flex justify-content-between border-bottom pb-2">
                                        <div>
                                          <div className="font-weight-semibold text-dark">{fret.prazo}</div>
                                        </div>
                                        <div>R${formatNumber(fret.valor)}</div>
                                      </div>
                                    )}


                                    <form className="needs-validation" noValidate>
                                      <div className="form-group">
                                        <InputMask className="form-control" mask="99999-999" maskChar={null} value={cep} onChange={(e) => setCep(e.target.value)} placeholder="CEP" />
                                        <div className="invalid-feedback">
                                          Por favor, adicione um CEP válido.
                                      </div>
                                      </div>
                                      <ButtonLoading class="btn btn-outline-primary btn-block" onClick={() => getFretes()} loading={loading} title="Calcular Frete" />
                                    </form>
                                  </div>
                                </div>
                              </div>
                            </div>
                          } */}
                        </>
                      ) : (
                        <Skeleton height={100} width="100%" />
                      )}

                      <h6 className="d-inline-block align-middle font-size-base my-2 mr-2"></h6>

                      {/*<a className="share-btn sb-twitter mr-2 my-2" href="/"><i className="czi-twitter"></i>Twitter</a>-->*/}
                      <a
                        className="share-btn sb-instagram mr-2 my-2"
                        href="https://www.instagram.com/ronnellypara/"
                        target="_blank"
                      >
                        <i className="czi-instagram"></i>Instagram
                      </a>

                      <a
                        className="share-btn sb-facebook my-2"
                        href="https://www.facebook.com/ronnellyjoiasfolheadas/"
                        target="_blank"
                      >
                        <i className="czi-facebook"></i>Facebook
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container pt-lg-3 pb-4 pb-sm-5"></div>

      <hr className="pb-5" />

      {produto.produtos_similares && produto.produtos_similares.length > 0 && (
        <div className="container pt-lg-2 pb-5 mb-md-3">
          <p className="text-center pb-4">Produtos que você pode gostar</p>

          <div className="cz-carousel cz-controls-static cz-controls-outside">
            <div
              className="cz-carousel-inner"
              data-carousel-options='{"items": 2, "controls": true, "nav": false, "autoHeight": true, "responsive": {"0":{"items":1},"500":{"items":2, "gutter": 18},"768":{"items":3, "gutter": 20}, "1100":{"items":4, "gutter": 30}}}'
            >
              {produto.produtos_similares.map((item, index) => (
                <div key={index}>
                  <CardCombineProduct item={item} />
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default inject(
  'CarrinhoStore',
  'ProdutoStore',
  'ClientStore',
  'HeaderStore'
)(observer(SingleProduct))
