import React from "react";
import TitleCardProduct from "../components/TitleCardProduct";
import { inject, observer } from "mobx-react";

const Obrigado = (props) => {
  const { cleanCart } = props.CarrinhoStore;

  return (
    <>
      <TitleCardProduct>
        <div className="col-12 p-5 text-center">
          <i className="h2 text-body czi-heart"></i>
          <h2>Muito obrigado por comprar conosco!</h2>
          <a
            className="bnt bnt-primary"
            href="/pedidos"
            onClick={() => cleanCart()}
          >
            Acompanhe seu pedido
          </a>
        </div>
      </TitleCardProduct>
    </>
  );
};

export default inject("ClientStore", "HeaderStore")(observer(Obrigado));
