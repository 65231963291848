import React, { useState, useEffect } from 'react'
import PageTitle from '../components/PageTitle'
import AsideProfile from '../components/Profile/AsideProfile'
import { inject, observer } from 'mobx-react'
import OrderItem from '../components/Profile/OrderItem'
import ModalOrders from '../components/Profile/ModalOrders'
import api from '../services/api'
import Toolbar from '../components/Profile/Toolbar'
import Skeleton from 'react-loading-skeleton'
import ModalReclamar from '../components/Profile/ModalReclamar'
import { useParams } from 'react-router-dom'
import { useAlert } from 'react-alert'
import subSiteConfig from '../services/subSite'

const Orders = props => {
  const { dados, token } = props.ClientStore
  const { setSubSite, subSite, setSubSiteParam } = props.HeaderStore
  const { vendedor } = useParams()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState({ andamento: [], anterior: [] })
  const [idPedido, setIdPedido] = useState(null)
  const [dataModal, setDataModal] = useState([])

  async function getOrders() {
    try {
      setLoading(true)
      let response = await api.get('/pedido', {
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer ' + token,
        },
      })

      if (response.data.data) {
        setData(response.data.data)
      }

      setLoading(false)
    } catch (error) {
      console.log(error)
      setLoading(false)
      alert.show(error.message, { type:'error'})
    }
  }

  useEffect(() => {
    if (token) {
      getOrders()
    }
  }, [token])

  //subsite
  const subSiteLayout = async v => {
    return await subSiteConfig.handleSubsite(v)
  }

  const reqSubsite = async v => {
    let obj = await subSiteConfig.getUrlParameter(v)

    setSubSite(obj)
  }

  useEffect(() => {
    reqSubsite(vendedor)
  }, [])

  useEffect(() => {
    console.log('subSite: ', subSite)

    if (subSite != null) {
      console.log('subSite: ENTROU')

      setSubSiteParam(subSite)
      subSiteLayout(subSite)
    }
  }, [subSite])
  //subsite

  return (
    <>
      <ModalReclamar id={idPedido} token={token} />

      <PageTitle
        nameRoute="Pedidos"
        url={subSite != null ? `/site/${subSite.param}/pedidos` : '/pedidos'}
        title="Minhas Compras"
      />

      <div className="container pb-5 mb-2 mb-md-3">
        <div className="row">
          {/* <!-- Sidebar--> */}
          <AsideProfile type="orders" name={dados.nome} email={dados.email} />
          {/* <!-- Content  --> */}

          <section className="col-lg-8">
            {/* <!-- Toolbar--> */}
            <Toolbar title="Confira suas compras:" />

            <ul className="nav nav-tabs nav-fill" role="tablist">
              <li className="nav-item">
                <a
                  href="#home1"
                  className="nav-link active"
                  data-toggle="tab"
                  role="tab"
                >
                  <i className="czi-delivery mr-2"></i>
                  Em andamento
                </a>
              </li>

              <li className="nav-item">
                <a
                  href="#profile1"
                  className="nav-link"
                  data-toggle="tab"
                  role="tab"
                >
                  <i className="czi-thumb-up mr-2"></i>
                  Anteriores
                </a>
              </li>
            </ul>

            <div className="tab-content">
              <div
                className="tab-pane fade show active"
                id="home1"
                role="tabpanel"
              >
                <div className="table-responsive font-size-md">
                  <table className="table table-hover mb-0">
                    <thead>
                      <tr>
                        <th>Compra</th>
                        <th>Data da compra</th>
                        <th>Status</th>
                        <th>Valor Total</th>
                        <th>Opções</th>
                      </tr>
                    </thead>

                    <tbody>
                      {data.andamento.map((item, index) => (
                        <OrderItem
                          key={index}
                          idOrder={item.id}
                          dateOrder={item.created_at}
                          status={item.status.situacao}
                          cor={item.status.cor}
                          value={item.valor_total}
                          data={item}
                          setDataModal={setDataModal}
                          setReclamar={() => setIdPedido(item.id)}
                        />
                      ))}

                      {loading && (
                        <>
                          <tr>
                            <td className="py-3">
                              <Skeleton width="100%" height="45px" />
                            </td>

                            <td className="py-3">
                              <Skeleton width="100%" height="45px" />
                            </td>

                            <td className="py-3">
                              <Skeleton width="100%" height="45px" />
                            </td>

                            <td className="py-3">
                              <Skeleton width="100%" height="45px" />
                            </td>

                            <td className="py-3">
                              <Skeleton width="100%" height="45px" />
                            </td>
                          </tr>

                          <tr>
                            <td className="py-3">
                              <Skeleton width="100%" height="45px" />
                            </td>

                            <td className="py-3">
                              <Skeleton width="100%" height="45px" />
                            </td>

                            <td className="py-3">
                              <Skeleton width="100%" height="45px" />
                            </td>

                            <td className="py-3">
                              <Skeleton width="100%" height="45px" />
                            </td>

                            <td className="py-3">
                              <Skeleton width="100%" height="45px" />
                            </td>
                          </tr>
                        </>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="tab-pane fade" id="profile1" role="tabpanel">
                <div className="table-responsive font-size-md">
                  <table className="table table-hover mb-0">
                    <thead>
                      <tr>
                        <th>Compra</th>
                        <th>Data da compra</th>
                        <th>Status</th>
                        <th>Valor Total</th>
                        <th>Opções</th>
                      </tr>
                    </thead>

                    <tbody>
                      {data.anterior.map((item, index) => (
                        <OrderItem
                          key={index}
                          idOrder={item.id}
                          dateOrder={item.created_at}
                          status={item.status.situacao}
                          cor={item.status.cor}
                          value={item.valor_total}
                          data={item}
                          setDataModal={setDataModal}
                          setReclamar={() => setIdPedido(item.id)}
                        />
                      ))}

                      {loading && (
                        <>
                          <tr>
                            <td className="py-3">
                              <Skeleton width="100%" height="45px" />
                            </td>

                            <td className="py-3">
                              <Skeleton width="100%" height="45px" />
                            </td>

                            <td className="py-3">
                              <Skeleton width="100%" height="45px" />
                            </td>

                            <td className="py-3">
                              <Skeleton width="100%" height="45px" />
                            </td>

                            <td className="py-3">
                              <Skeleton width="100%" height="45px" />
                            </td>
                          </tr>

                          <tr>
                            <td className="py-3">
                              <Skeleton width="100%" height="45px" />
                            </td>

                            <td className="py-3">
                              <Skeleton width="100%" height="45px" />
                            </td>

                            <td className="py-3">
                              <Skeleton width="100%" height="45px" />
                            </td>

                            <td className="py-3">
                              <Skeleton width="100%" height="45px" />
                            </td>

                            <td className="py-3">
                              <Skeleton width="100%" height="45px" />
                            </td>
                          </tr>
                        </>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            {/* <!-- Orders list--> */}

            <hr className="pb-4" />
          </section>
        </div>
      </div>

      <ModalOrders dataOrder={dataModal} />
    </>
  )
}

export default inject('ClientStore', 'HeaderStore')(observer(Orders))

