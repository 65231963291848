import styled from 'styled-components';



export const AreaBemVindo = styled.div`
    justify-content: center;
    align-items:center;
    padding: 30px;

    .back-bnt:hover {
        
        color: #FFFFFF !important;
        background: #eee;
    }

    @media only screen and (max-width: 770px)  {
        div {
            font-size: 15px;
        }
    }
`;

export const AreaBemVindoText = styled.div`
    color: #002D6D; 
    
`;

export const Area =  styled(AreaBemVindo)` 
    display: flex; 
    text-align: center;  
`;

export const VoltarBtn = styled.button`
    width: 343px;
    height: 60px;
    background: #ECF2F7 0% 0% no-repeat padding-box;
    border-radius: 8px;
    opacity: 1;
    border: none;
    color: #002D6D;
    box-shadow: 0 0.5rem 1.125rem -0.5rem rgb(0 46 110 / 90%);

    

`;

export const LogoImg = styled.img`
    width: 221px;
    height: 47px;
`;

export const GoldenArrow = styled.img`
    width: 20px;
    height: 20px;
    margin-left: 5px;
`;

