import styled from 'styled-components';

export const SpanButton = styled.button`
    background: transparent;
    border: none;
    
`;

export const ContainerAlert = styled.div`
    display: flex;
    flex-direction: row;
`;

export const ContainerInfo = styled.div`
    width: 50%;
`;


export const ContainerX = styled.div`
    width: 50%;
    display: flex;
    justify-content: flex-end;

    span {
        font-size: 1rem !important;
        color: #e04167 !important;
    }
`;