import React, { useState, useEffect } from 'react';
import PageTitle from '../components/PageTitle';
import NavigationMobile from '../components/Checkout/NavigationMobile';
import AsideCheckout from '../components/Checkout/AsideCheckout';
import NavigationDesktop from '../components/Checkout/NavigationDesktop';
import Step from '../components/Checkout/Step';
import TypeAddress from '../components/Checkout/TypeAddress';
import { inject, observer } from 'mobx-react';
import api from '../services/api';
import { useAlert } from 'react-alert';
import Skeleton from 'react-loading-skeleton';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import ModalAddress from '../components/Profile/ModalAddress';
import {
  useParams
} from "react-router-dom";
import subSiteConfig from '../services/subSite';


const CheckoutDetail = (props) => {

  const [enderecos, setEnderecos] = useState([]);
  let history = useHistory();
  const { dados, token } = props.ClientStore;
  const [loading, setLoading] = useState(false);
  const { endereco_id, setEnderecoId, setObjectEndereco } = props.PedidoStore;
  const { produtos } = props.CarrinhoStore;
  const { visible, setVisible, setSubSite, subSite, setSubSiteParam } = props.HeaderStore;
  let { vendedor } = useParams();

  const alert = useAlert();


  const changeCheck = (e) => {
    let index = _.findIndex(enderecos, function (o) { return o.id == e.target.htmlFor; });
    setEnderecoId(e.target.htmlFor);
    setObjectEndereco(enderecos[index]);
  }

  async function getEnderecos() {
    setLoading(true);
    setEnderecos([]);
    try {
      let response = await api.get('/endereco', {
        headers: {
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + token
        }
      });
      setLoading(false);
      if (response.data.data) {
        setEnderecos(response.data.data);
      }
    } catch (error) {
      console.log(error)
      setLoading(false);
      alert.show(error.message, { type: 'error' });
    }
  }

  useEffect(() => {
    getEnderecos();

    window.scrollTo(0, 0)
  }, []);


  //subsite
  const subSiteLayout = async (v) => {
    return await subSiteConfig.handleSubsite(v)

  }

  const reqSubsite = async (v) => {
    let obj = await subSiteConfig.getUrlParameter(v)
    setSubSite(obj);
  }

  useEffect(() => {

    reqSubsite(vendedor)

  }, [])

  useEffect(() => {


    console.log("subSite: ", subSite)
    if (subSite != null) {
      console.log("subSite: ENTROU")
      setSubSiteParam(subSite)
      subSiteLayout(subSite)
      if (!produtos.length) {
        alert.show('Seu carrinho está vazio, escolha algum produto!', { type: 'info' });
        history.push(`/site/${subSite.param}/carrinho`);
      }

    } else {
      if (!produtos.length) {
        alert.show('Seu carrinho está vazio, escolha algum produto!', { type: 'info' });
        history.push('/carrinho');
      }

    }

  }, [subSite])
  //subsite


  return (
    <>
      <ModalAddress getMyAddresses={getEnderecos} />
      <PageTitle nameRoute="Detalhes" url={subSite != null ? `/site/${subSite.param}/checkout-detalhes` : '/checkout-detalhes'} />

      <div className="container pb-5 mb-2 mb-md-4">
        <div className="row">
          <section className="col-lg-8">

            <Step numberCurrent="2" />

            <div className="d-sm-flex justify-content-between align-items-center bg-secondary p-4 rounded-lg mb-grid-gutter">
              <div className="media align-items-center">
                <div className="media-body pl-3">
                  <h3 className="font-size-base mb-0">{dados.nome}</h3><span className="text-accent font-size-sm">{dados.email}</span>
                </div>
              </div><a className="btn btn-light btn-sm btn-shadow mt-3 mt-sm-0" href="#add-address" data-toggle="modal"><i className="czi-edit mr-2"></i>Adicionar Endereço</a>
            </div>

            <h2 className="h6 pb-3 mb-2">
              Escolha o endereço
            </h2>
            <div className="table-responsive">
              <table className="table table-hover font-size-sm border-bottom">
                <thead>
                  <tr>
                    <th className="align-middle"></th>
                    <th className="align-middle">Endereço</th>
                    <th className="align-middle">Cidade</th>
                    <th className="align-middle">Estado</th>
                  </tr>
                </thead>
                <tbody>
                  {(enderecos.length > 0) &&
                    enderecos.map((item) =>
                      <TypeAddress
                        key={item.id}
                        checked={endereco_id == item.id ? true : false}
                        change={(e) => changeCheck(e)}
                        value={item.id}
                        name={item.rua}
                        city={item.cidade}
                        state={item.estado}
                        description={`Nº ${item.numero}, ${item.bairro}`}
                      />
                    )
                  }
                </tbody>
              </table>
            </div>

            {loading &&
              <>
                <Skeleton height={50} width="100%" />
                <Skeleton height={50} width="100%" />
                <Skeleton height={50} width="100%" />
              </>
            }

            {(!enderecos.length && !loading) &&
              <div className="text-center">
                <span>Nenhum endereço cadastrado.</span>
              </div>
            }


            <NavigationDesktop
              linkNext={subSite != null ? `/site/${subSite.param}/checkout-endereco` : '/checkout-endereco'}
              linkBack={subSite != null ? `/site/${subSite.param}/carrinho` : '/carrinho'}
              textBack='Voltar ao carrinho'
              textNext='Prosseguir para entrega'
            />

          </section>

          <AsideCheckout />

        </div>
        <NavigationMobile
          linkNext={subSite != null ? `/site/${subSite.param}/checkout-endereco` : '/checkout-endereco'}
          linkBack={subSite != null ? `/site/${subSite.param}/carrinho` : '/carrinho'}
          textBack='Voltar ao carrinho'
          textNext='Prosseguir para entrega'
        />

      </div>
    </>
  );
}

export default inject("ClientStore", "PedidoStore", "CarrinhoStore", "HeaderStore")(observer(CheckoutDetail));