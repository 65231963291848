import React, {useEffect} from 'react';
import { inject, observer } from 'mobx-react';
import GoogleMapReact from 'google-map-react';
 
import TitleCardProduct from '../components/TitleCardProduct';
import subSiteConfig from '../services/subSite';
import {
  useParams
} from "react-router-dom";


/**
 * 
 *<Marker onClick={this.onMarkerClick}
    name={'Current location'} />

    -1.4245899609787491, 
 */

const OndeEncontrar = (props) => {

    const { vendedor } = useParams();
    const { setSubSite, subSite } = props.HeaderStore;
 
    const ApiKey = process.env.React_App_APIKEY_GOOGLE_MAPS;
    const markName = {name:"Ronnelly Joias e Folheados"};

    const CustomMarker =  ({text}) =>  <div className="custom-marker"><img src="/img/logo-light-rosa.png" title={text.name} width="30px"/></div>;
    const GoogleMapsMarkers = <CustomMarker lat={-1.4245899609787491} lng={-48.469783488692606} text={markName} />;


    //subsite
        const subSiteLayout = async (v) => {
            return await subSiteConfig.handleSubsite(v)

        }

        const reqSubsite = async (v) => {
            let obj = await subSiteConfig.getUrlParameter(v)
            setSubSite(obj);
        }


        useEffect(() => {
            
            reqSubsite(vendedor)

        },[]) 

        useEffect(() => {
            console.log("subSite: ", subSite)
            if(subSite!=null) {
                console.log("subSite: ENTROU")
                subSiteLayout(subSite)
            }

        },[subSite]) 
    //subsite



    
   
    


 

    return (
        <>
            <TitleCardProduct>
                <div className="col-12 p-2 text-center">
                    <div style={{padding: "10px", margin: "10px"}}>
                        <img src="/img/logo-light-rosa.png" style={{minWidth: "3.5rem"}}/>
                    </div>
                    <h2>Encontre - nos</h2>
                    <div className="col-12 p-2 text-left " style={{color: "#727176"}}>
                        <h6 style={{color: "#727176"}}><strong>Endereço:</strong> Av. Pedro Miranda, 1786 - Pedreira, Belém - PA, 66080-000</h6>
                        <p><strong>Funcionamente:</strong> 9:00 - 18:00 de Segunda-feira a Sexta-feira, aos Sabados de 9:00 as 14:00</p>
                    </div>
                    <div style={{border: "solid 1px #001e48", borderRadius: "10px"}}>
                        <div className="col-12 p-2" style={{width:"100%",height:"600px"}}>
                        <GoogleMapReact
                            defaultCenter={{lat: -1.4245899609787491,lng: -48.469783488692606}}
                            defaultZoom={17}
                            bootstrapURLKeys={{
                            key: ApiKey,
                            language: 'pt-br'
                            }}
                        >
                            {GoogleMapsMarkers}
                        </GoogleMapReact>
                        </div>  
                    </div>
                </div>
            </TitleCardProduct>
        </>
    );
}
export default inject("HeaderStore")(observer(OndeEncontrar));