import React from 'react';

const TypeShipping = ({ name, description, term, price, checked, change, value, type }) => {



  return (
    <tr>
      <td >
        <div className="custom-control custom-radio mb-4 d-flex align-items-center">
          <input value={value} checked={checked} onChange={change} className="custom-control-input" type="radio" name={"shipping-method"} />
          <label onClick={change} className="custom-control-label" htmlFor={value}></label>
        </div>
      </td>
      <td className="align-middle">

        {console.log("Tipo: ", type.toUpperCase())}
        {type.toUpperCase()}
      </td>
      <td className="align-middle">

        {description === 'Retirar na Loja' ? '' : <span className="text-dark font-weight-medium">{description}  </span>}

        <span className="text-muted">
          {name}
        </span>
      </td>
      <td className="align-middle">
        R$ {price}
      </td>
    </tr>
  );
}

export default TypeShipping;