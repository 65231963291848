export const Data = [
    {
        title:"Lançamentos constantes",
        body:"Semijoias que acompanham tendências e inspiram. Lançamentos constantes que se encaixam em todos os estilos."
    },
    {
        title:"Semijoias de alto padrão",
        body:"Todas as nossas semijoias são antialérgicas, pois não possuem o níquel em sua composição. Além de serem aplicadas mais camadas de ouro do que as demais empresas do mercado."
    },
    {
        title:"Garantia",
        body:"Todas as semijoias acompanham certificados de garantia, garantindo sua legitimidade e qualidade."
    },
    {
        title:"Suporte",
        body:"Oferecemos o material de suporte personalizados, para que suas vendas possam brilhar ainda mais."
    }
]