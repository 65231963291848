import React, { useState, useEffect } from 'react'

import {
  AreaBemVindo,
  AreaBemVindoText,
  Area,
  VoltarBtn,
  LogoImg,
  GoldenArrow,
} from './styles'
import subSiteConfig from '../../../services/subSite'

const Header = props => {
  const [back, setBack] = useState(false)

  const handleRevendedor = () => {
    setBack(true)
  }

  useEffect(() => {
    if (back) {
      return subSiteConfig.handleRevendedor()
    }
  }, [back])

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-4 col-sm-6 mb-4">
          {props.compraSucesso ? (
            <></>
          ) : (
            <AreaBemVindo>
              <AreaBemVindoText>
                <strong>Bem vindo</strong> a página do Consultor Ronnelly
              </AreaBemVindoText>

              <AreaBemVindoText>
                <strong>Conheça</strong>{' '}

                <GoldenArrow src="/img/Icon ionic-ios-arrow-dropdown-circle.png" />
              </AreaBemVindoText>
            </AreaBemVindo>
          )}
        </div>

        <div className="col-md-4 col-sm-6 mb-4">
          <Area>
            <LogoImg src="/img/logo-light.png" />
          </Area>
        </div>

        <div className="col-md-4 col-sm-6 mb-4">
          {props.compraSucesso ? (
            <></>
          ) : (
            <Area>
              <VoltarBtn class="back-bnt" onClick={handleRevendedor}>
                <a className="voltarRevendedor" title="Home" alt="Home">
                  Voltar para Site Ronnelly
                </a>
              </VoltarBtn>
            </Area>
          )}
        </div>
      </div>
    </div>
  )
}

export default Header
