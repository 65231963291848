export const Data = [
    {
        title:"1",
        body:""
    },
    {
        title:"2",
        body:""
    },
    {
        title:"3",
        body:"Todas as semijoias acompanham certificados de garantia, garantindo sua legitimidade e qualidade."
    },
    {
        title:"Suporte",
        body:""
    }
]