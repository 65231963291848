import React, { useEffect,  useState } from 'react';
import { inject, observer } from 'mobx-react';
import api from '../../services/api';
import { closeModal } from '../../services/helpers';
import ButtonLoading from '../../components/ButtonLoading';
import TitleCardProduct from '../../components/TitleCardProduct';
import InputMask from 'react-input-mask';
import { useAlert } from 'react-alert';
import subSiteConfig from '../../services/subSite';
import {
  useParams
} from "react-router-dom";

import {
  ContainerAlert,
  ContainerInfo,
  ContainerX,
  SpanButton
} from './styles';

const Trocas = (props) => {

  const { setSubSite, subSite } = props.HeaderStore;
  const { vendedor } = useParams();
  


  useEffect(()=>{
  },[]);


  //subsite
    const subSiteLayout = async (v) => {
      return await subSiteConfig.handleSubsite(v)

    }

    const reqSubsite = async (v) => {
        let obj = await subSiteConfig.getUrlParameter(v)
        setSubSite(obj);
    }

    useEffect(() => {
                  
        reqSubsite(vendedor)

    },[]) 

    useEffect(() => {
            

        console.log("subSite: ", subSite)
        if(subSite!=null) {
            console.log("subSite: ENTROU")
            subSiteLayout(subSite)
        }

    },[subSite]) 
  //subsite


  return (
    <>
      <TitleCardProduct>
          <div className="col-12 p-5 text-center">
              <div style={{padding: "10px", margin: "10px"}}>
                  <img src="/img/logo-light-rosa.png" style={{minWidth: "3.5rem"}}/>
              </div>
              <h2>Troca e Devolução</h2>
              <div style={{color: "#727176"}}>
                  <div className="col-12 p-3">
                      <section>
                      Nossa Política de Troca e Devolução está baseada no Código de Defesa do Consumidor e tem o prazo de 30 (trinta) dias para analisar o produto. 
O prazo de troca (reposição) de produto é de 7 (sete) dias, a partir do recebimento do pedido, desde que as peças não tenham sido usadas e/ou danificadas e só será efetuada mediante a apresentação da peça com etiqueta e o certificado de garantia devidamente preenchido.
Para que sua solicitação de trocas seja analisada, nosso cliente primeiro deve entrar em contato com nosso setor responsável enviando um e-mail para nossa central de atendimento: contato@ronnellypara.com.br
                      </section>   
                  </div>
              </div>
          </div>
      </TitleCardProduct> 
    </>
  );
}

export default inject('CadastroRevendedorStore', "HeaderStore")(observer(Trocas));