import { decorate, observable, action } from "mobx";
import Cookies from 'js-cookie';
import api from '../services/api';
import config from '../config';


class HeaderStore {

    menus = {categorias:[], paginas:[]};
    visible = {on:true};
    promoVisible  = {on:true};
    subSite = null;
    subSiteParam = null;


    setSubSite = (v) => {
        this.subSite = v;
    }

    setSubSiteParam = (v) => {
        if(undefined!=v){
            this.subSiteParam = v;
        }
        
    }

    setVisible = (status) => {
        this.visible.on = status;
    }

    setPromoVisible = (status) => {
        this.promoVisible.on = status;
    }


    getMenus = async () => {
        this.menus = Cookies.get('menus') ? JSON.parse(Cookies.get('menus')) : {categorias:[], paginas:[]};
        const response = await api.get('/info?loja_id='+config.loja_id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        if (response.data) {
            this.menus = response.data;
            Cookies.set('menus', JSON.stringify(response.data));
        }
    }

}

decorate(HeaderStore, {
    menus: observable,
    visible: observable,
    subSite: observable,
    setSubSite: action,
    subSiteParam: observable,
    setSubSiteParam: action,
    setVisible: action,
    promoVisible: observable,
    setPromoVisible: action,
    getMenus: action,
});

export default new HeaderStore();