import React, { useState, useEffect }  from 'react';
import { inject, observer } from 'mobx-react';
import { Route, Redirect, useParams } from 'react-router-dom';
import subSiteConfig from '../services/subSite';
import Cookies from 'js-cookie'


const AuthSubsiteRoute = ({logged,  ...props}) => {

    console.log("AuthSubsiteRoute: ", {pathname:props.location.pathname})
    const [propsLocation, setPropsLocation] = useState(props.location.pathname);
    

    return (
        <Route {...props} >
            
            {Cookies.get('token')
                ? props.children
                : <> <Redirect to={`/site/${propsLocation.split("/")[2]}/entrar`} /> </>
            }

        </Route>
    );
}

export default inject("HeaderStore")(observer(AuthSubsiteRoute));