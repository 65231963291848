import { inject, observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import {
    useParams,
    Link,
  } from "react-router-dom";
  import subSiteConfig from '../services/subSite';


const BannerMeio = (props) => {

    const { visible, setVisible, setSubSite, subSite, setSubSiteParam } = props.HeaderStore;

    let { vendedor } = useParams();

      //subsite
      const subSiteLayout = async (v) => {
          return await subSiteConfig.handleSubsite(v)

      }

      const reqSubsite = async (v) => {
          let obj = await subSiteConfig.getUrlParameter(v)
          setSubSite(obj);
      }

      useEffect(() => {
          
          reqSubsite(vendedor)

      },[]) 

      useEffect(() => {
              

          //console.log("subSite: ", subSite)
          if(subSite!=null) {
              //console.log("subSite: ENTROU")
              setSubSiteParam(subSite)
              subSiteLayout(subSite)
          }

      },[subSite]) 
      //subsite

  return (
    <section className="container pb-2 mt-3 mb-5">
        <a href={"/cadastro-revendedor"} title="Cadastrar Revendedores" alt="Cadastrar Revendedores">
          <img src="/img/home/banners/banner – 2.png" />
        </a>
    </section>
  );
}

export default inject("ClientStore","HeaderStore")(observer(BannerMeio)); 