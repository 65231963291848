import { inject, observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import {
  useParams
} from "react-router-dom";
import subSiteConfig from '../services/subSite';

import PageTitle from '../components/PageTitle';
import PageCartContent from '../components/Checkout/PageCartContent';

const Cart = (props) => {


  const { visible, setVisible, setSubSite, subSite, setSubSiteParam } = props.HeaderStore;
  let { vendedor } = useParams();


  //subsite
  const subSiteLayout = async (v) => {
    return await subSiteConfig.handleSubsite(v)

  }

  const reqSubsite = async (v) => {
    let obj = await subSiteConfig.getUrlParameter(v)
    setSubSite(obj);
  }

  useEffect(() => {

    reqSubsite(vendedor)

  }, [])

  useEffect(() => {


    console.log("subSite: ", subSite)
    if (subSite != null) {
      console.log("subSite: ENTROU")
      setSubSiteParam(subSite)
      subSiteLayout(subSite)
    }

  }, [subSite])
  //subsite


  return (
    <>
      <PageTitle nameRoute="Carrinho"
        title="Seu carrinho" />

      <PageCartContent data={subSite} />

    </>

  );
}

export default inject("ClientStore", "HeaderStore")(observer(Cart));