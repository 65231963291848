import React, { useEffect,  useState } from 'react';
import { inject, observer } from 'mobx-react';
import api from '../../services/api';
import subSiteConfig from '../../services/subSite';
import {
    useParams
  } from "react-router-dom";
import ButtonLoading from '../ButtonLoading';
import CardReactFormContainer from 'card-react';
import InputMask from 'react-input-mask';
import { useAlert } from 'react-alert';
import { FormBox, Cartao, CardBox, HomeBtn, Area, AreaBtn, VoltarBtn } from './styles';
import TitleCardProduct from '../../components/TitleCardProduct';
import Header from '../../components/revendedor-components/Header';


const  CompraSucesso = (props) => {

    const { visible, setVisible, setSubSite, subSite  } = props.HeaderStore;
    const { cleanCart } = props.CarrinhoStore;

    let { vendedor } = useParams();

    useEffect(() => {
        if(visible.on) {
            setVisible(false);
        }
    }, []);


    //subsite
        const subSiteLayout = async (v) => {
            return await subSiteConfig.handleSubsite(v)

        }

        const reqSubsite = async (v) => {
            let obj = await subSiteConfig.getUrlParameter(v)
            setSubSite(obj);
        }
        const handleCleanCart = () => {
            return cleanCart();
        }

        useEffect(() => {

            reqSubsite(vendedor)

        },[])

        useEffect(() => {


            console.log("subSite: ", subSite)
            if(subSite!=null) {
                console.log("subSite: ENTROU")
                subSiteLayout(subSite)
            }

        },[subSite])
    //subsite

    return(
        <>
            <Area>
                <Header compraSucesso={true} />
                <TitleCardProduct>
                    <div className="col-12 p-3 text-center">
                        <div style={{padding: "10px"}}>
                            <img src="/img/logo-light-rosa.png" style={{minWidth: "3.5rem"}}/>
                        </div>
                        <h2 style={subSite !=null ? {color: subSite.cor} :  {color: "#002D6D"}}>Compra efetuada com sucesso</h2>
                    </div>
                </TitleCardProduct>
                <AreaBtn className="container">
                    <VoltarBtn class="back-bnt">
                            <a href={subSite != null ? `/site/${subSite.param}` : "/"} onClick={()=> handleCleanCart } style={subSite != null ? {"color": subSite.cor} : {}} title="Home" alt="Home">
                                Voltar para Site Ronnelly
                            </a>
                    </VoltarBtn>
                </AreaBtn>
            </Area>
        </>
    );
}

export default  inject("HeaderStore", "CarrinhoStore")(observer(CompraSucesso));
