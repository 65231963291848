import React, {useEffect} from 'react';
import { inject, observer } from 'mobx-react';
import TitleCardProduct from '../components/TitleCardProduct';
import subSiteConfig from '../services/subSite';
import {
  useParams
} from "react-router-dom";

const QuemSomos = (props) => {

    const { vendedor } = useParams();
    const { setSubSite, subSite } = props.HeaderStore;

     //subsite
        const subSiteLayout = async (v) => {
            return await subSiteConfig.handleSubsite(v)

        }

        const reqSubsite = async (v) => {
            let obj = await subSiteConfig.getUrlParameter(v)
            setSubSite(obj);
        }


        useEffect(() => {
            
            reqSubsite(vendedor)

        },[]) 

        useEffect(() => {
            console.log("subSite: ", subSite)
            if(subSite!=null) {
                console.log("subSite: ENTROU")
                subSiteLayout(subSite)
            }

        },[subSite]) 
    //subsite

    return (
        <>
            <TitleCardProduct>
                <div className="col-12 p-5 text-center">
                    <div style={{padding: "10px", margin: "10px"}}>
                        <img src="/img/logo-light-rosa.png" style={{minWidth: "3.5rem"}}/>
                    </div>
                    <h2>Quem Somos</h2>
                    <div style={{color: "#727176"}}>
                        <div className="col-12 p-3">
                            <section>
                                A Ronnelly Pará está há mais de 10 anos no mercado, produzindo as mais lindas joias folheadas.
                            </section>   
                        </div>
                        <div className="col-12 p-3">
                            <section>
                            Todas as peças são confeccionadas com o mais alto padrão de qualidade e mantém a excelente relação investimento x estilo. Foram estes diferenciais que garantiram o reconhecimento internacional da marca Ronnelly e o seu posicionamento entre as melhores do mundo a atuar no ramo de joalheria.
                            </section>   
                        </div>
                        <div className="col-12 p-3">
                            <section>
                            A Ronnelly valoriza o charme e elegância. Assim, todos os nossos produtos são antialérgicos e não contém níquel em sua composição, as joias são folheadas à ouro e seguem as tendências do mundo da moda.
                            </section>   
                        </div>
                        <div className="col-12 p-3">
                            <section>
                            A confiança em nossos produtos é muito importante para nós, por isso todas as nossas peças são gravadas com nossa marca ou com as letras “RNL”. Priorizamos a transparência na relação que mantemos com nossos clientes. Esta tem sido a receita para tanto sucesso: qualidade, beleza, inovação e design.
                            </section>   
                        </div>
                    </div>
                </div>
            </TitleCardProduct>

        </>
    );
}


export default inject("HeaderStore")(observer(QuemSomos));