import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { Link, useParams } from 'react-router-dom';
import subSiteConfig from '../../services/subSite';



const Step = ({ numberCurrent, HeaderStore, ClientStore }) => {

  const { visible, setVisible, setSubSite, subSite, setSubSiteParam } = HeaderStore;
  const { token, dados } = ClientStore;

  let { vendedor } = useParams();

  //subsite
    const subSiteLayout = async (v) => {
        await subSiteConfig.handleSubsite(v)
        await subSiteConfig.handleActive(v)

    }

    const reqSubsite = async (v) => {
        let obj = await subSiteConfig.getUrlParameter(v)
        console.log("Checando subsite dados Home: ",obj)
        setSubSite(obj);
    }

    const handleActive = async (v) => {
      await subSiteConfig.handleActive(v)
    }


    useEffect(() => {
            
        reqSubsite(vendedor)

    },[]) 

    useEffect(() => {
        if(subSite!=null) {
            console.log("subSite SALVO:  ", subSite)
            setSubSiteParam(subSite)
            subSiteLayout(subSite)
            handleActive(subSite)
        }

    },[subSite, numberCurrent]) 
  //subsite


  return (
    <div className="steps steps-light pt-2 pb-3 mb-5">
      <Link className={`step-item ${ numberCurrent >= 1 ? 'active' : '' } ${ numberCurrent === 1 ? 'current' : '' }`} to={subSite != null ? `/site/${vendedor}/carrinho` : "/carrinho"}>
        <div className="step-progress">
          <span className="step-count">1</span>
        </div>
        <div className="step-label text-dark">
          <i className="czi-cart"></i>
          Carrinho
        </div>
      </Link>

      <Link className={`step-item ${ numberCurrent >= 2 ? 'active' : '' } ${ numberCurrent === 2 ? 'current' : '' }`} to={subSite != null ? `/site/${vendedor}/checkout-detalhes` : "/checkout-detalhes"} >
        <div className="step-progress">
          <span className="step-count">2</span>
        </div>
        <div className="step-label text-dark">
          <i className="czi-user-circle"></i>
          Suas informações
        </div>
      </Link>
      
      <Link className={`step-item ${ numberCurrent >= 3 ? 'active' : '' } ${ numberCurrent === 3 ? 'current' : '' }`} to={subSite != null ? `/site/${vendedor}/checkout-endereco` : "/checkout-endereco"}>
        <div className="step-progress">
          <span className="step-count">3</span>
        </div>
        <div className="step-label text-dark">
          <i className="czi-package"></i>
          Entrega
        </div>
      </Link>
      
      {/* <Link className={`step-item ${ numberCurrent >= 4 ? 'active' : '' } ${ numberCurrent === 4 ? 'current' : '' }`} to="/checkout-pagamento">
        <div className="step-progress">
          <span className="step-count">4</span>
        </div>
        <div className="step-label text-dark">
          <i className="czi-card"></i>
          Pagamento
        </div>
      </Link> */}
      
      <Link className={`step-item ${ numberCurrent >= 4 ? 'active' : '' } ${ numberCurrent === 4 ? 'current' : '' }`} to={subSite != null ? `/site/${vendedor}/checkout-conclusao` : "/checkout-conclusao"} >
        <div className="step-progress">
          <span className="step-count">4</span>
        </div>
        <div className="step-label text-dark">
          <i className="czi-check-circle"></i>
          Concluir
        </div>
      </Link>
    </div>
  );
}

export default inject("ClientStore","HeaderStore")(observer(Step));