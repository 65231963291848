import React from 'react';
import Skeleton from 'react-loading-skeleton';

const MiniBanner = (props) => {

    const handlelink = (v) => {
        //console.log("link banner: ",v)
        return v;
    }

    return (
        <section className="container position-relative pt-3 pt-lg-0 pb-5 mt-5" style={{ zIndex: 10 }}>
            <div className="row">
                <div className="col-xl-12 col-lg-12">
                    <div className="card border-0 box-shadow-lg">
                        <div className="card-body px-3 pt-grid-gutter pb-0">
                            <div className="row no-gutters pl-1">
                                {props.data.map((item) =>
                                    <div key={item.id} className="col-sm-4 px-2 mb-grid-gutter"><a className="d-block text-center text-decoration-none mr-1" href={handlelink(item.link)}>
                                        <img style={{ margin: "0 auto" }} className="d-block rounded mb-3" src={item.url_fotos} />
                                        <h3 className="font-size-base pt-1 mb-0">{item.titulo}</h3></a>
                                    </div>
                                )}

                                {!props.data.length &&
                                    <>
                                        <div className="col-sm-4 px-2 mb-grid-gutter">
                                            <Skeleton height={200} />
                                        </div>
                                        <div className="col-sm-4 px-2 mb-grid-gutter">
                                            <Skeleton height={200} />
                                        </div>
                                        <div className="col-sm-4 px-2 mb-grid-gutter">
                                            <Skeleton height={200} />
                                        </div>
                                        <div className="col-sm-4 px-2 mb-grid-gutter">
                                            <Skeleton height={200} />
                                        </div>
                                        <div className="col-sm-4 px-2 mb-grid-gutter">
                                            <Skeleton height={200} />
                                        </div>
                                        <div className="col-sm-4 px-2 mb-grid-gutter">
                                            <Skeleton height={200} />
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default MiniBanner;