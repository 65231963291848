import React from "react";
import { Route, Redirect } from "react-router-dom";
import $ from "jquery";
import api from "./api";

export default {
  getUrlParameter: async (v) => {
    let searchParams = "";
    let param = null;
    searchParams = v;

    //console.log("searchParams: ", searchParams);
    if (searchParams != "" && searchParams != null) {
      param = searchParams;

      //integracao com a API de checagem de vendendor virá seguir
      const response = await api.get(`/subsite?nome=${param}`, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.data.errors == false) {
        //console.log("retono subsite sucesso > ", response.data.data.subsite)
        return {
          subSite: true,
          param,
          cor: response.data.data.subsite.cor_tema,
          corFonte: response.data.data.subsite.cor_fonte,
          vendedor_id: response.data.data.subsite.revendedor_id,
        };
      } else {
        return (
          <>
            {" "}
            <Redirect to="/" />{" "}
          </>
        );
      }
    } else {
      return null;
    }
  },
  handleSubsite: async (v) => {
    $(".btn-primary").css({
      color: v.corFonte,
      "background-color": v.cor,
      "border-color": v.cor,
    });
    $(".topbar.topbar-dark.bg-primary").css({
      color: v.corFonte,
      "background-color": v.cor,
      "border-color": v.cor,
    });
    $("button.btn-primary").css({
      color: v.corFonte,
      "background-color": v.cor,
      "border-color": v.cor,
    });
    $(".nav-link.active").css({ "border-color": v.cor, color: v.cor });
    $(".btn-outline-primary").css({
      "background-color": v.cor,
      "border-color": v.cor,
      color: `${v.corFonte}`,
    });
    $(".btn-outline-primary").hover(
      function () {
        $(this).css("color", "#CCC");
      },
      function () {
        $(this).css({ color: `${v.corFonte} !important` }); //to remove property set it to ''
      }
    );

    $("a.btn-primary").css({ color: `${v.corFonte} !important` });
    $("div.topbar-text").css({ color: v.corFonte });
    $("span.text-muted").css({ color: v.corFonte });
    $("span.text-accent").css({ color: v.cor });
    $("a.topbar-link").css({ color: v.corFonte });
    $("span.bg-primary").css({ color: v.corFonte, "background-color": v.cor });

    $(".voltarRevendedor").hover(
      function () {
        $(this).css("color", "#CCC");
      },
      function () {
        $(this).css("color", `${v.corFonte}`); //to remove property set it to ''
      }
    );
    $(".text-primary").css("color", v.cor);
    $(".widget-list-link ").hover(
      function () {
        $(this).css("color", "#CCC");
      },
      function () {
        $(this).css("color", ""); //to remove property set it to ''
      }
    );
    $(".step-count").css({ color: v.corFonte, "background-color": v.cor });
    $("div.text-accent").css({ color: v.cor });

    //$(".custom-radio").css("background", v.cor);
  },
  handleRevendedor: async (v) => {
    $(".voltarRevendedor").click(window.history.back());
  },
  handleActive: async (v) => {
    $("a.step-item").children(".step-progress").css("background-color", "#ccc");
    $("a.step-item.active")
      .children(".step-progress")
      .css("background-color", v.cor);
  },
  handleChecked: async (v) => {
    //$('label.custom-control-label::before').css({"border-color": v.corFonte, "background-color": v.corFonte});
    //document.querySelectorAll('.label.custom-control-label')[0].style.setProperty("--background-color", v.cor);
    //document.querySelectorAll('.label.custom-control-label')[0].style.setProperty("--border-color", );
    $("label.custom-control-label").append(
      `<style>.label.custom-control-label:before{background-color: ${v.cor} !important; border-color: ${v.cor} !important}</style>`
    );
  },
};
